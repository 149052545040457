const responsiveSettings = [
  {
    breakpoint: 1235,
    settings: {
      slidesToShow: 3.25,
      slideToScroll: 3,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slideToScroll: 3,
    },
  },
  {
    breakpoint: 896,
    settings: {
      slidesToShow: 2.5,
      slideToScroll: 3,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2.2,
      slideToScroll: 2,
    },
  },
  {
    breakpoint: 638,
    settings: {
      slidesToShow: 1.5,
      slideToScroll: 1,
    },
  },
  {
    breakpoint: 375,
    settings: {
      slidesToShow: 1.5,
      slideToScroll: 1,
    },
  },
];

export { responsiveSettings };
