/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { TOKEN_KEY, MODAL_WELCOME } from '@constants/config.constants';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  isLoading: false,
  token: localStorage.getItem(TOKEN_KEY),
  error: {},
  isB2CModalOpen: false,
  isB2BModalOpen: false,
  phone: '',
  statusCode: '',
  otpError: false,
  OtpStatus: 0,
  otpSent: null,
  passwordError: false,
  recoveryModalOpen: false,
  scroll: false,
  bransisLoading: false,
  statusCodeBrand: 0,
  brandError: '',
  errorUpdatePassword: '',
});
/* ------------- Reducers ------------- */
export const authRequest = (state = INITIAL_STATE, { phone, shippingTypeOtp }) => {
  return {
    ...state,
    isLoading: true,
    phone,
    shippingTypeOtp,
    otpSent: false,
    OtpStatus: null,
    statusCode: '',
  };
};

export const authSuccess = (state = INITIAL_STATE, { statusCode }) => {
  return {
    ...state,
    isLoading: false,
    isB2CModalOpen: false,
    isB2BModalOpen: false,
    recoveryModalOpen: false,
    error: {},
    statusCode,
    otpSent: true,
  };
};
export const authFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isLoading: false,
    phone: '',
    error: {
      message: error.message,
    },
    allowedUser: error.allowedUser,
    otpSent: false,
  };
};

export const validateotpRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const validateotpSuccess = (state = INITIAL_STATE, { token, statusCode = 200 }) => ({
  ...state,
  isLoading: false,
  isB2CModalOpen: false,
  isB2BModalOpen: false,
  error: {},
  OtpStatus: statusCode,
  token,
});
export const validateotpFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isLoading: false,
    otpError: error.otpError,
    OtpStatus: error.statusCode,
  };
};
export const resetOtp = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isLoading: false,
    otpError: '',
    statusCode: '',
    otpSent: false,
  };
};

export const validateEmailRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const validateEmailSuccess = (state = INITIAL_STATE, { statusCode }) => {
  return {
    ...state,
    isLoading: false,
    OtpStatus: statusCode,
  };
};

export const emailLoginRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const emailLoginSuccess = (state = INITIAL_STATE, { token }) => {
  return {
    ...state,
    isLoading: false,
    isB2CModalOpen: false,
    isB2BModalOpen: false,
    token,
    OtpStatus: null,
  };
};
export const emailLoginFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    passwordError: true,
  };
};

export const openB2CModal = (state = INITIAL_STATE) => {
  return {
    ...state,
    isB2CModalOpen: true,
    otpSent: null,
  };
};
export const closeB2CModal = (state = INITIAL_STATE) => {
  return {
    ...state,
    isB2CModalOpen: false,
    otpSent: null,
  };
};
export const openB2BModal = (state = INITIAL_STATE) => {
  return {
    ...state,
    isB2BModalOpen: true,
    otpSent: null,
  };
};
export const closeB2BModal = (state = INITIAL_STATE) => {
  localStorage.removeItem(MODAL_WELCOME);
  return {
    ...state,
    isB2BModalOpen: false,
    otpSent: null,
  };
};
export const reset = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...INITIAL_STATE,
    token: null,
  };
};
export const retry = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...INITIAL_STATE,
    isB2CModalOpen: state.isB2CModalOpen,
    isB2BModalOpen: state.isB2BModalOpen,
    token: null,
  };
};

export const openPasswordRecovery = (state = INITIAL_STATE) => {
  return {
    ...state,
    recoveryModalOpen: true,
  };
};

export const closePasswordRecovery = (state = INITIAL_STATE) => {
  return {
    ...state,
    recoveryModalOpen: false,
  };
};

export const startLoading = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const stopLoading = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const signUpRequest = (state = INITIAL_STATE, { userInfo }) => {
  return { ...state, isLoading: true, userInfo, otpSent: null, OtpStatus: 401 };
};

export const resetOtpSentRequest = (state = INITIAL_STATE) => {
  return { ...state, otpSent: null };
};

export const signUpRequestSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    OtpStatus: 202,
  };
};
export const activeScrollBottom = (state = INITIAL_STATE) => ({
  ...state,
  scroll: true,
});
export const resetScrollBottom = (state = INITIAL_STATE) => ({
  ...state,
  scroll: false,
});

export const signUpBransRequest = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  payload,
  bransisLoading: true,
});
export const signUpBransSuccess = (state = INITIAL_STATE) => ({
  ...state,
  statusCodeBrand: 200,
  bransisLoading: false,
});
export const signUpBransFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  bransisLoading: false,
  brandError: error,
});
export const updatePasswordSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  isLoading: true,
  errorUpdatePassword: data,
});
export const updatePasswordFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  isLoading: false,
  errorUpdatePassword: error,
});

export const updatePasswordReset = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: false,
  errorUpdatePassword: '',
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: authRequest,
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.SIGNUP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_REQUEST_SUCCESS]: signUpRequestSuccess,
  [Types.VALIDATE_OTP_REQUEST]: validateotpRequest,
  [Types.VALIDATE_OTP_SUCCESS]: validateotpSuccess,
  [Types.VALIDATE_OTP_FAILURE]: validateotpFailure,
  [Types.VALIDATE_EMAIL_REQUEST]: validateEmailRequest,
  [Types.VALIDATE_EMAIL_SUCCESS]: validateEmailSuccess,
  [Types.EMAIL_LOGIN_REQUEST]: emailLoginRequest,
  [Types.EMAIL_LOGIN_SUCCESS]: emailLoginSuccess,
  [Types.EMAIL_LOGIN_FAILURE]: emailLoginFailure,
  [Types.OPEN_B_TO_C_MODAL]: openB2CModal,
  [Types.CLOSE_B_TO_C_MODAL]: closeB2CModal,
  [Types.OPEN_B_TO_B_MODAL]: openB2BModal,
  [Types.CLOSE_B_TO_B_MODAL]: closeB2BModal,
  [Types.OPEN_PASSWORD_RECOVERY]: openPasswordRecovery,
  [Types.CLOSE_PASSWORD_RECOVERY]: closePasswordRecovery,
  [Types.FORGOT_PASSWORD_REQUEST]: startLoading,
  [Types.FORGOT_PASSWORD_SUCCESS]: stopLoading,
  [Types.UPDATE_PASSWORD]: startLoading,
  [Types.LOGOUT_REQUEST]: reset,
  [Types.RESET_OTP_SENT_REQUEST]: resetOtpSentRequest,
  [Types.ACTIVE_SCROLL_BOTTOM]: activeScrollBottom,
  [Types.RESET_SCROLL_BOTTOM]: resetScrollBottom,
  [Types.SIGN_UP_BRANS_REQUEST]: signUpBransRequest,
  [Types.SIGN_UP_BRANS_SUCCESS]: signUpBransSuccess,
  [Types.SIGN_UP_BRANS_FAILURE]: signUpBransFailure,
  [Types.VALIDATE_RESET_OTP]: resetOtp,
  [Types.AUTH_RETRY]: retry,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAILURE]: updatePasswordFailure,
  [Types.UPDATE_PASSWORD_RESET]: updatePasswordReset,
});
