/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  data: [],
  isLoading: true,
  isOpenDictionaryModal: false,
  error: {},
});

/* ------------- Reducers ------------- */
export const dietsRequest = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const dietsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error: {
      message: error.message,
    },
  };
};

export const dietsSuccess = (state = INITIAL_STATE, { items }) => {
  return { ...state, data: items.data, isLoading: false };
};
export const toggleShowDictionaryModal = (state, { isOpenDictionaryModal }) => {
  return { ...state, isOpenDictionaryModal };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DIETS_REQUEST]: dietsRequest,
  [Types.DIETS_SUCCESS]: dietsSuccess,
  [Types.DIETS_TOGGLE_DICTIONARY_MODAL]: toggleShowDictionaryModal,
});
