import { put } from 'redux-saga/effects';
import { Creators as DietsCreators } from '@reducers/Diets/actionsTypes';
import { DietsService } from '@services/diets.service';

export function* getDiets() {
  try {
    const response = yield DietsService.getDiets();
    yield put(DietsCreators.dietsSuccess({ data: response }));
  } catch (error) {
    yield put(DietsCreators.dietsFailure(error));
  }
}
