/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  startUpComplete: false,
});

/* ------------- Reducers ------------- */
const setStartUpComplete = (state) => {
  return { ...state, startUpComplete: true };
};

const reset = () => INITIAL_STATE;
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.STARTUP]: reset,
  [Types.SET_STARTUP_COMPLETE]: setStartUpComplete,
});
