import { useEffect, useState } from 'react';
import { clientTypePlaces } from '@constants/client-type-places';
import { capitalizeText } from '@utils/format/format.utils';

export function usePlaces({ clientType, isLoggedIn }) {
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const url = clientTypePlaces.B2B;
  useEffect(() => {
    const fetchPlaces = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        const { data } = await response.json();
        if (data.length > 0) {
          const lowercase = data.map((place) => {
            return {
              ...place,
              departament: capitalizeText(place.department_name),
              city: capitalizeText(place.city_name),
              departament_id: place.department_id,
            };
          });
          setPlaces(lowercase);
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (isLoggedIn) {
      fetchPlaces();
    }
  }, [clientType, url, isLoggedIn]);

  return {
    places,
    isLoading,
  };
}
