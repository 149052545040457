import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import '../style.scss';

import SVG from 'react-inlinesvg';
import arrowIcon from '@assets/svg/next_arrow_icon.svg';

const ArrowRightIcon = () => (
  <SVG className="carousel-arrow-icon carousel-arrow-icon-next" src={arrowIcon} />
);

const NextArrow = (props) => {
  const { className, onClick, idSources } = props;

  return (
    <div
      id={idSources}
      style={{ display: window.innerWidth > 600 ? 'block' : 'none' }}
      className={className}
      onClick={onClick}
      onKeyUp={onClick}
      tabIndex={0}
      role="button"
    >
      <IconButton onClick={onClick} style={{ top: '-45px' }} className={idSources}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

NextArrow.defaultProps = {
  className: '',
  idSources: '',
  onClick: () => {},
};

NextArrow.propTypes = {
  className: PropTypes.string,
  idSources: PropTypes.string,
  onClick: PropTypes.func,
};

export { NextArrow };
