import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CategoryButton, CategoriesHeaderList } from '@components/index';

const CategoriesHeader = ({ categories, onCategoryChangeHandler, activeCategory }) => {
  const [currentCategories, setCurrentCategories] = useState([]);
  useEffect(() => {
    const mapCategoriesToAisle = (categoriesToMap) =>
      categoriesToMap
        .filter((category) => category.image?.find((image) => image.type === 'General'))
        .map((category) => ({
          ...category,
          label: category.name,
          imageUrl: category.image.find((image) => image.type === 'General')?.url,
        }));

    const mappedCategories = mapCategoriesToAisle(categories);
    setCurrentCategories(mappedCategories);
  }, [categories]);

  return (
    <CategoriesHeaderList categories={currentCategories} activeCategory={activeCategory}>
      {currentCategories &&
        currentCategories?.length &&
        currentCategories?.map((category, idx) => {
          const isActive = activeCategory?.id === category.id;
          return (
            <CategoryButton
              key={idx}
              isActive={isActive}
              onClick={() => onCategoryChangeHandler(category)}
              category={category}
            />
          );
        })}
    </CategoriesHeaderList>
  );
};
CategoriesHeader.defaultProps = {
  activeCategory: {},
};
CategoriesHeader.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCategoryChangeHandler: PropTypes.func.isRequired,
  activeCategory: PropTypes.shape({}),
};

export { CategoriesHeader };
