/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Button, Fade, Grid, Modal, Typography, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CircleLoader } from '@superfuds/saori';

import { statusCode } from '@constants/index';

import { ReactComponent as ZiroLogo } from '@assets/svg/ziroLogo.svg';
import { ReactComponent as ErrorIcon } from '@assets/svg/error_icon.svg';

import './styles.scss';

const ValidateZiro = ({
  open,
  closeModal,
  confirm,
  payCreditZiro,
  disabled,
  setCodeZiro,
  resendcode,
}) => {
  const payconfirm =
    payCreditZiro && payCreditZiro?.numero_obligacion && payCreditZiro?.code === statusCode.SUCCESS;

  const [document, setDocument] = useState('');
  const handledConfirmation = () => {
    const userDocument = parseInt(document, 10);
    confirm(userDocument);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={() => { }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modal__paper">
          <ZiroLogo style={{ marginRight: 20, height: 40 }} />
          <Typography classes={{ root: 'modal__warninigTitle' }}>
            {payconfirm ? 'Confirmar solicitud de crédito' : 'Aplicar a crédito'}
          </Typography>
          {!payconfirm && payCreditZiro?.code !== statusCode.UNAUTHORIZED && (
            <>
              <Typography classes={{ root: 'modal__warningTextOne' }}>
                ¡Compra ahora y paga después sin intereses hasta 30 días!
              </Typography>
              <div className="mb-5 mt-4">
                <Typography classes={{ root: 'modal__warningTextOne' }}>
                  <strong> Para continuar,</strong>
                  <br />
                  por favor ingresa tu documento de identidad.
                </Typography>

                <TextField
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  id="digitalCode"
                  label="Documento de identidad"
                  variant="outlined"
                  onChange={(e) => {
                    if (e.target.value?.length >= 4) {
                      setDocument(e.target.value);
                    }
                  }}
                />
              </div>
            </>
          )}
          {payCreditZiro?.code > statusCode.SUCCESS && (
            <div className="mb-5">
              <ErrorIcon width={70} />
              <Typography classes={{ root: 'modal__ErrorText' }}>
                {payCreditZiro?.message}
              </Typography>
              {payCreditZiro?.code === statusCode.UNAUTHORIZED && (
                <Typography classes={{ root: 'modal__warningText' }} className="mt-5">
                  Lo sentimos, ha ocurrido un error. Por favor, intenta recargar la página e
                  intentarlo de nuevo.
                </Typography>
              )}
            </div>
          )}
          {payconfirm && (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12}>
                <Typography classes={{ root: 'modal__warningText' }}>
                  Número de Obligación: <b>{payCreditZiro?.numero_obligacion}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography classes={{ root: 'modal__warningText' }}>
                  ¡Se ha enviado un código a tu WhatsApp y correo electrónico! Por favor revisa y
                  utiliza el código para continuar con el proceso de compra.
                </Typography>
                <TextField
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 6,
                    minLength: 5,
                  }}
                  id="digitalCode"
                  label="Código"
                  variant="outlined"
                  onChange={(e) => {
                    setCodeZiro(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                <Link onClick={resendcode}>Reenviar código</Link>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Button
                id="PaymentMethodsValidateZiroNo"
                classes={{ root: 'modal__buttonWhite' }}
                onClick={closeModal}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                id="PaymentMethodsValidateZiroYes"
                classes={{ root: 'modal__buttonGreen' }}
                onClick={handledConfirmation}
                fullWidth
                disabled={
                  payCreditZiro.isLoading ||
                  (payconfirm && disabled === '') ||
                  document === '' ||
                  payCreditZiro?.code === statusCode.UNAUTHORIZED
                }
              >
                {payCreditZiro.isLoading ? (
                  <CircleLoader width="20" height="20" />
                ) : payconfirm ? (
                  ' Validar'
                ) : (
                  ' Confirmar'
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

ValidateZiro.defaultProps = {
  open: false,
  payCreditZiro: {},
  setCodeZiro: () => { },
  resendcode: () => { },
  disabled: '',
};

ValidateZiro.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  setCodeZiro: PropTypes.func,
  resendcode: PropTypes.func,
  payCreditZiro: PropTypes.arrayOf(PropTypes.shape({})),
};

export { ValidateZiro };
