import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getProductByIdRequest: ['payload'],
  getProductSuccess: ['data'],
  openClothesModal: ['payload'],
  closeClothesModal: null,
  updateProductInfoVariant: ['payload'],
  getProductError: null,
});
