import { userIsLoggedIn } from '@reducers/Auth';

export const getActiveLocation = (state) =>
  state.delivery.userLocations.find((location) => location.isSelected);

export const getAnonActiveLocation = (state) =>
  state.delivery.anonLocations.find((location) => location.isSelected);

export const userHasLocation = (state) => {
  const isLoggedIn = userIsLoggedIn(state);
  return isLoggedIn ? getActiveLocation(state) : getAnonActiveLocation(state);
};

export const getUserAnonLocations = (state) => state.delivery.anonLocations;

export const getFilterCity = (state) => state.delivery.filterCity;

export const delivery = (state) => state.delivery;
