import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#25c16a',
      light: '#6BA973',
      dark: '#369D65',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFCB47',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: "'Silka'",
  },
  overrides: {
    MuiTypography: {
      h1: {
        root: {
          fontSize: '33px',
        },
      },
      h2: {
        root: {
          fontSize: '22px',
        },
      },
    },
  },
});

export { theme };
