/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { B2C_USER, B2B_USER, UserType, CC_CODE, TOKEN_KEY, IS_USER_ANONYM } from '@constants/index';
import { Types } from './actionsTypes';
/* ------------- Initial State ------------- */
const isLoggedIn = () => !!localStorage.getItem(TOKEN_KEY);
const isAnonymous = () => localStorage.getItem(IS_USER_ANONYM);
export const INITIAL_STATE = Immutable({
  documentType: CC_CODE,
  clientType: parseInt(UserType.B2C, 10),
  isEmployee: false,
  eid: B2C_USER.toString(),
  loading: false,
  avatar: null,
  name: '',
  lastName: null,
  isAuthModal: false,
  isOpenWarningProducts: false,
  ballancesInfo: {},
  status: 0,
  changeUserType: false,
  source: null,
  isLoggedIn: isLoggedIn(),
  membership: [],
  isAnonymous: isAnonymous(),
  completeDocument: false,
});
/* ------------- Reducers ------------- */
export const updateUserData = (state = INITIAL_STATE, { userData }) => {
  return {
    ...state,
    ...userData,
    loading: false,
    isLoggedIn: isLoggedIn(),
  };
};

export const updateProfileRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

export const userDataUpdateSucceeded = (state = INITIAL_STATE, { userData }) => {
  return {
    ...state,
    ...userData,
    loading: false,
    isAuthModal: false,
    isLoggedIn: isLoggedIn(),
  };
};

export const userDataUpdateFailure = (state = INITIAL_STATE, { status }) => {
  return {
    ...state,
    status,
  };
};

export const changeProfileUserRequest = (state = INITIAL_STATE, { userType }) => {
  return {
    ...state,
    clientType: parseInt(userType, 10),
    eid: B2B_USER.toString(),
  };
};

export const setAuthModal = (state = INITIAL_STATE, { isAuthModal }) => {
  return {
    ...state,
    isAuthModal,
    isLoggedIn: isLoggedIn(),
    isAnonymous: isAnonymous(),
  };
};

const toggleWarningProducts = (state = INITIAL_STATE) => {
  return {
    ...state,
    isOpenWarningProducts: !state.isOpenWarningProducts,
  };
};

export const reset = () => INITIAL_STATE;

const openB2BRegister = (state) => {
  return {
    ...state,
    isAuthModal: true,
    status: 401,
    clientType: parseInt(UserType.B2B, 10),
    changeUserType: true,
  };
};

const toggleCompleteDocumentModal = (state, { completeDocument }) => {
  return {
    ...state,
    completeDocument,
  };
};

const openB2CRegister = (state) => {
  return {
    ...state,
    isAuthModal: true,
    status: 401,
    clientType: parseInt(UserType.B2C, 10),
    changeUserType: false,
    source: null,
  };
};

const setB2C = (state) => {
  return {
    ...state,
    clientType: parseInt(UserType.B2C, 10),
    changeUserType: false,
    source: null,
  };
};

export const changeStatus = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    ...payload,
    loading: false,
  };
};

/* ------------- Change Type User ------------- */
export const userChangeTypeRequest = (state) => {
  return {
    ...state,
    ...state,
    loading: true,
  };
};

export const userChangeTypeSuccess = (state, { payload }) => {
  return {
    ...state,
    ...payload,
    loading: false,
    isAuthModal: false,
    changeUserType: false,
  };
};

export const userChangeTypeFailure = (state, { payload }) => {
  return {
    ...state,
    status: payload.status,
    error: payload.error,
    loading: false,
    changeUserType: true,
    source: 'error',
  };
};

export const setSourceModal = (state = INITIAL_STATE, { source }) => {
  return {
    ...state,
    source,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_DATA_FETCH_SUCCEEDED]: updateUserData,
  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.CHANGE_PROFILE_USER]: changeProfileUserRequest,
  [Types.SET_AUTH_MODAL]: setAuthModal,
  [Types.TOGGLE_WARNING_PRODUCTS]: toggleWarningProducts,
  [Types.RESET]: reset,
  [Types.OPEN_B2B_REGISTER]: openB2BRegister,
  [Types.OPEN_B2C_REGISTER]: openB2CRegister,
  [Types.USER_DATA_UPDATE_SUCCEEDED]: userDataUpdateSucceeded,
  [Types.USER_CHANGE_TYPE_REQUEST]: userChangeTypeRequest,
  [Types.USER_CHANGE_TYPE_SUCCESS]: userChangeTypeSuccess,
  [Types.USER_CHANGE_TYPE_FAILURE]: userChangeTypeFailure,
  [Types.SET_B2C]: setB2C,
  [Types.SET_SOURCE_MODAL]: setSourceModal,
  [Types.CHANGE_STATUS]: changeStatus,
  [Types.TOGGLE_COMPLETE_DOCUMENT_MODAL]: toggleCompleteDocumentModal,
});
