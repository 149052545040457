export const TRANSACTION__STATUS = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILURE: 'FAILURE',
};

export const ORDER__STATUS = {
  PENDING: 'PENDING_PAYMENT',
  ERROR: 'CANCELLED',
  PAID: 'BLOCKED',
};

export const REJECTED_TRANSACTION_CODE = '596';

export const LOGIN_FROM_ADD_TO_CART = 'LoginFromAddToCart';
export const ADD_TO_CART_PENDING = 'product';

export const MINIMUM_PSE_PAYMENT = 5000;
export const MINIMUM_TC_PAYMENT = 15000;
export const MINIMUM_ZIRO_PAYMENT = 200000;
export const MINIMUM__PAYMENT_REEXPEDICION = 500000;
export const MINIMUM_CASH_PAYMENT = 100000;

export const responseNetworkMessages = new Map([
  ['PENDING', 'El pago está pendiente de confirmación'],
  ['EXPIRED', 'El pago ha caducado'],
  ['DECLINED', 'El pago ha sido rechazado'],
  ['ERROR', 'Ha ocurrido un error al procesar el pago'],
  ['ANTIFRAUD_REJECTED', 'El pago ha sido rechazado por el sistema antifraude'],
  ['SUBMITTED', 'El pago ha sido enviado y está pendiente de respuesta'],
  ['CANCELLED', 'El pago ha sido cancelado'],
  ['REVERSED', 'El pago ha sido reversado'],
  ['PARTIALLY_APPROVED', 'El pago ha sido aprobado parcialmente'],
  ['UNKNOWN', 'Estado de pago desconocido'],
]);
export const statusCode = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  CONFLICT: 409,
  SERVER_ERROR: 500,
};

export const ResponseMessagesError =
  'Hemos tenido problemas para procesar tu pago, por favor intentalo de nuevo. Si el problema persiste por favor cambia tu método de pago o comunicate con tu entidad bancaria';

export const MessagesErrorUnknown =
  'Tu transacción fue declinada: El emisor ha tenido un problema al autorizar la transacción';

export const MessagesError = 'Cuando se confirme la orden pendiente de pago podrás volver a pedir';
export const MessagesInventory = 'Alcanzaste el límite de unidades en nuestro inventario';
