import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { history } from '@redux/store';
import { ProductList } from '@components/organisms';
import { SubCategoriesSidebar } from '@containers/SubCategoriesSidebar/subCategoriesSidebar.container';
import { Creators as ProductsCreators } from '@reducers/Products';

import { Layout } from '../Layout/layout';
import './by-category.container.scss';

const ProductsByCategoryContainer = ({ categories, products, isLoading, hasMore }) => {
  const dispatch = useDispatch();
  const [subcategories, setSubcategories] = useState([]);
  const { categoryId, subCategoryId } = useParams();
  const isTablet = useMediaQuery('(max-width: 959px)');
  const [activeSubCategory, setActiveSubCategory] = useState(
    subcategories?.find((subCate) => subCate?.id === subCategoryId || undefined),
  );
  // eslint-disable-next-line no-unused-vars
  let category = null;
  const selectedCategory = useMemo(() => subCategoryId || categoryId, [categoryId, subCategoryId]);
  if (categoryId) {
    category = categories.find((category) => category.id.toString() === categoryId);
  }

  useEffect(() => {
    if (categoryId) {
      const category = categories.find((category) => category.id.toString() === categoryId);
      if (category) {
        if (subCategoryId) {
          const subCate = category.subcategories.find(
            (subCate) => subCate.id.toString() === subCategoryId,
          );
          setActiveSubCategory(subCate);
        } else {
          setActiveSubCategory(undefined);
        }
        setSubcategories(category.subcategories);
      } else {
        history.push('/');
      }
    }
  }, [categories, selectedCategory, dispatch, categoryId, subcategories, subCategoryId]);

  useEffect(() => {
    dispatch(ProductsCreators.getProductsByCategoryRequest(selectedCategory, true));
  }, [dispatch, selectedCategory]);

  const handleChangeSubcategory = (id, name) => {
    if (id === undefined) {
      setActiveSubCategory(true);
      history.push(`/categoria/${categoryId}`);
    } else {
      history.push(`/categoria/${categoryId}/${id}`);
    }
  };
  return (
    <Layout>
      <Grid container item xs={12} className="by-category-container">
        {subcategories?.length > 0 && (
          <Grid item xs={2} className="by-category-container__sidebar" hidden={isTablet}>
            <SubCategoriesSidebar
              subcategories={subcategories}
              subCategoryClickHandler={handleChangeSubcategory}
              selectedSubCategory={activeSubCategory}
            />
          </Grid>
        )}
        <Grid
          item
          md={subcategories.length > 0 ? 10 : 12}
          xs={12}
          className={`${
            subcategories.length > 0
              ? 'by-category-container__productList'
              : 'by-category-container__productList by-category-container__productList--noSubCategories'
          }`}
        >
          {activeSubCategory && (
            <Typography variant="h3" style={{ marginTop: 0 }}>
              {activeSubCategory.name}
            </Typography>
          )}
          <ProductList
            getMore={() => {
              if (!isLoading && hasMore) {
                dispatch(ProductsCreators.getProductsByCategoryRequest(selectedCategory, false));
              }
            }}
            isLoading={isLoading}
            products={products}
            hasSubcategories={subcategories.length > 0}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

ProductsByCategoryContainer.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      banner: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
};

ProductsByCategoryContainer.defaultProps = {
  products: [],
  categories: [],
};

const mapStateToProps = ({ products: { items, isLoading, hasMore }, categories, user }) => {
  return {
    products: items,
    isLoading,
    expressRoutes: categories.expressRoutes,
    exploreCategories: categories.exploreCategories,
    hasMore,
    categories: categories.categories,
    user,
  };
};

const ProductsByCategory = connect(mapStateToProps)(ProductsByCategoryContainer);

export default ProductsByCategory;
