/* eslint-disable react-hooks/exhaustive-deps */
import QueryString from 'query-string';
import { useEffect, useState } from 'react';
import { history } from '@utils/history/history.utils';

export function useKeepClientIdInQueryStringIfPresent() {
  let queryParams = QueryString.parse(history.location.search);

  const [initialClientId] = useState(queryParams.masqueraded_user_id);

  useEffect(() => {
    const listener = (location) => {
      queryParams = QueryString.parse(location.search);
      const { masqueraded_user_id: clientId } = queryParams;
      if (!clientId && initialClientId) {
        history.replace({
          pathname: location.pathname,
          search: QueryString.stringify({
            ...queryParams,
            masqueraded_user_id: clientId || initialClientId,
          }),
        });
      }
    };

    listener(window.location);
    return history.listen(listener);
  }, [history]);
}
