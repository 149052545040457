import { takeLatest, all } from 'redux-saga/effects';
import { Types as StartupTypes } from '@reducers/Startup';
import { Types as SalesTypes } from '@reducers/Sales';
import { Types as DietsTypes } from '@reducers/Diets';
import { Types as LocationsTypes } from '@reducers/Locations';
import { Types as OrdersTypes } from '@reducers/Orders';
import { Types as BannersTypes } from '@reducers/Banners';
import { Types as BrandsTypes } from '@reducers/Brands';
import { Types as CategoriesTypes } from '@reducers/Categories';
import { Types as ProductsTypes } from '@reducers/Products';
import { Types as ProductTypes } from '@reducers/Product';
import { Types as UserTypes } from '@reducers/User';
import { Types as AuthTypes } from '@reducers/Auth';

import { startup, fetchAll } from './StartupSagas';

/* ------------- Sagas ------------- */
import { getBanners } from './banners/banners.sagas';
import { getBrands, getDetailsBrand } from './brands/brands.sagas';
import { getCategories } from './categories/categories.sagas';
import { getDiets } from './diets/DietsSagas';
import {
  getProductsByCategory,
  getProductsByBrand,
  getProductsByHint,
  getProductById,
  getProductsByDiets,
} from './products/products.sagas';
import {
  getShoppingCart,
  upsertShoppingCart,
  addToShoppingCart,
  resetShoppingCart,
  updatePickUpMethod,
  usePositiveBallance,
} from './shoppingCart/ShoppingCartSagas';

import {
  getUserLocations,
  getAnonLocations,
  changeSelectedLocation,
  addUserLocation,
  deleteUserLocation,
  updateUserLocation,
} from './locations/LocationsSagas';
import {
  getOrders,
  getOrdersByMonth,
  getRecentSeasons,
  experienceUserRatingStar,
} from './orders/orders.sagas';
import { addCoupon } from './coupons/CouponsSagas';
import {
  payWithCreditCard,
  getStatuspayWithPse,
  confirmFreeOrder,
  payWithCash,
  payWithCredit,
  requestListBank,
  payWithPse,
  requestListCard,
  requestCardDelete,
  authZiro,
  validateCreditZiro,
  validateCodeZiro,
  resendCodeZiro,
  applyCreditZiro,
} from './payment/PaymentSagas';
import {
  getUserData,
  updateUserData,
  updateUserProfile,
  changePassword,
  userDataFetchSucceeded,
  requestNewPassword,
  changeUserType,
} from './user/UserSagas';
import {
  otpLogin,
  logout,
  signup,
  validateOtp,
  validateEmail,
  emailLogin,
  forgotPassword,
  updatePassword,
  signUpBrans,
} from './auth/AuthSagas';

/* ------------- Connect Types To Sagas ------------- */
export function* rootSaga() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup),
    // some sagas receive extra parameters in addition to an action
    takeLatest(BannersTypes.BANNERS_REQUEST, getBanners),
    takeLatest(BrandsTypes.BRANDS_REQUEST, getBrands),
    takeLatest(BrandsTypes.BRANDS_DETAILS, getDetailsBrand),
    takeLatest(CategoriesTypes.CATEGORIES_REQUEST, getCategories),
    takeLatest(DietsTypes.DIETS_REQUEST, getDiets),
    // Products
    takeLatest(ProductTypes.GET_PRODUCT_BY_ID_REQUEST, getProductById),
    takeLatest(ProductsTypes.GET_PRODUCTS_BY_CATEGORY_REQUEST, getProductsByCategory),
    takeLatest(ProductsTypes.GET_PRODUCTS_BY_BRAND_REQUEST, getProductsByBrand),
    takeLatest(ProductsTypes.GET_PRODUCTS_BY_HINT_REQUEST, getProductsByHint),
    takeLatest(ProductsTypes.GET_PRODUCTS_BY_DIETS_REQUEST, getProductsByDiets),
    // Shopping Cart
    takeLatest(SalesTypes.GET_SHOPPING_CART, getShoppingCart),
    takeLatest(SalesTypes.UPSERT_SHOPPING_CART, upsertShoppingCart),
    takeLatest(SalesTypes.ADD_TO_SHOPPING_CART, addToShoppingCart),
    takeLatest(SalesTypes.RESET_SHOPPING_CART, resetShoppingCart),
    takeLatest(SalesTypes.ADD_COUPON, addCoupon),
    takeLatest(SalesTypes.GET_LIST_BANK, requestListBank),
    takeLatest(SalesTypes.HANDLE_POSITIVE_BALLANCE_REQUEST, usePositiveBallance),
    // Locations
    takeLatest(LocationsTypes.GET_USER_LOCATIONS, getUserLocations),
    takeLatest(LocationsTypes.GET_ANON_LOCATIONS, getAnonLocations),
    takeLatest(LocationsTypes.CHANGE_SELECTED_LOCATION, changeSelectedLocation),
    takeLatest(LocationsTypes.ADD_USER_LOCATION, addUserLocation),
    takeLatest(LocationsTypes.DELETE_USER_LOCATION, deleteUserLocation),
    takeLatest(LocationsTypes.UPDATE_USER_LOCATION, updateUserLocation),
    takeLatest(LocationsTypes.SET_WILL_PICK_UP_IN_WAREHOUSE, updatePickUpMethod),
    takeLatest(LocationsTypes.ADD_FILTER_CITY, fetchAll),
    // Orders
    takeLatest(OrdersTypes.GET_ORDERS, getOrders),
    takeLatest(OrdersTypes.GET_ORDERS_BY_MONTH, getOrdersByMonth),
    // RatingStar
    takeLatest(OrdersTypes.GET_SEASONS, getRecentSeasons),
    // ExperienceUSer
    takeLatest(OrdersTypes.POST_EXPERIENCE, experienceUserRatingStar),
    // Payment
    takeLatest(SalesTypes.PAY_WITH_CREDIT_CARD, payWithCreditCard),
    takeLatest(SalesTypes.GET_STATUS_PAY_WITH_PSE, getStatuspayWithPse),
    takeLatest(SalesTypes.PAY_WITH_PSE, payWithPse),
    takeLatest(SalesTypes.GET_LIST_CARD, requestListCard),
    takeLatest(SalesTypes.DELETE_CARD, requestCardDelete),
    takeLatest(SalesTypes.CONFIRM_FREE_ORDER, confirmFreeOrder),
    takeLatest(SalesTypes.PAY_WITH_CASH_REQUEST, payWithCash),
    takeLatest(SalesTypes.PAY_WITH_CREDIT_REQUEST, payWithCredit),
    takeLatest(SalesTypes.GET_TOKEN_ZIRO, authZiro),
    takeLatest(SalesTypes.VALIDATE_CREDIT_ZIRO, validateCreditZiro),
    takeLatest(SalesTypes.VALIDATE_CODE_CREDIT_ZIRO, validateCodeZiro),
    takeLatest(SalesTypes.RESEND_CODE_ZIRO, resendCodeZiro),
    takeLatest(SalesTypes.APPLY_CREDIT_ZIRO, applyCreditZiro),

    // UsergetListCard
    takeLatest(UserTypes.GET_USER_DATA, getUserData),
    takeLatest(SalesTypes.UPDATE_BILLING_DATA, updateUserData),
    takeLatest(UserTypes.UPDATE_USER_PROFILE, updateUserProfile),
    takeLatest(UserTypes.USER_DATA_FETCH_SUCCEEDED, userDataFetchSucceeded),
    takeLatest(UserTypes.REQUEST_NEW_PASSWORD, requestNewPassword),
    takeLatest(UserTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(UserTypes.USER_CHANGE_TYPE_REQUEST, changeUserType),
    // Auth
    takeLatest(AuthTypes.LOGIN_REQUEST, otpLogin),
    takeLatest(AuthTypes.VALIDATE_OTP_REQUEST, validateOtp),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(AuthTypes.SIGNUP_REQUEST, signup),
    takeLatest(AuthTypes.VALIDATE_EMAIL_REQUEST, validateEmail),
    takeLatest(AuthTypes.EMAIL_LOGIN_REQUEST, emailLogin),
    takeLatest(AuthTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(AuthTypes.UPDATE_PASSWORD, updatePassword),
    takeLatest(AuthTypes.SIGN_UP_BRANS_REQUEST, signUpBrans),
  ]);
}
