import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like brandsRequest -> BRANDS_REQUEST
 */
export const { Types, Creators } = createActions({
  brandsRequest: ['clean'],
  brandsSuccess: ['items'],
  brandsDetails: ['brandId'],
  brandsDetailsSuccess: ['item'],
});
