import { ODOO_HOST } from '@constants/index';

export const transformCoupons = (couponList) => {
  return couponList.map((coupon) => ({
    isAPercentage: coupon.isAPercentage ? { ...coupon, amount: coupon.amount } : coupon,
    inUse: coupon.in_use,
    ...coupon,
  }));
};

export const transformShoppingCart = (shoppingCart) => {
  const {
    items: { standart, cold },
  } = shoppingCart;

  const mapProductsByType = (products, defaultAppliesSinIvaDay = false, routeType = 'standard') =>
    products.items.map((item) => ({
      ...item,
      distribuitor: { ...item?.distribuitor?.distributor[0] },
      thumbnail: item.url ? `${ODOO_HOST}${item.url}&width=80&height=80` : item.thumbnail,
      totalStock:
        item.unitsPerPackage > 1
          ? Math.floor(item.totalStock / item.unitsPerPackage)
          : item.totalStock,
      appliesSinIvaDay: defaultAppliesSinIvaDay || item?.appliesSinIvaDay,
      routeType: item?.routeType ?? routeType,
    }));

  const standartItems = standart?.items?.length ? mapProductsByType(standart, false) : [];
  const coldItems = cold?.items?.length ? mapProductsByType(cold, false, 'congelado') : [];
  const totalItems = [standart, cold]
    .filter(Boolean)
    .reduce((accum, current) => accum + current?.items?.length || 0, 0);

  const grandTotal = shoppingCart.usesPositiveBalances
    ? shoppingCart.grandTotalWithPositiveBalance
    : shoppingCart.grandTotal;
  const outputGrandTotal = {
    defaultGrandTotal: shoppingCart.usesPositiveBalances ? grandTotal : 0,
  };

  return {
    ...shoppingCart,
    items: {
      ...shoppingCart.items,
      standart: { ...shoppingCart.items.standart, items: standartItems },
      cold: { ...shoppingCart.items.cold, items: coldItems },
    },
    totalItems,
    grandTotal,
    ...outputGrandTotal,
  };
};
