export const TOKEN_KEY = 'sf-token';
export const TOKEN_ZIRO = 'token';
export const DEVICESESSIONID = 'deviceSessionId';
export const DEPARTMENT_ID = 'department_id';
export const MODAL_WELCOME = 'ModalWelcome';
export const COOKIE_ACCEPT = 'CookieAccept';
export const TYPE_USER = 'sf-type-user';
export const LOGIN_IN = 'login';
export const LOGIN_FROM_CHECKOUT = 'loginFromCheckout';
export const IS_USER_ANONYM = 'isAnonym';
export const B2B_REGISTER = 'B2BRegister';
export const COMPLETE_USER_INFO_FROM_CART = 'completeUserInfoFromCart';
export const SELECT_NEW_ADDRESS = 'selectNewAddress';
export const EMAIL_REGISTER = 'emailRegister';
export const SHOW_CHECKOUT_WARNING = 'showCheckoutWarning';
export const SHOW_VERIY_CART = 'showVerifyCartWarning';
export const SHOW_CHECKOUT = 'showCheckout';
export const TOKEN_VALIDATION = 'Token is invalid or has expired';
export const BOGOTA_CITY_ID = 6920;
export const WHITOUT_IVA_DAY_MODAL_SHOWN = 'whitoutIvaDayModalShown';
export const STRENGTHS = 'strengths';
export const IMPROVEMENTS = 'improvements';
export const RANTING = 'RatingStar';
export const ORDERPROBLEMS = 'orderProblems';
export const DESCRIPTIONEXPERIENCE =
  'Tu opinión es muy importante para nosotros asi podemos mejorar nuestra experiencia';
export const SUGGESTED_CATEGORY_ID = 321;
export const SERVICECOSTTITLE = 'Cómo se calcula el costo de servicio';
export const SERVICECOSTDESCRIPTION =
  'Este valor incluye el costo de operación con nuestros diferentes aliados';
export const CATEGORY_WITHOUT_DICTIONARY = [268, 266, 276, 277, 275, 339];
export const SHOW_SUPERFAST_MODAL_REQUEST = 'showSuperfastModalRequest';
export const PRESALE_CATEGORY_ID = 376;

export const BANNERS = [
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/Banner_Badem_Web.png',
    platformBanner: 'DESKTOP',
    bannerType: 'BRAND',
    alt: 'new',
  },
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/ziro_credito_web.jpg',
    platformBanner: 'DESKTOP',
    bannerType: 'ZIRO',
    alt: 'new',
  },

  /*   {
      url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/super_prima_web.jpg',
      platformBanner: 'DESKTOP',
      bannerType: 'CATEGORY',
      alt: 'new',
    }, */
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/banner_1900x377.jpg',
    platformBanner: 'DESKTOP',
    bannerType: 'PROMO',
    alt: 'new',
  },
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/BANNER-WEB-1.jpg',
    platformBanner: 'DESKTOP',
    bannerType: 'PROMO',
    alt: 'new',
  },
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/Banner_Badem_Movil.png',
    platformBanner: 'MOBILE',
    bannerType: 'BRAND',
  },
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/ziro_credito_movil.jpg',
    platformBanner: 'MOBILE',
    bannerType: 'ZIRO',
  },
  /*  {
     url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/super_prima_movil.jpg',
     platformBanner: 'MOBILE',
     bannerType: 'CATEGORY',
   }, */
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/Banner_Mobile-1.jpg',
    platformBanner: 'MOBILE',
  },
  {
    url: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/Banner_Mobile-3.jpg',
    platformBanner: 'MOBILE',
    bannerType: 'PROMO',
    alt: 'new',
  },
];
