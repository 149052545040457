import {
  CLOTHES_CATEGORY,
  CATEGORY_WITHOUT_DICTIONARY,
  expressCategoriesIds,
  ODOO_HOST,
  PRESALE_CATEGORY_ID,
} from '@constants/index';

const MAX_QTY = 100;

const excludeAttributes = (prodCategories, excludeCategories) => {
  if (!prodCategories || prodCategories.length === 0) return false;
  return prodCategories.some((item) => excludeCategories.includes(item));
};

export const parseProduct = (raw) => {
  const mapImages = (image) => {
    const images = image.map((item) => ({
      ...item,
      url: `${ODOO_HOST}${item.url}`,
    }));

    return images;
  };

  const mapImagesType = (image) => {
    const data = [];
    Object.values(image).forEach((val) => {
      if (
        (val && val.type === 'Frontal') ||
        (val && val.type === 'Lateral') ||
        (val && val.type === 'Trasera')
      )
        data.push(val.url);
    });
    return data;
  };

  const getOnlyValuesList = (list) => {
    if (!list) return [];
    return list.map((elem) => {
      return elem.name;
    });
  };

  const getTotalInventory = (item) => {
    return item.unitsPerPackage > 1
      ? Math.floor(item.totalInventory / item.unitsPerPackage)
      : (item.totalInventory > 1 && item.totalInventory) || 0;
  };
  const dataString = ['fecha_corta', 'fecha corta', 'Fecha-corta'];

  const mapProduct = (item) => {
    const expirationDate = new Date(item?.shortDateExpiry);
    const currentDate = new Date();
    const differenceInMilliseconds = expirationDate - currentDate;
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return {
      id: item?.id ?? 0,
      name: item.shortTitle || item.title_ec,
      shortName: item.shortTitle,
      distributor: item.distributor,
      description: item.sf_product_type === 'membership' ? '' : item.about || '',
      brand: item.mark,
      template_id: item?.template_id,
      daysUntilExpiration: differenceInDays,
      short_description: item.shortDescription || '',
      net_content: item.netContent || '',
      brandId: item.markId || 0,
      price: item.price,
      sku: item.sku,
      recommendationSales: item.recommendationSales,
      packPriceWithTax: item.price_without_taxes,
      shortDateExpiry: item.shortDateExpiry,
      packPrice:
        item.brandDiscount > 0
          ? item.priceWithDiscount * (1 - item.brandDiscount / 100)
          : item.priceWithDiscount,
      units: item.unitsPerPackage || 0,
      cautions: item?.cautions,
      expirationDate: dataString.some((word) =>
        item?.sf_product_name_slug?.toLowerCase()?.includes(word?.toLowerCase()),
      ),
      thumbnail:
        item.images.length > 0 && mapImages(item.images).find((image) => image.type === 'Frontal')
          ? `${mapImages(item.images).find((image) => image.type === 'Frontal').url}&height=200`
          : '',
      previewImage:
        item.images.length > 0 && mapImages(item.images).find((image) => image.type === 'Frontal')
          ? `${mapImages(item.images).find((image) => image.type === 'Frontal').url}&height=500`
          : '',
      imagesGuides: item?.imagesGuides ? mapImages(item.imagesGuides) : [],
      quantity: item.unitsPerPackage || 0,
      maxQty: item.stock_quantity || MAX_QTY,
      images: mapImagesType(mapImages(item.images || [])),
      total_inventory: dataString.some((word) =>
        item?.sf_product_name_slug?.toLowerCase()?.includes(word?.toLowerCase()),
      )
        ? item?.totalInventory
        : getTotalInventory(item),
      price_real: item.price || 0,
      discount: item.discountInPercentage || item.brandDiscount || 0,
      price_sf: item.price || 0,
      taxes: item.taxesInMoney || 0,
      price_sf_with_tax: item.price_without_taxes || 0,
      categoryId: item.categoryId ? item.categoryId : [],
      queryID: item.queryID,
      isExpress: item.categoryId?.some((id) => expressCategoriesIds.includes(id)),
      characteristics: item.characteristics,
      isRefrigerated: !!item.characteristics.find(
        (characteristic) =>
          characteristic.name === 'Refrigerado' || characteristic.name === 'Congelado',
      ),
      isDropshipping: !!item.attributes.find((attribute) => attribute.name === 'Orgánico'),
      attributes:
        (!excludeAttributes(item.categoryId, CATEGORY_WITHOUT_DICTIONARY) &&
          item.attributes &&
          item.attributes.map((attribute) => {
            return { ...attribute, stamp: `${ODOO_HOST}${attribute.stamp}` };
          })) ||
        null,
      appliesSinIvaDay: item?.appliesSinIvaDay,
      isClothes: item.categoryId.some((category) => CLOTHES_CATEGORY.includes(category)),
      //  variants: mapVariants(item.variants),
      hasPresale: item.categoryId.includes(PRESALE_CATEGORY_ID),
      ivaPercentage: 19,
      warranty: item.warranty,
      productType: item.sf_product_type,
      includes: getOnlyValuesList(item.sf_membership_contain),
      extraInfo: getOnlyValuesList(item.sf_membership_info),
      routeId: item.routeId,
      routeType: item.routeType,
      routes: item.routes,
      publicSugeredPrice: item.publicSugeredPrice,
      // percentageMargin: parseInt(item.percentageMargin.toFixed(0), 10),
      priceMargin: item.priceMargin,
    };
  };
  return raw
    .filter((item) => item.price && item.unitsPerPackage)
    .filter((item) => item.images.length)
    .map((item) => mapProduct(item));
};
