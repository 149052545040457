import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { ProductCardV2 } from '@superfuds/saori';

import { withRouter } from 'react-router-dom';
import {
  COLD_AND_FROZEN_TYPE,
  LOGIN_FROM_ADD_TO_CART,
  ADD_TO_CART_PENDING,
} from '@constants/index';
import { history } from '@redux/store';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { Creators as LocationCreators } from '@reducers/Locations';
import { Creators as ProductActions } from '@reducers/Product/actionsTypes';
import { Creators as UserCreators } from '@reducers/User';

const ProductCardMolecule = ({ product, shoppingCart, userLocations, token, idx, isLoggedIn }) => {
  const dispatch = useDispatch();
  const [currentVariantId, setCurrentVariantId] = useState(null);
  const toggleClothesModal = (product, addItem) =>
    dispatch(
      ProductActions.openClothesModal({ product, callback: addItem, source: 'ProductCard' }),
    );
  const addProduct = (product) => dispatch(ShoppingCartCreators.addToShoppingCart(product));

  let productQuantity = 0;
  if (
    shoppingCart?.items?.standart?.items?.length ||
    shoppingCart?.items?.express?.items?.length ||
    shoppingCart?.items?.cold?.items?.length ||
    shoppingCart?.items?.dropshipping?.items?.length ||
    shoppingCart?.items?.presale?.items?.length
  ) {
    const { cold, dropshipping, express, presale, standart } = shoppingCart.items;
    if (product.isClothes) {
      productQuantity = dropshipping.items.reduce((accum, currentValue) => {
        if (product.template_id === currentValue.itemTemplateId) {
          return accum + 1;
        }
        return accum;
      }, 0);
    } else {
      const itemsListByType = [cold, dropshipping, express, presale, standart];
      const matchProductByCartType = itemsListByType.find((item) => {
        const matched =
          item.items.length &&
          item.items.find((p) => p.itemId === product.id && p.routeId === product.routeId);
        return matched;
      });
      if (matchProductByCartType !== undefined) {
        const existingItem = matchProductByCartType.items.find(
          (currentItem) =>
            currentItem.itemId === product.id && currentItem.routeId === product.routeId,
        );
        productQuantity = existingItem.quantityInPackages;
      }
    }
  }
  if (shoppingCart?.items?.length) {
    if (product.isClothes) {
      productQuantity = shoppingCart.items.reduce((accum, currentValue) => {
        if (product.template_id === currentValue.itemTemplateId) {
          return accum + 1;
        }
        return accum;
      }, 0);
    } else {
      const productFiltered = shoppingCart.items.filter(
        (currentProduct) =>
          product.id === currentProduct.itemId && product.routeId === currentProduct.routeId,
      );
      if (productFiltered.length > 0) {
        productQuantity = productFiltered[0].quantityInPackages;
      }
    }
  }
  const handleColdFrozenType = (productType) => {
    if (product.characteristics && product.characteristics.length) {
      const match = product.characteristics.filter(
        (characteristic) => characteristic.name === productType,
      );
      return match.length > 0;
    }
    return false;
  };
  let currentVariant = null;
  if (product.isClothes) {
    const variantWithStock = product?.variants.find((v) => v.isSelected);
    currentVariant = variantWithStock !== undefined ? variantWithStock : product?.variants[0];
  }
  const sharedProps = {
    product: { ...product, totalInventory: product.total_inventory },
    idx,
    onDetailClick: () => {
      const pathname =
        product.id === 0
          ? `/p/${product.template_id}/${product.variants[0].id}`
          : `/p/${product.id}`;
      history.push(pathname);
    },
    onBrandClick: () => {
      if (product && product.brandId) {
        history.push(`/marca/${product.brandId}`);
      }
    },
    onAddClick: () => {
      if (isLoggedIn) {
        if (!userLocations.length) {
          dispatch(LocationCreators.toggleGoogleMapsModal());
          return;
        }
        if (product.isClothes) {
          toggleClothesModal(product, addProduct);
          return;
        }
        addProduct({
          ...product,
          itemId: product.id,
          quantityInPackages: 1,
        });
      } else {
        dispatch(UserCreators.setAuthModal(true));
        localStorage.setItem(LOGIN_FROM_ADD_TO_CART, 'true');
        localStorage.setItem(ADD_TO_CART_PENDING, JSON.stringify(product));
      }
    },
    toggleClothesModal: () => {
      let mappedClothesProduct = product;
      if (currentVariantId !== null) {
        const mapperVariantsProduct = product?.variants?.map((variant) => {
          if (variant.id === currentVariantId) {
            return { ...variant, isSelected: true };
          }
          return { ...variant, isSelected: false };
        });
        mappedClothesProduct = { ...mappedClothesProduct, variants: mapperVariantsProduct };
      }
      if (isLoggedIn) {
        toggleClothesModal(mappedClothesProduct, addProduct);
      } else {
        dispatch(UserCreators.setAuthModal(true));
      }
    },
    onDeleteClick: () => {
      if (token) {
        dispatch(
          ShoppingCartCreators.upsertShoppingCart([
            {
              itemId: product.id,
              quantityInPackages: productQuantity > 1 ? productQuantity - 1 : 0,
              isExpress: product.isExpress,
              routeId: product?.routeId,
            },
          ]),
        );
      }
    },
    productsQuantity: productQuantity,
    isProductUpserting: shoppingCart.isLoading,
    isRefrigerated: handleColdFrozenType(COLD_AND_FROZEN_TYPE.COLD),
    isFrozen: handleColdFrozenType(COLD_AND_FROZEN_TYPE.FROZEN),
  };
  return (
    <section className="products-list__product">
      <ProductCardV2
        token={token}
        onPrince={() => () => dispatch(UserCreators.setAuthModal(true))}
        type={product.isClothes ? 'clothes' : 'product'}
        currentVariantId={currentVariantId === null ? currentVariant?.id : currentVariantId}
        handleSelectVariant={(variant) => setCurrentVariantId(variant.id)}
        {...sharedProps}
      />
      <div style={{ marginBottom: 25 }} />
    </section>
  );
};

ProductCardMolecule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  shoppingCart: PropTypes.shape({
    items: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    coupon: PropTypes.shape({
      namePromo: PropTypes.string,
    }),
  }).isRequired,
  token: PropTypes.string,
  clientType: PropTypes.number.isRequired,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
    }),
  ).isRequired,
  idx: PropTypes.number.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

ProductCardMolecule.defaultProps = {
  product: {},
  token: '',
};

const mapStateToProps = ({
  auth: { token },
  delivery: { userLocations, filterCity },
  user: { clientType, isLoggedIn },
  sales: { shoppingCart },
}) => ({
  token,
  userLocations,
  filterCity,
  clientType,
  shoppingCart,
  isLoggedIn,
});

export const ProductCard = connect(mapStateToProps)(withRouter(ProductCardMolecule));
