import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';

import { BannerCarousel } from '@components/index';
import { Creators as UserCreators } from '@reducers/User';
import { ReactComponent as DeliveryIcon } from '@assets/svg/deliveryHome.svg';
import { ReactComponent as Discount } from '@assets/svg/discountHome.svg';
import { ReactComponent as CreditIcon } from '@assets/svg/credit_icon_home.svg';

const ContainerSectionOperation = ({ token, banners, user }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthModal } = user;

  const buttonAction = () => (
    <Button
      className="buttonCallToAction"
      onClick={() => dispatch(UserCreators.setAuthModal(!isAuthModal))}
    >
      Aprovecha esta oportunidad de compra
    </Button>
  );

  return (
    <Grid className="container-layout">
      {location.pathname === '/' && (
        <Grid className="container-layout">
          <BannerCarousel location="HOME" banners={banners} user={user} />
          <Grid className="sectionOperation" container>
            <Grid item xs={12} md={4} style={{ padding: '3%' }}>
              <DeliveryIcon height="70px" width="70px" />
              <Typography variant="h5" className="text-center">
                Compra más de 5.000 mil productos para tu negocio desde tu computador o celular
              </Typography>
              <Typography className="text-center">
                Todos tus proveedores, con entregas centralizadas o despachos directos ¡en un solo
                lugar!
              </Typography>
              {!token && (
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, marginTop: 20 }}>
                  {buttonAction()}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '3%' }}>
              <CreditIcon height="70px" width="70px" />
              <Typography variant="h5" className="text-center">
                30 días de crédito neto en todas las marcas
              </Typography>
              <Typography className="text-center">
                Compra nuevos productos para reventa en tu tienda y paga 30 días después
              </Typography>
              {!token && (
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, marginTop: 20 }}>
                  {buttonAction()}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '3%' }}>
              <Discount height="70px" width="70px" />
              <Typography variant="h5" className="text-center">
                Nuestra promesa, el mejor precio
              </Typography>
              <Typography className="text-center">
                Si encuentras mejores precios en el mercado, trae la factura y nuestra promesa es
                igualarlos
              </Typography>
              {!token && (
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, marginTop: 20 }}>
                  {buttonAction()}
                </Box>
              )}
            </Grid>
            {!token && (
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    {buttonAction()}
                  </Grid>
                  <Grid item xs={4}>
                    {buttonAction()}
                  </Grid>
                  <Grid item xs={4}>
                    {buttonAction()}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

ContainerSectionOperation.defaultProps = {
  token: null,
};

ContainerSectionOperation.propTypes = {
  token: PropTypes.string,
  banners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    d: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    clientType: PropTypes.number,
    isAuthModal: PropTypes.bool,
    snackbarMessage: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    places: PropTypes.arrayOf(PropTypes.shape({})),
    isOpenWarningProducts: PropTypes.bool.isRequired,
    status: PropTypes.number,
    changeUserType: PropTypes.bool,
    source: PropTypes.string,
    loading: PropTypes.bool,
    isEmployee: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = ({ user, banners, auth: { token } }) => ({
  token,
  user,
  banners,
});

export const SectionOperation = connect(mapStateToProps)(withRouter(ContainerSectionOperation));
