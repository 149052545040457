// @ts-nocheck
import { call } from 'redux-saga/effects';
import { transformBrands } from '@transforms/ApiToBrands.transform';
import { TransformPagination } from '@transforms/ApiToPagination.transform';
import { odooApi } from './api';

export const BrandsService = {
  *getBrands({ page }) {
    const brands = yield call({ context: odooApi, fn: odooApi.get }, '/brands', {
      page,
      per_page: 50,
    });
    const ResponseToMap = transformBrands(brands.data.data);
    return {
      ...brands.data,
      data: ResponseToMap,
      ...TransformPagination(brands.data),
    };
  },
  *getDetailsBrand({ brandId }) {
    const detailsBrand = yield call({ context: odooApi, fn: odooApi.get }, `/brands`, {
      brand_id: brandId,
    });
    return {
      ...detailsBrand.data,
    };
  },
};
