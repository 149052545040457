import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import inAsset from '@assets/svg/Linkedin.svg';
import igAsset from '@assets/svg/Instagram.svg';
import fbAsset from '@assets/svg/Facebook.svg';

const InSvg = () => <SVG className="svg svg-contentText" src={inAsset} />;
const IgSvg = () => <SVG className="svg svg-contentText" src={igAsset} />;
const FbSvg = () => <SVG className="svg svg-contentText" src={fbAsset} />;

const SocialMediaLinks = ({ getI18nText }) => (
  <div className="footerin">
    <Typography variant="body2" className="contentText text-margin link">
      {getI18nText('footer.footerSocial')}
    </Typography>
    <div className="icon-bar">
      <a
        href="https://www.instagram.com/superfuds/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="cursor-pointer"
      >
        <IgSvg />
      </a>
      <a
        href="https://www.facebook.com/superfuds/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="cursor-pointer"
      >
        <FbSvg />
      </a>
      <a
        href="https://www.linkedin.com/company/superfuds"
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="cursor-pointer"
      >
        <InSvg />
      </a>
    </div>
  </div>
);

SocialMediaLinks.defaultProps = {
  getI18nText: () => {},
};

SocialMediaLinks.propTypes = {
  getI18nText: PropTypes.func,
};

export { SocialMediaLinks };
