import { put, select } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import { ODOO_HOST } from '@constants/odoo.constants';
import { OrdersService } from '@services/orders.service';
import { Creators as OrdersCreators } from '@reducers/Orders';

export function* getOrders({ date }) {
  try {
    yield put(OrdersCreators.ordersRequest());
    const orders = yield OrdersService.getOrders({ ...date });
    /* Logic to handle different cart type */
    const mapProductsByType = (products) => {
      if (!products.items.length) return products;
      return products.items.map((item) => ({
        ...item,
        thumbnail: item.url ? `${ODOO_HOST}${item.url}&width=80&height=80` : item.thumbnail,
      }));
    };
    const mappedOrders = orders.reduce((accumulator, currentOrder) => {
      const { standart, presale, express, dropshipping, cold } = currentOrder.items;
      const standartMappedProducts = standart?.items?.length ? mapProductsByType(standart) : [];
      const presaleMappedProducts = presale?.items?.length ? mapProductsByType(presale) : [];
      const expressMappedProducts = express?.items?.length ? mapProductsByType(express) : [];
      const dropshippingMappedProducts = dropshipping?.items?.length
        ? mapProductsByType(dropshipping)
        : [];
      const coldMappedProducts = cold?.items?.length ? mapProductsByType(cold) : [];
      accumulator.push({
        ...currentOrder,
        items: {
          cold: coldMappedProducts,
          dropshipping: dropshippingMappedProducts,
          express: expressMappedProducts,
          presale: presaleMappedProducts,
          standart: standartMappedProducts,
        },
      });
      return accumulator;
    }, []);
    yield put(OrdersCreators.ordersSuccess(mappedOrders));
  } catch (err) {
    captureException(err);
  }
}

export function* getOrdersByMonth({ page = 1, month }) {
  try {
    const orders = yield select(getOrders);
    const filteredOrders = orders.filter((order) => {
      const oMonth = +order.created_at.split('-')[1];
      if (+oMonth === month) return order;
      return null;
    });
    yield put(OrdersCreators.ordersSuccess(filteredOrders));
  } catch (err) {
    captureException(err);
  }
}

export function* getRecentSeasons() {
  try {
    const { data: seasons } = yield OrdersService.getSeason();
    yield put(OrdersCreators.ordersSeasonSuccess( seasons ));
  } catch (error) {
    // TODO track error sentry
    captureException(error);
  }
}

export function* experienceUserRatingStar({ orderId, body }) {
  try {
    yield OrdersService.experienceUser({ orderId, body });
  } catch (error) {
    captureException(error);
  }
}
