export const OrderStatus = {
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  READY_TO_DELIVER: 'READY_TO_DELIVER',
  ACTIVE: 'ACTIVE',
  ERROR: 'CANCELLED',
};

export const CouponErrors = {
  INVALID_DUE_FIRST_PURCHASE: 'El cupón aplica solo para primera compra',
  EXPIRED: 'El cupón ya no se encuentra vigente',
  UNAVAILABLE: 'El cupón es invalido',
  DUPLICATED: 'Lo sentimos, el cupón ingresado ya fue redimido',
  NOT_FOUND: 'Lo sentimos, el cupón ingresado no existe',
};

export const PAYMENT__METHODS = {
  PSE: 'PSE',
  TC: 'TC',
  RAPPI_PAY: 'RAPPI_PAY',
  CASH: 'EFECTY',
  CREDIT_SF: 'CREDIT_SF',
  TCSUBSCRIPTION: 'TCSUBSCRIPTION',
  ZIRO: 'ZIRO',
};

export const SCREENTYPE = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
  FAILURESF: 'FAILURE__SF',
};
