import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '@superfuds/saori';
import { currencyFormatter } from '@utils/render';
import { MINIMUM_ZIRO_PAYMENT, PAYMENT__METHODS } from '@constants/index';

import { ReactComponent as EnabledIcon } from '@assets/svg/statusIconEnabled.svg';
import { ReactComponent as DisabledIcon } from '@assets/svg/statusIconDisabled.svg';
import { ReactComponent as ZiroLogo } from '@assets/svg/ziroLogo.svg';

import './styles.scss';

const ZiroOption = ({ open, paymentMethod, isDisabled }) => {
  return (
    <Card
      key={paymentMethod.id}
      className={`paymentOption ${paymentMethod?.active === true
          ? 'paymentOption-active'
          : 'paymentOption-whiteBg paymentOption-background-ziro'
        }`}
    >
      <Grid container classes={{ root: 'paymentOption__dataContainer' }}>
        <Grid
          container
          justifyContent="flex-start"
          classes={{ root: 'paymentOption__paymentDetail' }}
        >
          <ZiroLogo style={{ marginRight: 20, height: 40 }} />
          <Grid container direction="column" style={{ maxWidth: '80%' }}>
            <>
              <Typography
                classes={{
                  root: isDisabled ? 'paymentOption__label--disabled' : 'paymentOption__label',
                }}
                className="text-left"
              >
                <strong> Pagar con Zíro</strong>
              </Typography>

              <Typography
                classes={{
                  root: isDisabled ? 'paymentOption__label--disabled' : 'paymentOption__label',
                }}
                className="text-left"
              >
                ¡Compra ahora y paga después sin intereses hasta 30 días!
              </Typography>
            </>

            {isDisabled && (
              <Typography component="span" classes={{ root: 'paymentOption__info' }}>
                Pago para compra mínima de {currencyFormatter(MINIMUM_ZIRO_PAYMENT)}
              </Typography>
            )}
          </Grid>
        </Grid>
        {(open || paymentMethod?.method === PAYMENT__METHODS.ZIRO) && !isDisabled && (
          <EnabledIcon
            id="PaymentMethodsPaymentOptionZiroOptionMethodOptionEnabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
        {!open && paymentMethod?.method !== PAYMENT__METHODS.ZIRO && !isDisabled && (
          <DisabledIcon
            id="PaymentMethodsPaymentOptionZiroOptionMethodOptionDisabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
      </Grid>
    </Card>
  );
};

ZiroOption.propTypes = {
  open: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    cardName: PropTypes.string,
    maskedNumber: PropTypes.string,
    method: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export { ZiroOption };
