/* eslint-disable callback-return */

import PropTypes from 'prop-types';
import React from 'react';

export class ScrollListener extends React.Component {
  componentWillUnmount() {
    this.observer.disconnect();
  }

  setupObserver() {
    const { callback } = this.props;
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          callback();
        }
      });
    });
  }

  render() {
    return (
      <div
        id="intersection_observer_placeholder"
        style={{ height: '1px' }}
        ref={(div) => {
          if (this.observer) {
            this.observer.disconnect();
          }
          this.setupObserver();
          if (div) {
            this.observer.observe(div);
          }
        }}
      />
    );
  }
}

ScrollListener.propTypes = {
  callback: PropTypes.func.isRequired,
};
