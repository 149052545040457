import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import { history } from '@redux/store';
import { ScrollListener } from '@components/atoms';
import { stringNormalize } from '@utils/format/stringNormalize.utils';
import { BrandsSkeletonLoader } from './skeleton';

import './style.scss';

const itemsToSkeletonLoad = 15;

export const BrandsList = ({ brands, getMore, isLoading }) => {
  const missingCardsInRow = useMemo(
    () => (brands.length % 4 ? 4 - (brands.length % 4) : 0),
    [brands.length],
  );

  const skeletonRows = useMemo(
    () =>
      Array.from(Array(itemsToSkeletonLoad + missingCardsInRow)).map((item, idx) => (
        <Grid
          key={`product-loading-${idx}`}
          container
          item
          xs={4}
          lg={3}
          className="mv-2"
          justifyContent="center"
        >
          <BrandsSkeletonLoader />
        </Grid>
      )),
    [missingCardsInRow],
  );

  return (
    <Grid container className="brands-grid pt-1" xs={12} spacing={2}>
      {brands?.map((e, index) => {
        return (
          <Grid
            id={`BrandsListBrandGrid-${stringNormalize(e.name)}`}
            key={e.id}
            item
            md={2}
            sm={3}
            xs={6}
            className="mv-2"
            style={{ display: 'flex', justifyContent: 'space-around', position: 'relative' }}
          >
            {e?.discount > 0 && <span className="discountBrand">{e?.discount}%</span>}
            <Card
              id={`BrandsListBrandCard-${stringNormalize(e.name)}`}
              key={e.id}
              className={`brand-card-component Brand-${index}`}
              onClick={() => history.push(`/marca/${e.id}`)}
            >
              <LazyLoad>
                <img
                  id={`BrandsListBrandImg-${stringNormalize(e.name)}`}
                  data-sizes="auto"
                  className={`logo Brand-${index}`}
                  src={e.image}
                  alt={e.name}
                />
              </LazyLoad>
            </Card>
          </Grid>
        );
      })}
      {isLoading && skeletonRows}
      <ScrollListener callback={getMore} />
    </Grid>
  );
};

BrandsList.defaultProps = {
  brands: [],
};

BrandsList.propTypes = {
  getMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
};
