import { all, call, put, select } from 'redux-saga/effects';
import { Creators as AppStateActions } from '@reducers/AppState';
import { debugConfig } from '@config/debugConfig';
import { Creators as BannersCreators } from '@reducers/Banners';
import { Creators as CategoriesCreators } from '@reducers/Categories';
import { Creators as BrandsCreators } from '@reducers/Brands';
import { Creators as UserCreators } from '@reducers/User';
import { Creators as StartupCreators } from '@reducers/Startup';
import { Creators as DietsCreators } from '@reducers/Diets';
import { userIsLoggedIn } from '@reducers/Auth';
/* Exported to make available for tests
   process STARTUP actions */
export function* fetchAll() {
  yield all([
    put(BannersCreators.bannersRequest(true)),
    put(BrandsCreators.brandsRequest(true)),
    put(CategoriesCreators.categoriesRequest(true)),
    put(StartupCreators.setStartupComplete()),
    put(DietsCreators.dietsRequest()),
  ]);
}

export function* getUserData() {
  yield all([put(UserCreators.getUserData()), put(BannersCreators.bannersRequest())]);
}

export function* startup() {
  // eslint-disable-next-line no-console
  if (debugConfig.useReactotron && console.tron) {
    // fully customized!
    const subObject = { a: 1, b: [1, 2, 3], c: true };
    subObject.circularDependency = subObject;
    // eslint-disable-next-line no-console
    /*    console.tron.display({
      name: '🔥 SUPERFUDS INIT 🔥',
      preview: 'You should totally expand this',
      value: {
        '💃': 'Welcome to the future!',
        subObject,
        someInlineFunction: () => true,
        someGeneratorFunction: startup,
        someNormalFunction: () => '',
      },
    }); */
  }
  const isLoggedIn = yield select(userIsLoggedIn);
  if (isLoggedIn) {
    yield call(getUserData);
  } else {
    yield call(fetchAll);
  }
  yield put(AppStateActions.setRehydrationComplete());
}
