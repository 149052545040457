import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Typography } from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import { withRouter } from 'react-router-dom';
import { history } from '@redux/store';
import { TitleSections, Carousel, MoreCard } from '@components/index';
import MoreBrandsAsset from '@assets/svg/moreBrands_inline.svg';
import { useWindowSize } from '@hooks/use-windows-size.hook';
import { stringNormalize } from '../../../utils/format/stringNormalize.utils';

import './style.scss';

const BrandCardCarouselBase = ({ brands, viewMoreUrl, user, title, activeDiscount }) => {
  const { width } = useWindowSize();

  let carrouselWidth;
  let buttonWidth;
  if (width > 1024) {
    carrouselWidth = 10;
    buttonWidth = 30;
  } else {
    carrouselWidth = 3;
    buttonWidth = 5;
  }

  const getButtonsQtyToShow = () => Math.round((width * carrouselWidth) / 100 / buttonWidth);

  const responsiveSettings = [
    {
      breakpoint: 8000,
      settings: {
        slidesToShow: getButtonsQtyToShow(),
        slideToScroll: 6,
      },
    },
    {
      breakpoint: 1441,
      settings: {
        slidesToShow: 6,
        slideToScroll: 6,
      },
    },
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: getButtonsQtyToShow(),
        slideToScroll: 6,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: getButtonsQtyToShow(),
        slideToScroll: 12,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: getButtonsQtyToShow(),
        slideToScroll: 5,
      },
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: getButtonsQtyToShow(),
        slideToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        rows: 2,
        slidesToShow: 3,
        slideToScroll: 0,
        slidesPerRow: 2,
      },
    },
    {
      breakpoint: 450,
      settings: {
        rows: 2,
        slidesToShow: 3,
        slideToScroll: 0,
        slidesPerRow: 2,
      },
    },
    {
      breakpoint: 380,
      settings: {
        rows: 2,
        slidesToShow: 3,
        slideToScroll: 0,
        slidesPerRow: 2,
      },
    },
  ];

  const [filterBrands, setFilterBrands] = useState([]);
  const brandsNumber = width <= 640 ? 11 : 20;

  useEffect(() => {
    const tempBrands = [];
    if (brands.length) {
      for (let i = 0; i < brandsNumber; i += 1) {
        tempBrands.push(brands[i]);
      }
    }
    setFilterBrands(tempBrands);
  }, [brands, brandsNumber]);

  const cards = filterBrands.map((brand, index) => {
    if (brand) {
      return (
        <Card
          id={`BrandsCarouselBrandCard-${stringNormalize(brand.name)}`}
          key={brand.id}
          className={`brand-card-component Brand-${index}`}
          onClick={() => {
            history.push(`/marca/${brand.id}`);
          }}
        >
          {brand?.discount > 0 && <span className="discount">{brand?.discount}%</span>}
          <LazyLoad>
            <img
              id={`BrandsCarouselBrandImg-${stringNormalize(brand.name)}`}
              data-sizes="auto"
              className={`logo lazyload  Brand-${index}`}
              src={brand.image}
              alt={brand.name}
            />
          </LazyLoad>
        </Card>
      );
    }
    return false;
  });

  return (
    <Grid item xs={12} className="carousel-brands ">
      <TitleSections
        id="BrandCarouselTitleSectionsBrands"
        title={title}
        onClick={() => history.push(viewMoreUrl)}
        show={false}
      />
      <Carousel sources="carousel-brands" responsiveSettings={responsiveSettings}>
        {cards}
        {viewMoreUrl && brands.length > 0 && (
          <MoreCard
            onClick={() => history.push(viewMoreUrl)}
            key={viewMoreUrl}
            url={MoreBrandsAsset}
            className="brand-card-component"
            title="paquete marca"
          >
            <Typography variant="caption">Más marcas</Typography>
          </MoreCard>
        )}
      </Carousel>
    </Grid>
  );
};

BrandCardCarouselBase.defaultProps = {
  activeDiscount: false,
};

BrandCardCarouselBase.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  viewMoreUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  activeDiscount: PropTypes.bool,
};

export const BrandCardCarousel = withRouter(BrandCardCarouselBase);
