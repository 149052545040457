export function isValidNit(strArg) {
  if (strArg == null) {
    // console.log('[@utils/validation/isValidNit]: ' + 'Argument can not be null');
    return false;
  }

  const vpri = [];
  let cleanStrArg = strArg;
  let x = 0;
  let y = 0;

  // Remove spaces ',' '.' '-' from the string
  cleanStrArg = cleanStrArg.replace(' ', '').replace('-', '').replace('.', '').replace(',', '');

  const stringLength = cleanStrArg.length - 1;

  // If what is remaining is not a number then return false
  if (isNaN(cleanStrArg)) {
    return false;
  }
  if (strArg.length < 9 || strArg.length > 16) {
    // console.log("[@utils/validation/isValidNit]: " + "Invalid length");
    return false;
  }

  vpri[1] = 3;
  vpri[2] = 7;
  vpri[3] = 13;
  vpri[4] = 17;
  vpri[5] = 19;
  vpri[6] = 23;
  vpri[7] = 29;
  vpri[8] = 37;
  vpri[9] = 41;
  vpri[10] = 43;
  vpri[11] = 47;
  vpri[12] = 53;
  vpri[13] = 59;
  vpri[14] = 67;
  vpri[15] = 71;

  for (let i = 0; i < stringLength; i++) {
    y = cleanStrArg.substr(i, 1);
    x += y * vpri[stringLength - i];
  }
  y = x % 11;

  // Verification number of NIT
  const verificationNumber = y > 1 ? 11 - y : y;
  // Last number introduced without taking the verification number into account
  const lastNumber = parseInt(cleanStrArg[stringLength], 10);
  return verificationNumber === lastNumber;
}
