import * as Yup from 'yup';
import { isValidNit } from '@utils/validations/nit.validations';
import { documentRegExp } from '@utils/validations';

const B2BValidationSchema = {
  businessName: Yup.string().required('Nombre de negocio requerido'),
  documentNumber: Yup.number().required('NIT requerido'),
  verificationDigit: Yup.number()
    .required('Dígito de verificación requerido')
    .test({
      name: 'digitVerification',
      message: 'Dígito incorrecto',
      test(value) {
        return isValidNit(`${this.parent.documentNumber}${value}`);
      },
    }),
};

const B2CValidationSchema = {
  documentType: Yup.string().required('Campo requerido'),
  documentNumber: Yup.string()
    .matches(documentRegExp, 'Documento no valido')
    .required('Campo requerido'),
};

const validationSchema = (isB2b) =>
  Yup.object().shape({
    name: Yup.string().required('Nombre requerido'),
    email: Yup.string()
      .email('Correo electrónico invalido')
      .required('Correo electrónico requerido'),
    password: Yup.string()
      .required('Contraseña requerida')
      .test({
        name: 'strongPassswordValidation1',
        message: 'Mínimo 8 caracteres',
        test: (value) => value?.length > 8,
      })
      .test({
        name: 'strongPassswordValidation2',
        message: 'Minimo 1 número',
        test: (value) => /\d/gm.test(value),
      })
      .test({
        name: 'strongPassswordValidation3',
        message: 'Minimo 1 mayúscula',
        test: (value) => /[A-Z]/gm.test(value),
      }),
    ...(isB2b ? B2BValidationSchema : B2CValidationSchema),
  });

export const formikConf = (isB2B, user) => ({
  validateOnChange: true,
  validateOnBlur: true,
  initialValues: {
    documentType: '',
    name: '',
    businessName: '',
    documentNumber: '',
    document: '',
    verificationDigit: '',
    lastName: '',
    email: '',
    password: '',
    alerts: false,
  },
  validationSchema: validationSchema(isB2B),
});

export const onsubmit = (handleSubmit) => (values, formikProps) => {
  formikProps.setSubmitting(false);
  handleSubmit(values);
};
