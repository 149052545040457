import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { CategoryList } from '@components/index';

import { UserInfo } from './components/UserInfo';

import './style.scss';

const NavigationDrawerBase = ({
  isOpen,
  onClose,
  categories,
  user,
  setSelectUserTypeOpen,
  setAuthModal,
}) => {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus
      className="navigation-drawer-category"
      anchor="left"
      classes={{
        paper: 'navigation-drawer-paper',
      }}
    >
      <div className="safe-space" />
      <UserInfo
        onClose={onClose}
        user={user}
        setSelectUserTypeOpen={setSelectUserTypeOpen}
        setAuthModal={setAuthModal}
      />
      <CategoryList categories={categories} onCloseMenu={onClose} />
    </Drawer>
  );
};

NavigationDrawerBase.defaultProps = {
  isOpen: false,
  onClose: () => {},
  setSelectUserTypeOpen: () => {},
};

NavigationDrawerBase.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}).isRequired,
  setSelectUserTypeOpen: PropTypes.func,
  setAuthModal: PropTypes.func.isRequired,
};

export const NavigationDrawer = withRouter(NavigationDrawerBase);
