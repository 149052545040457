import valid from 'card-validator';

const checkFullYearFormat = (yearVal) => {
  let newYearVal = yearVal;
  const FIXED_YEAR = 20;
  if (newYearVal < 100) {
    const nowYear = new Date().getFullYear();
    newYearVal += Math.floor(nowYear / 100) * 100;
    if (newYearVal > nowYear + FIXED_YEAR) {
      newYearVal -= 100;
    } else if (newYearVal <= nowYear - 100 + FIXED_YEAR) {
      newYearVal += 100;
    }
  }
  return newYearVal;
};

export const isNotEmpty = (o) => o && !!(Object.values(o) && Object.values(o).length);
export const getInnerValue = (name, object) =>
  isNotEmpty(object) && name && name.split('.').reduce((acc, v) => acc && acc[v], object);

// Validators
export const validateCardNumber = (value) => {
  let error;

  const visaRegEx = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const mastercardRegEx = /^5[1-5][0-9]{14}$/;
  const amexRegEx = /^3[47][0-9]{13}$/;
  const dinersRegEx = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  const codensaRegEx = /^590712[0-9]{10}$/;
  const crmRegEx = /^528209[0-9]{10}$/;
  const daviviendaRegEx = /^524708[0-9]{10}$/;
  const nequiRegEx = /^409355[0-9]{10}$/;
  const visaDebitRegEx = /^450942[0-9]{10}$/;

  if (!value) {
    error = 'Número de tarjeta requerido';
  }

  value = value.replace(/\s/g, ''); // Remove any whitespace

  if (!/^[0-9]{13,19}$/.test(value)) {
    error = 'Número de tarjeta inválido';
  }

  if (
    !visaRegEx.test(value) &&
    !mastercardRegEx.test(value) &&
    !amexRegEx.test(value) &&
    !dinersRegEx.test(value) &&
    !codensaRegEx.test(value) &&
    !crmRegEx.test(value) &&
    !daviviendaRegEx.test(value) &&
    !nequiRegEx.test(value) &&
    !visaDebitRegEx.test(value)
  ) {
    error = 'Número de tarjeta inválido';
  }

  if (!valid.number(value).isValid) {
    error = 'Número de tarjeta inválido';
  }

  return error;
};

export const validateName = (value) => {
  let error;
  if (!value) error = 'Nombre requerido';
  else if (value.length < 4) error = 'Nombre inválido';
  return error;
};

export const validateCardDate = (value) => {
  if (!value) return 'Fecha de vencimiento requerida MM/AA';

  const regex = value.match(/^(0[1-9]{1}|1[0-2]{1})\/(2[0-9]|3[0-9]{1})$/);

  if (!regex) {
    return 'Fecha de vencimiento inválida';
  }

  const exp = new Date(checkFullYearFormat(1 * regex[2]), 1 * regex[1] - 1, 1).valueOf();
  const now = new Date();
  const currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
  if (exp <= currMonth) {
    return 'Fecha de vencimiento inválida';
  }
  return '';
};

export const validateCVV = (value) => {
  let error;
  if (!value) error = 'CVV requerido';
  else if (value.length !== 3 && value.length !== 4) error = 'CVV inválido';
  else if (!/^[0-9]+$/.test(value)) error = 'CVV inválido';
  return error;
};

export const validateEmail = (value) => {
  let error;
  if (!value) error = 'Email requerido';
  else if (!/\S+@\S+\.\S+/.test(value)) error = 'Email inválido';
  return error;
};

export const validateDocumentType = (value) => {
  let error;
  if (!value) error = 'Tipo de documento requerido';
  return error;
};

export const validateDocument = (value) => {
  let error;
  if (!value) error = 'Número de documento requerido';
  else if (value.length < 4) error = 'Número de documento inválido';
  return error;
};

export const getPaymentMethod = (cardNumber) => {
  if (!cardNumber) return null;
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) return 'VISA';
  if (/^450942[0-9]{10}$/.test(cardNumber)) return 'VISA_DEBIT';
  if (/^5[1-5][0-9]{14}$/.test(cardNumber)) return 'MASTERCARD';
  if (/^5[6-9][0-9]{14}$/.test(cardNumber)) return 'MASTERCARD_DEBIT';
  if (/^3[47][0-9]{13}$/.test(cardNumber)) return 'AMEX';
  if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNumber)) return 'DINERS';
  if (/^590712[0-9]{10}$/.test(cardNumber)) return 'CODENSA';
  return null;
};
