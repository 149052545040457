/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Button, Fade, Grid, Modal, Typography } from '@material-ui/core';

import { history } from '@redux/store';
import { ReactComponent as ErrorIcon } from '@assets/svg/error_icon.svg';
import { currencyFormatter } from '@utils/render';

import './styles.scss';

const MinimumOrder = ({ open, closeModal, minimunAmountOrder }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modal__paper">
          <ErrorIcon width={60} />
          <Typography classes={{ root: 'modal__warninigTitle' }}>¡Pedido Minimo!</Typography>

          <Typography classes={{ root: 'modal__warningTextOne' }}>
            Tus compras deben ser superiores a{' '}
            <strong>
              <span color="primary">{currencyFormatter(minimunAmountOrder)}</span>
            </strong>
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Button
                id="PaymentMethodsMinimumOrderNo"
                classes={{ root: 'modal__buttonWhite' }}
                onClick={() => {
                  history.push('/');
                  closeModal();
                }}
                fullWidth
              >
                Seguir Comprando
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

MinimumOrder.defaultProps = {
  open: false,
  minimunAmountOrder: 0,
};

MinimumOrder.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  minimunAmountOrder: PropTypes.number,
};

export { MinimumOrder };
