import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { ReactComponent as CheckIcon } from '@assets/svg/whiteCheck.icon.svg';

import 'react-toastify/dist/ReactToastify.css';

import styles from './productAddToast.module.scss';

const Message = () => (
  <div className={styles.messageBox}>
    <CheckIcon />
    <p className={styles.addProductSnackBar__message}>Agregaste un producto al carrito</p>
  </div>
);

export const ProductAddToast = () => {
  const dispatch = useDispatch();
  const notify = () =>
    toast.dark(<Message />, {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
      className: styles.addProductSnackBar,
    });

  React.useEffect(() => {
    notify();
    setTimeout(() => dispatch(ShoppingCartCreators.closeAddProductSnackbar()), 2000);
  }, [dispatch]);

  return <ToastContainer />;
};
