import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import './style.scss';

const MoreCard = ({ children, url, title, onClick, className }) => (
  <Card className={`cursor-pointer more-card ${className}`} onClick={onClick}>
    <SVG src={url} alt={title} />
    {children}
  </Card>
);

MoreCard.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { MoreCard };
