import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';

import { Alert } from '@mui/material';
import { BannerCarousel, ProductList, BreadCrumb } from '@components/index';
import { Creators as ProductsCreators } from '@reducers/Products';
import { Creators as BrandsCreators } from '@reducers/Brands';

import { Layout } from '../Layout/layout';

const ProductsByBrandContainer = ({ detailsBrand, products, isLoading, hasMore }) => {
  const dispatch = useDispatch();
  const { brandId } = useParams();

  useEffect(() => {
    dispatch(ProductsCreators.getProductsByBrandRequest(brandId, true));
    dispatch(BrandsCreators.brandsDetails(brandId));
  }, [dispatch, brandId]);

  return (
    <Layout isFullWidth>
      <Grid container item xs={12} className="category-container">
        <Grid container>
          <BannerCarousel isLoading={isLoading} banners={detailsBrand} />
          {detailsBrand[0]?.discount > 0 && (
            <Grid
              item
              style={{
                margin: '0 auto',
                marginTop: '-85px',
                marginBottom: '30px',
              }}
            >
              <Alert
                icon={false}
                severity="success"
                sx={{
                  margin: '10px 0',
                  fontSize: '1rem',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6" className="text-center">
                  ¡Tenemos una excelente noticia para ti!
                </Typography>
                Todo el catálogo de productos de la marca {detailsBrand[0]?.name} cuenta con un{' '}
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: '1.3rem',
                    color: '#F65858',
                  }}
                >
                  {detailsBrand[0]?.discount}%
                </span>{' '}
                de descuento.
              </Alert>
            </Grid>
          )}
        </Grid>

        <BreadCrumb
          urls={[
            { name: 'Nuestras marcas', route: '/todas-brands' },
            { name: detailsBrand[0]?.name ?? '', route: '' },
          ]}
        />
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ margin: 'auto', marginTop: '20px' }}
        >
          <ProductList
            getMore={() => {
              if (!isLoading && hasMore) {
                dispatch(ProductsCreators.getProductsByBrandRequest(brandId));
              }
            }}
            isLoading={isLoading}
            products={products}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

ProductsByBrandContainer.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      brand: PropTypes.string,
      brandId: PropTypes.number,
    }),
  ),
  detailsBrand: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

ProductsByBrandContainer.defaultProps = {
  products: [],
  detailsBrand: [],
};

const mapStateToProps = ({ products: { items, hasMore }, brands: { detailsBrand, isLoading } }) => {
  const url = { ...detailsBrand };
  return {
    products: items,
    brandBanner: url,
    isLoading,
    hasMore,
    detailsBrand,
  };
};

const ProductsByBrand = connect(mapStateToProps)(withRouter(ProductsByBrandContainer));

export default ProductsByBrand;
