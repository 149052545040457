// @ts-nocheck
import { call } from 'redux-saga/effects';
import { parseProduct } from '@transforms/ApiToProducts.transform';
import { odooApi } from './api';

export const ProductsService = {
  *getProductNew({ page }) {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/products', {
      is_new: true,
      page,
    });
    return {
      ...response,
      data: parseProduct(response.data),
      hasMore: response.data.length > 0,
    };
  },
  *getSuggested({ page }) {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/product/suggested', {
      page,
    });
    return {
      ...response,
      data: parseProduct(response.data),
    };
  },
  *getByBrand({ brandId, page }) {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/products', {
      brand_id: brandId,
      perPage: 20,
      page,
    });
    return {
      ...response,
      data: parseProduct(response.data),
      hasMore: response.data.length > 0,
    };
  },
  *getByCategoryId({ categoryId, page, subCategoryId }) {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/products', {
      category_id: categoryId,
      perPage: 20,
      page,
      dynamic_category_id: subCategoryId,
    });
    return {
      ...response,
      data: parseProduct(response.data),
      hasMore: response.data.length > 0,
    };
  },
  *getByQuery({ hint, page }) {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/products', {
      hint,
      perPage: 20,
      page,
    });
    return {
      ...response,
      data: parseProduct(response.data),
      hasMore: response.data.length > 0,
    };
  },
  *getProductDetails({ productId, templateId }) {
    const data = productId ? { product_id: productId } : { product_template_id: templateId };
    const response = yield call({ context: odooApi, fn: odooApi.get }, `/products`, data);
    return {
      ...response,
      data: parseProduct(response.data),
    };
  },

  *getByDietID({ dietsId, page }) {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/products', {
      diet_id: dietsId,
      page,
    });
    return {
      ...response,
      data: parseProduct(response.data),
      hasMore: response.data.length > 0,
    };
  },
};
