/* eslint-disable react-hooks/exhaustive-deps */
import QueryString from 'query-string';
import { useEffect, useState } from 'react';
import { history } from '@utils/history/history.utils';

export function useKeepUiVersionInQueryStringIfPresent() {
  let queryParams = QueryString.parse(history.location.search);

  const [initialUiVersion] = useState(queryParams.ui_version);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      const listener = (location) => {
        queryParams = QueryString.parse(location.search);
        const { ui_version: uiVersion } = queryParams;
        if (!uiVersion && initialUiVersion) {
          history.replace({
            pathname: location.pathname,
            search: QueryString.stringify({
              ...queryParams,
              ui_version: uiVersion || initialUiVersion,
            }),
          });
        }
      };

      listener(window.location);
      return history.listen(listener);
    }
  }, [history]);
}
