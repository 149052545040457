import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';

import { ProductList } from '@components/organisms';
import { Creators as ProductsCreators } from '@reducers/Products';

import './by-hint.container.scss';

import { Layout } from '../Layout/layout';

const ProductsByHintContainer = ({ products, isLoading, hasMore }) => {
  const dispatch = useDispatch();
  const { hint } = useParams();
  useEffect(() => {
    dispatch(ProductsCreators.getProductsByHintRequest(hint, true));
  }, [hint, dispatch]);
  return (
    <Layout>
      <Grid container item xs={12} className="by-hint-container">
        {products.length !== 0 && (
          <Grid item xs={12} style={{ marginBottom: '25px' }}>
            <Typography variant="h4">
              Resultado para “<span className="by-hint-container__hint-text">{hint}</span>”
            </Typography>
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="center">
          <ProductList
            getMore={() => {
              if (!isLoading && hasMore) {
                dispatch(ProductsCreators.getProductsByHintRequest(hint));
              }
            }}
            isLoading={isLoading}
            products={products}
          />
        </Grid>
        {products.length === 0 && !isLoading && (
          <Typography
            variant="h4"
            style={{ marginBottom: '25px', textAlign: 'center', width: '100%' }}
          >
            Lo sentimos, la búsqueda “<span className="by-hint-container__hint-text">{hint}</span>
            ” <br /> no ha coincidido con ningún producto.
          </Typography>
        )}
      </Grid>
    </Layout>
  );
};

ProductsByHintContainer.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

ProductsByHintContainer.defaultProps = {
  products: [],
};

const mapStateToProps = ({ products: { items, isLoading, hasMore } }) => {
  return {
    products: items,
    isLoading,
    hasMore,
  };
};

const ProductsByHint = connect(mapStateToProps)(withRouter(ProductsByHintContainer));

export default ProductsByHint;
