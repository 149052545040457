export const {
  REACT_APP_ENVIRONMENT: NODE_ENVIRONMENT,
  REACT_APP_WEB_URL: WEB_URL,
  REACT_APP_AWS_ASSETS: AWS_ASSETS,
  REACT_APP_KUSHKI_URL: KUSHKI_URL,
  REACT_APP_KUSHKI_MERCHANT_ID: KUSHKI_MERCHANT_ID,
  REACT_APP_REDUX_LOGGER_ENABLED: REDUX_LOGGER_ENABLED,
  REACT_APP_APP_ID_FACEBOOK: APP_ID_FACEBOOK,
  REACT_APP_CLIENT_ID_GOOGLE: CLIENT_ID_GOOGLE,
  REACT_APP_RAPPIPAY_URL: RAPPIPAY_URL,
  REACT_APP_RAPPIPAY_CLIENT_ID: RAPPIPAY_CLIENT_ID,
  REACT_APP_RAPPIPAY_CLIENT_SECRET: RAPPIPAY_CLIENT_SECRET,
  REACT_APP_EMAIL_DEMO: EMAIL_DEMO,
  REACT_APP_PASSWORD_DEMO: PASSWORD_DEMO,
} = process.env;

export const isProd = NODE_ENVIRONMENT === 'production';
