import { put, select } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import { getBrandsPage } from '@reducers/Brands';
import { Creators as BrandsCreators } from '@reducers/Brands/actionsTypes';
import { BrandsService } from '@services/brands.service';
import { history } from '@redux/store';

export function* getBrands() {
  try {
    const page = yield select(getBrandsPage);
    const response = yield BrandsService.getBrands({
      page: page + 1,
    });

    const res = {
      ...response,
      data: response.data?.sort((a, b) => {
        if (b.discount < a.discount) {
          return -1;
        }
        if (b.discount > a.discount) {
          return 1;
        }
        return 0;
      }),
    };
    yield put(BrandsCreators.brandsSuccess(res));
  } catch (err) {
    captureException(err);
  }
}

export function* getDetailsBrand({ brandId }) {
  try {
    const response = yield BrandsService.getDetailsBrand({
      brandId,
    });
    if (response?.data?.length > 0) {
      yield put(BrandsCreators.brandsDetailsSuccess(...response.data));
    } else {
      history.push(`/404/`);
    }
  } catch (err) {
    captureException(err);
  }
}
