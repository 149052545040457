export const transformCategories = ({ categories, sections }) => {
  const mergeCategories = categories.concat(sections);

  const getBanner = (image, type = 'Banner SUPERFUDS') => {
    if (image.length > 0) {
      const filterImage = image.find((item) => item.type === type);
      return filterImage ? filterImage.url : '';
    }
    return '';
  };

  const mapSubcategory = (slugCategories) => (subcat) => {
    return {
      id: subcat.id,
      slugCategories,
      alias: subcat.alias,
      slugSubcategories: subcat.slug,
      name: subcat.name,
      thumbnail: getBanner(subcat.image, 'Banner SUPERFUDS'),
      bannerGeneral: getBanner(subcat.image, 'General'),
      metaDescription: subcat.meta_description,
      metaKeywords: subcat.meta_keywords,
      metaTitle: subcat.meta_title,
      dynamic_category: subcat?.dynamic_category,
      type: subcat.type,
    };
  };

  const mapCategory = (json) => {
    const filerSubCategories = json.subcategories.filter((subcategory) => {
      return subcategory.product_count > 0;
    });
    return {
      id: json.id,
      subcategories: filerSubCategories.map(mapSubcategory(json.slug)),
      alias: json.alias,
      name: json.name,
      description: json.description || 'Sin categoria',
      slug: json.slug,
      svg: json.thumbnail,
      banner: getBanner(json.image, 'Banner SUPERFUDS'),
      bannerGeneral: getBanner(json.image, 'General'),
      metaDescription: json.meta_description,
      metaKeywords: json.meta_keywords,
      metaTitle: json.meta_title,
      section: getBanner(json.image, 'Sección'),
      image: json.image,
      dynamic_category: json?.dynamic_category,
      type: json.type,
    };
  };
  return mergeCategories
    .filter((category) => category.product_count > 0)
    .sort((a, b) => a.order - b.order)
    .map((category) => mapCategory(category));
};
