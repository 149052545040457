import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { List, ListItem, Typography, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { history } from '@redux/store';

import './style.scss';

const CategoryListBase = ({ categories, onCloseMenu }) => {
  const defaultToggleStates = [...categories].map(() => false);
  const [toggleStates, setToggleStates] = useState(defaultToggleStates);

  function toggleState(index) {
    const newState = toggleStates.slice();
    newState[index] = !newState[index];
    setToggleStates(newState);
  }

  function buildSubcategory(category, subcategory, onClose = () => { }) {
    return (
      <ListItem
        key={subcategory.id}
        className={`subcategory pl-4 Subcategory-${subcategory.slugSubcategories}`}
        button
        id={`Subcategory-${subcategory.slugSubcategories}`}
        onClick={() => {
          onClose();
          history.push(`/categoria/${category.id}/${subcategory.id}/`);
        }}
      >
        <Typography className="dark-font" variant="subtitle2">
          {subcategory.name}
        </Typography>
      </ListItem>
    );
  }

  function buildCategory(category, isOpen, onToggle, onClose = () => {}, onCloseMenu) {
    return (
      <div key={category.id}>
        <div className="category">
          <ListItem
            button
            id={`Category-${category.slug}`}
            className={`category-text  Category-${category.slug}`}
            onClick={() => {
              onClose();
              history.push(`/categoria/${category.id}`);
            }}
          >
            <Typography variant="subtitle2" className="darker-font category-text">
              {category.name}
            </Typography>
          </ListItem>
          {category.subcategories.length > 0 && (
            <ListItem
              button
              onClick={() => {
                onToggle();
              }}
              className="expand-arrow"
            >
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
        </div>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {category.subcategories.map((e) => buildSubcategory(category, e, onCloseMenu))}
          </List>
        </Collapse>
      </div>
    );
  }

  return (
    <List className="navigation-drawer-category-list">
      {categories?.map((e, index) =>
        buildCategory(e, toggleStates[index], () => toggleState(index), onCloseMenu),
      )}
    </List>
  );
};

CategoryListBase.defaultProps = {
  onCloseMenu: () => {},
};

CategoryListBase.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCloseMenu: PropTypes.func,
};

export const CategoryList = withRouter(CategoryListBase);
