import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import { ReactComponent as NextIcon } from '@assets/svg/next.carousel.icon.svg';
import { ReactComponent as BackIcon } from '@assets/svg/back.carousel.icon.svg';
import { useWindowSize } from '@hooks/use-windows-size.hook';

import './styles.scss';

const ProductViewerModal = ({ product }) => {
  const { width } = useWindowSize();
  const [previewImg, setPreviewImg] = useState(null);
  const [images, setImages] = useState(null);
  const onImageClick = (url) => {
    setPreviewImg(url);
  };

  useEffect(() => {
    const { previewImage, images } = product;
    setPreviewImg(previewImage);
    setImages(images);
  }, [product]);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    adaptiveHeight: width <= 992 && false,
    draggable: true,
    mobileFirst: true,
    focusOnSelect: width >= 992 && true,
    vertical: width >= 992 && true,
    nextArrow: <NextIcon width={50} height={50} />,
    prevArrow: <BackIcon width={50} height={50} />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slideToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
          slideToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 3,
          slideToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="product-viewer">
      <div className="product-viewer__thumbs">
        <Slider {...settings}>
          {images &&
            images.map(
              (url, index) =>
                url && (
                  <img
                    aria-hidden
                    className="cursor-pointer"
                    onClick={() => onImageClick(`${url}`)}
                    key={`product-viewer__${url}`}
                    src={url}
                    alt={`Imagen N°${index + 1} del producto`}
                  />
                ),
            )}
        </Slider>
      </div>
      <div className="product-viewer__preview">
        <InnerImageZoom
          zoomType="hover"
          origen={previewImg}
          src={previewImg}
          zoomSrc={previewImg}
          zoomScale={width <= 400 ? 2 : 1}
          zoomPreload
          fullscreenOnMobile
        />
      </div>
    </div>
  );
};
ProductViewerModal.defaultProps = {
  previewImage: '',
  images: [],
};

ProductViewerModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  previewImage: PropTypes.string,
  product: PropTypes.shape({
    previewImage: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }).isRequired,
};

export { ProductViewerModal };
