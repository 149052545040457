import { useState } from 'react';
import { useMount } from '@superfuds/sf-app/dist/index.modern';
import { legalDocs } from '@constants/legal-docs';

export function useLegalDocs() {
  const [contentLegalDocs, setContentLegalDocs] = useState([]);

  useMount(() => {
    const fetchLedalDocs = async () => {
      const url = legalDocs.DATA;
      try {
        const response = await fetch(url, {
          cache: 'no-cache',
        });
        const data = await response.json();
        setContentLegalDocs(data);
      } catch (e) {
        throw new Error(e.message);
      }
    };
    fetchLedalDocs();
  });
  return {
    ...contentLegalDocs,
  };
}
