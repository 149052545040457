import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ProductCardV2 } from '@superfuds/saori';
import { Grid } from '@material-ui/core';
import { useDispatch, connect } from 'react-redux';
import { Creators as UserCreators } from '@reducers/User';
import { history } from '@redux/store';
import { COLD_AND_FROZEN_TYPE } from '@constants/index';
import { ScrollListener } from '@components/index';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { Creators as ProductActions } from '@reducers/Product/actionsTypes';
import { Creators as CategoriesCreators } from '@reducers/Categories';

import './style.scss';

const itemsToSkeletonLoad = 14;

export const ProductListBase = ({
  products,
  getMore,
  isLoading,
  shoppingCart,
  hasSubcategories,
  isLoggedIn,
  token,
}) => {
  const dispatch = useDispatch();
  const [currentVariantId] = useState(null);
  const toggleClothesModal = (product, addItem) =>
    dispatch(
      ProductActions.openClothesModal({ product, callback: addItem, source: 'ProductCard' }),
    );
  const addProduct = (product) => dispatch(ShoppingCartCreators.addToShoppingCart(product));

  const missingCardsInRow = useMemo(
    () => (products.length % 4 ? 4 - (products.length % 4) : 0),
    [products.length],
  );

  const skeletonRows = useMemo(
    () =>
      Array.from(Array(itemsToSkeletonLoad + missingCardsInRow)).map((item, idx) => (
        <Grid
          key={`product-loading-${idx}`}
          container
          item
          xs={4}
          lg={3}
          className="products-list__product"
          justifyContent="center"
        >
          <ProductCardV2
            isLoading
            token={token}
            onPrince={() => dispatch(UserCreators.setAuthModal(true))}
          />
        </Grid>
      )),
    [missingCardsInRow, token, dispatch],
  );

  return (
    <Grid container item xs={12} className="products-list">
      <Grid
        container
        item
        xs={12}
        justifyContent={hasSubcategories ? 'space-evenly' : 'center'}
        style={{ gap: '2vw 2vh' }}
      >
        {products?.map((product, idx) => {
          let productQuantity = 0;
          if (
            shoppingCart.items?.standart?.items?.length ||
            shoppingCart.items?.cold?.items?.length
          ) {
            const items = shoppingCart.items?.standart?.items?.length
              ? shoppingCart.items?.standart?.items
              : shoppingCart.items?.cold?.items;

            const productFiltered = items.filter(
              (currentProduct) => product.id === currentProduct.itemId,
            );
            if (productFiltered.length > 0) {
              productQuantity = productFiltered[0].quantityInPackages;
            }
          }
          const handleColdFrozenType = (productType) => {
            if (product.characteristics && product.characteristics.length) {
              const match = product.characteristics.filter(
                (characteristic) => characteristic.name === productType,
              );
              return match.length > 0;
            }
            return false;
          };
          let currentVariant = null;
          if (product.isClothes) {
            const variantWithStock = product?.variants.find((v) => v.isSelected);
            currentVariant =
              variantWithStock !== undefined ? variantWithStock : product?.variants[0];
          }
          const sharedProps = {
            product: { ...product, totalInventory: product.total_inventory },
            idx,
            daysUntilExpiration: product.daysUntilExpiration,
            onDetailClick: () => {
              const pathname =
                product.id === 0
                  ? `/p/${product?.template_id}/${product?.variants[0]?.id}`
                  : `/p/${product.id}`;

              history.push({
                pathname,
                state: {
                  totalInventory: product?.total_inventory ? product?.total_inventory : 0,
                  routeId: product?.routeId ? product?.routeId : null,
                  routeType: product?.routeType ? product?.routeType : null,
                },
              });
            },
            onBrandClick: () => {
              if (product && product.brandId) {
                history.push(`/marca/${product.brandId}`);
              }
            },
            onAddClick: () => {
              addProduct({
                ...product,
                itemId: product.id,
                quantityInPackages: 1,
              });
            },
            toggleClothesModal: (variantId = null) => {
              let mappedClothesProduct = product;
              const productVatiantId = variantId || currentVariantId;
              if (productVatiantId !== null) {
                const mapperVariantsProduct = product?.variants?.map((variant) => {
                  if (variant.id === productVatiantId) {
                    return { ...variant, isSelected: true };
                  }
                  return { ...variant, isSelected: false };
                });
                mappedClothesProduct = {
                  ...mappedClothesProduct,
                  variants: mapperVariantsProduct,
                };
              }
              if (isLoggedIn) {
                toggleClothesModal(mappedClothesProduct, addProduct);
              } else if (product?.appliesSinIvaDay) {
                dispatch(UserCreators.setAuthModal(true));
              }
            },
            onDeleteClick: () => {
              dispatch(
                ShoppingCartCreators.upsertShoppingCart([
                  {
                    itemId: product.id,
                    quantityInPackages: productQuantity > 1 ? productQuantity - 1 : 0,
                    isExpress: product.isExpress,
                    routeId: product.routeId,
                  },
                ]),
              );
            },
            productsQuantity: productQuantity,
            isProductUpserting: shoppingCart.isLoading,
            isRefrigerated: handleColdFrozenType(COLD_AND_FROZEN_TYPE.COLD),
            isFrozen: handleColdFrozenType(COLD_AND_FROZEN_TYPE.FROZEN),
          };
          return (
            <section key={idx} className="products-list__product">
              <ProductCardV2
                toggleShowDictionaryModal={() => {
                  dispatch(CategoriesCreators.openModalDiets(true));
                }}
                type={product.isClothes ? 'clothes' : 'product'}
                currentVariantId={currentVariantId === null ? currentVariant?.id : currentVariantId}
                {...sharedProps}
                token={token}
                onPrince={() => dispatch(UserCreators.setAuthModal(true))}
              />
              <div style={{ marginBottom: 25 }} />
            </section>
          );
        })}
        {isLoading && skeletonRows}
        <ScrollListener callback={getMore} />
      </Grid>
    </Grid>
  );
};

ProductListBase.defaultProps = {
  products: [],
  token: '',
  hasSubcategories: false,
  filterCity: {
    city: {
      name: '',
    },
  },
};

ProductListBase.propTypes = {
  getMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  token: PropTypes.string,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
    }),
  ).isRequired,
  filterCity: PropTypes.shape(
    PropTypes.shape({
      city: PropTypes.shape({ name: PropTypes.string }),
    }),
  ),
  clientType: PropTypes.number.isRequired,
  shoppingCart: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    coupon: PropTypes.shape({
      namePromo: PropTypes.string,
    }),
  }).isRequired,
  hasSubcategories: PropTypes.bool,
};

const mapStateToProps = ({
  auth: { token },
  delivery: { userLocations, filterCity },
  user: { clientType, isLoggedIn },
  sales: { shoppingCart },
}) => ({
  token,
  userLocations,
  filterCity,
  clientType,
  shoppingCart,
  isLoggedIn,
});

export const ProductList = connect(mapStateToProps)(ProductListBase);
