import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { captureException } from '@sentry/react';
import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { reactotron } from '@config/reactotronConfig';
import { debugConfig } from '@config/debugConfig';
import { REDUX_LOGGER_ENABLED } from '@constants/env-vars.constants';
import { updateReducers } from './rehydration';

// creates the store
export const configureStore = (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Navigation Middleware ------------ */
  // middleware.push(appNavigatorMiddleware) TODO

  /* ------------- Analytics Middleware ------------- */
  // middleware.push(ScreenTracking) TODO

  /* ------------- Saga Middleware ------------- */
  // eslint-disable-next-line no-console
  const sagaMonitor = debugConfig.useReactotron ? console.tron.createSagaMonitor() : null;
  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor,
    onError(error, errorInfo) {
      // If this is called you should be worried
      // because all the app crashed so maybe you have
      // some unhandled exception somewhere
      // TODO show the user some message
      captureException(error);
    },
  });

  middleware.push(sagaMiddleware);
  if (process.env.REACT_APP_ENVIRONMENT !== 'production' && !REDUX_LOGGER_ENABLED)
    middleware.push(logger);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  const createAppropriateStore = createStore;
  if (debugConfig.useReactotron) {
    enhancers.push(reactotron.createEnhancer());
  }

  const store = createAppropriateStore(rootReducer, compose(...enhancers));

  // configure persistStore and check reducer version number
  if (reduxPersistConfig.active) {
    updateReducers(store);
  }

  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga);
  return {
    store,
    sagasManager,
    sagaMiddleware,
  };
};
