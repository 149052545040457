import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

const BreadCrumb = ({ urls }) => {
  return (
    <div className="BreadCrumb__wrapper">
      <Link className="BreadCrumb__home" to="/">
        Home
      </Link>
      {urls.map((url, index) => (
        <Link
          key={url.name}
          className={index === urls.length - 1 ? 'BreadCrumb__last' : 'BreadCrumb__prev'}
          to={url.route}
        >
          {`${url.name}`}
        </Link>
      ))}
    </div>
  );
};

BreadCrumb.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { BreadCrumb };
