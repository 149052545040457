import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '@superfuds/saori';
import { capitalizeText } from '@utils/format/format.utils';
import { currencyFormatter } from '@utils/render';
import { MINIMUM_TC_PAYMENT, PAYMENT__METHODS } from '@constants/index';

import { ReactComponent as EnabledIcon } from '@assets/svg/statusIconEnabled.svg';
import { ReactComponent as DisabledIcon } from '@assets/svg/statusIconDisabled.svg';
import { ReactComponent as TCIcon } from '@assets/svg/TCIcon.svg';

import './styles.scss';

const TCOptionSave = ({ open, paymentMethod, isDisabled }) => {
  return (
    <Card
      key={paymentMethod.id}
      className={`paymentOption ${
        paymentMethod?.active === true ? 'paymentOption-active' : 'paymentOption-whiteBg'
      }`}
    >
      <Grid container classes={{ root: 'paymentOption__dataContainer' }}>
        <Grid
          container
          justifyContent="flex-start"
          classes={{ root: 'paymentOption__paymentDetail' }}
        >
          <TCIcon
            id="PaymentMethodsPaymentOptionTCOptionSaveMethodOptionTCIcon"
            style={{ marginRight: 20 }}
          />
          <Grid container direction="column" style={{ maxWidth: '80%' }}>
            <Typography
              classes={{
                root: isDisabled
                  ? 'paymentOption__paymentDetail--disabled'
                  : 'paymentOption__paymentDetail',
              }}
            >
              {capitalizeText(paymentMethod.name)}
              <span className="paymentOption__TCNumber">{paymentMethod.maskedNumber}</span>
            </Typography>
            {!paymentMethod?.method || paymentMethod?.method !== PAYMENT__METHODS.TC ? (
              <Typography
                classes={{
                  root: isDisabled ? 'paymentOption__label--disabled' : 'paymentOption__label',
                }}
              >
                Tarjeta de crédito
              </Typography>
            ) : null}
            {isDisabled && (
              <Typography component="span" classes={{ root: 'paymentOption__info' }}>
                Pago para compra mínima de {currencyFormatter(MINIMUM_TC_PAYMENT)}
              </Typography>
            )}
          </Grid>
        </Grid>
        {(open || paymentMethod?.method === PAYMENT__METHODS.TC) && !isDisabled && (
          <EnabledIcon
            id="PaymentMethodsPaymentOptionTCOptionSaveMethodOptionEnabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
        {!open && paymentMethod?.method !== PAYMENT__METHODS.TC && !isDisabled && (
          <DisabledIcon
            id="PaymentMethodsPaymentOptionTCOptionSaveMethodOptionDisabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
      </Grid>
    </Card>
  );
};

TCOptionSave.propTypes = {
  open: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    cardName: PropTypes.string,
    maskedNumber: PropTypes.string,
    method: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export { TCOptionSave };
