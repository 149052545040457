import { v4 as uuidv4 } from 'uuid';
import { DEVICESESSIONID } from '@constants/index';

export const mapPseTransferBody = ({ bank, documentType, documentNumber, email }, callbackUrl) => ({
  bankCode: bank.toString(),
  paymentMethod: 'PSE',
  userType: documentType === 'NIT' ? 'J' : 'N',
  url_response: callbackUrl,
  document: documentNumber,
  email,
  documentType,
  cookie: uuidv4(),
  userAgent: navigator.userAgent,
  deviceSessionId: localStorage.getItem(DEVICESESSIONID),
});

export const mapCreditCardBody = (data) => {
  const { paymentMethod, cardNumber, cvv, cardName, date, installmentsNumber, saveCard } = data;
  const [month, year] = date.split('/');
  const formattedDate = `20${year}/${month}`;

  return {
    paymentMethod,
    creditCard: {
      number: cardNumber.replace(/\s/g, ''),
      securityCode: cvv,
      expirationDate: formattedDate,
      name: cardName,
      installmentsNumber: parseInt(installmentsNumber, 10),
    },
    saveCard,
    cookie: uuidv4(),
    userAgent: navigator.userAgent,
    deviceSessionId: localStorage.getItem(DEVICESESSIONID),
  };
};

export const mapCreditCardBodySave = (data) => {
  return {
    paymentMethod: data.name,
    creditCard: {
      cardId: data.id,
      installmentsNumber: data.installmentsNumber,
    },
    cookie: uuidv4(),
    userAgent: navigator.userAgent,
    deviceSessionId: localStorage.getItem(DEVICESESSIONID),
  };
};

export const pseProcessorStateMapper = (processorState) => {
  switch (processorState) {
    case 'PENDING':
      return 'PENDING';
    case 'APPROVED':
      return 'APPROVED';
    case 'SUBMITTED':
      return 'SUBMITTED';
    case 'DECLINED':
      return 'DECLINED';
    case 'EXPIRED':
      return 'EXPIRED';
    default:
      return processorState;
  }
};
