import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { CircleLoader } from '@superfuds/saori';
import { Button, Grid, Typography, TextField } from '@material-ui/core';
import { formikConf, onsubmit } from './signup.formik';

import './style.scss';

export const SignupBrands = ({ onSubmit, brandError, bransisLoading }) => {
  return (
    <Grid item xs className="email-signup" zeroMinWidth>
      <Typography variant="h5" className="email-signup__title">
        ¡Registrate gratis!
      </Typography>
      <Formik {...formikConf()} onSubmit={onsubmit(onSubmit)}>
        {({ errors, handleBlur, handleChange, touched }) => (
          <Form>
            <Grid container className="email-signup__fields">
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                className="email-signup__field"
                wrap="nowrap"
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Nombre Completo"
                      error={Boolean(errors.name && touched.name)}
                      variant="outlined"
                      name="name"
                      value={field.value}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.name && touched.name && String(errors.name)}
                      style={{ width: '100%' }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid container item xs={12} className="email-signup__field" justify="space-between">
                <Grid item xs={6} className="email-signup__field text-left">
                  <Field name="phone">
                    {({ field, form }) => (
                      <TextField
                        label="N° celular"
                        variant="outlined"
                        name="phone"
                        value={field.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.phone && touched.phone)}
                        helperText={errors.phone && touched.phone && String(errors.phone)}
                        type="number"
                        style={{ width: '98%' }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6} className="email-signup__field  text-right">
                  <Field name="email">
                    {({ field }) => (
                      <TextField
                        label="Correo electrónico"
                        error={Boolean(errors.email && touched.email)}
                        variant="outlined"
                        name="email"
                        value={field.value}
                        type="email"
                        autoComplete=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.email && touched.email && String(errors.email)}
                        style={{ width: '98%' }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className="email-signup__field"
                justify="space-between"
                wrap="nowrap"
              >
                <Grid item xs={4} className="email-signup__field text-left">
                  <Field name="businessName">
                    {({ field }) => (
                      <TextField
                        label="Nombre de la marca"
                        error={Boolean(errors.businessName && touched.businessName)}
                        variant="outlined"
                        name="businessName"
                        value={field.value}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.businessName && touched.businessName && String(errors.businessName)
                        }
                        style={{ width: '98%' }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4} className="email-signup__field text-right">
                  <Field name="website">
                    {({ field }) => (
                      <TextField
                        label="Sitio Web"
                        error={Boolean(errors.website && touched.website)}
                        variant="outlined"
                        name="website"
                        value={field.value}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.website && touched.website && String(errors.website)}
                        style={{ width: '98%' }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4} className="email-signup__field text-right">
                  <Field name="instagram">
                    {({ field }) => (
                      <TextField
                        label="Instagram"
                        error={Boolean(errors.instagram && touched.instagram)}
                        variant="outlined"
                        name="instagram"
                        value={field.value}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.instagram && touched.instagram && String(errors.instagram)
                        }
                        style={{ width: '98%' }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid container item xs={12} className="email-signup__field" justify="space-between">
                <div className="email-signup__document-number-field">
                  <Field name="documentNumber">
                    {({ field }) => (
                      <TextField
                        label="NIT"
                        error={Boolean(errors.documentNumber && touched.documentNumber)}
                        variant="outlined"
                        name="documentNumber"
                        value={field.value}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.documentNumber &&
                          touched.documentNumber &&
                          String(errors.documentNumber)
                        }
                        style={{ width: '100%' }}
                      />
                    )}
                  </Field>
                </div>
                <div className="email-signup__verification-digit-field">
                  <Field name="verification_digit">
                    {({ field }) => (
                      <TextField
                        label="DV"
                        error={Boolean(errors.verification_digit && touched.verification_digit)}
                        variant="outlined"
                        name="verification_digit"
                        value={field.value}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Field>
                </div>
              </Grid>
              <Grid container item xs={12} className="email-signup__field" justify="space-between">
                <Field name="notes">
                  {({ field }) => (
                    <TextField
                      label="¿Por qué decidiste aplicar como proveedor en Superfuds y qué esperas lograr al formar parte de nuestra plataforma?"
                      error={Boolean(errors.notes && touched.notes)}
                      variant="outlined"
                      name="notes"
                      value={field.value}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      rows={4}
                      helperText={errors.notes && touched.notes && String(errors.notes)}
                      style={{ width: '100%' }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Button type="submit" className="form__button">
              {bransisLoading ? <CircleLoader width="20" height="20" /> : 'Enviar'}
            </Button>
          </Form>
        )}
      </Formik>

      {brandError && (
        <Typography variant="h6" className="error">
          {brandError}
        </Typography>
      )}
    </Grid>
  );
};

SignupBrands.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  bransisLoading: PropTypes.bool,
  brandError: PropTypes.string,
};

SignupBrands.defaultProps = {
  bransisLoading: false,
  brandError: '',
};
