import { call, select } from 'redux-saga/effects';
import { getUserData } from '@reducers/User';
import { odooApi } from './api';

export const ShoppingCartService = {
  *getShoppingCart() {
    const user = yield select(getUserData);
    return yield call({ context: odooApi, fn: odooApi.get }, '/sales/shopping-cart', {
      email: user.email,
    });
  },
  *updateShoppingCart(items) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sales/shopping-cart',
      {},
      {
        items: [...items],
      },
    );
  },
  *updateShoppingCartPickUp(willPickUpInWarehouse) {
    return yield call(
      { context: odooApi, fn: odooApi.put },
      '/sales/shopping-cart/pick-up',
      {},
      {
        pick_up_method: willPickUpInWarehouse,
      },
    );
  },
  *resetShoppingCart() {
    return yield call({ context: odooApi, fn: odooApi.delete }, '/order/shopping-cart');
  },
  *getCoupons() {
    const user = yield select(getUserData);
    return yield call({ context: odooApi, fn: odooApi.get }, '/sales/coupons', {
      email: user.email,
    });
  },
  *addCoupon(coupon, ordenId) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sales/apply-coupon',
      {},
      {
        order_id: ordenId,
        coupon_code: coupon,
      },
    );
  },
  *selectCoupon(coupon, ordenId) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sales/select-coupon',
      {},
      {
        order_id: ordenId,
        coupon_code: coupon,
      },
    );
  },
  *deleteCoupon(ordenId) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sales/delete-coupon',
      {},
      {
        order_id: ordenId,
      },
    );
  },
  *handlePositiveBalallance(enabled) {
    return yield call(
      { context: odooApi, fn: odooApi.put },
      '/sales/shopping-cart/use-balances',
      {},
      {
        use_positive_balances: enabled,
      },
    );
  },
};
