import React from 'react';
import { Grid } from '@material-ui/core';
import { Image } from 'cloudinary-react';

import './WhybuySection.scss';
import { CLOUDINARY_KEY, CLOUDINARY_URL } from '@constants/index';

const contents = [
  {
    source: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/estilosf.png',
    alt: 'activist-girl',
  },
  {
    title: 'Cumple tu objetivo',
    description: (
      <span>
        Creemos en el poder de los emprendedores, por que nosotros también lo somos. Encuentra las
        mejores marcas del país en un solo lugar.
      </span>
    ),
    color: 'orange',
  },
  {
    source: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/ades.png',
    alt: 'happy-kid',
  },
  {
    title: 'Todos crecemos',
    description: (
      <span>
        ¡Resolvemos tu problema de compras al por mayor, no importa si eres una marca o una tienda,
        en este espacio te ayudamos a crecer!
      </span>
    ),
    color: 'purple',
  },
  {
    source: 'https://s3.sa-east-1.amazonaws.com/assets.superfuds.com/assets/ecom/v3/sfcard.png',
    alt: 'kid-eating',
  },
  {
    title: 'Siempre algo nuevo',
    description: (
      <span>
        Ampliamos continuamente nuestro portafolio para darte siempre lo mejor y ayudarte en tu
        meta.
      </span>
    ),
    color: 'green',
  },
];

export const WhybuySection = () => {
  return (
    <Grid container className="container-Whybuy">
      {contents?.map((el, idx) => {
        const NewURL = CLOUDINARY_URL + el.source;
        if (idx % 2 === 0) {
          return (
            <Grid
              key={`source-${idx}`}
              className={`card card--${el.color} hide`}
              item
              md={4}
              sm={6}
              xs={6}
            >
              <Image
                alt={el.alt}
                cloudName={CLOUDINARY_KEY}
                publicId={el.source}
                src={NewURL}
                width="100%"
                height="100%"
              />
            </Grid>
          );
        }
        return (
          <Grid
            key={`source-${idx}`}
            className={`card card--${el.color}`}
            item
            md={4}
            sm={6}
            xs={12}
          >
            <h3 className={`title title--${el.color}`}>{el.title}</h3>
            <p>{el.description}</p>
          </Grid>
        );
      })}
    </Grid>
  );
};

WhybuySection.propTypes = {};
