/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { TransactionStatusScreen, Loader } from '@superfuds/saori';
import { history } from '@redux/store';
import { useQueryParam } from '@hooks/use-query-param.hook';
import { QueryParams } from '@constants/index';
import { PAYMENT__METHODS } from '@constants/sales.constants';

import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import './StatusPayment.container.scss';

import { Layout } from '../Layout/layout';

const StatusPaymentContainer = ({ paymentError, loading, type, paymentSuccess }) => {
  const dispatch = useDispatch();
  const isRedirectedFromPSE = useQueryParam(QueryParams.REDIRECTED_FROM) === 'PSE';
  const pseToken = useQueryParam(QueryParams.PSE_TOKEN);

  useEffect(() => {
    if (isRedirectedFromPSE && pseToken) {
      dispatch(ShoppingCartCreators.getStatusPayWithPse(pseToken));
      window.history.replaceState(null, null, '/confirmacion-pago');
    }
  }, [dispatch, isRedirectedFromPSE, pseToken]);

  const transactionState =
    paymentSuccess?.data?.transactionResponse?.state ||
    paymentSuccess?.data?.state ||
    paymentError?.data?.transactionResponse?.state ||
    paymentError?.status;

  const getTransactionStatus = () => {
    if (transactionState === 'APPROVED') {
      return (
        <TransactionStatusScreen
          backToHome={() => {
            dispatch(ShoppingCartCreators.resetShoppingCartSalePayment());
            history.replace('/');
          }}
          screenType="SUCCESS"
          paymentInfo={paymentSuccess?.sfId}
        />
      );
    }

    if (transactionState === 'PENDING') {
      return (
        <TransactionStatusScreen
          backToHome={() => {
            dispatch(ShoppingCartCreators.resetShoppingCartSalePayment());
            history.replace('/checkout');
          }}
          screenType="PENDING"
        />
      );
    }

    if (['DECLINED', 'SUBMITTED', 'EXPIRED', 'FAILURE'].includes(transactionState)) {
      return (
        <TransactionStatusScreen
          message={paymentSuccess?.message || paymentError?.message}
          backToCheckout={() => {
            dispatch(ShoppingCartCreators.resetShoppingCartSalePayment());
            history.replace('/checkout');
          }}
          screenType="FAILURE"
        />
      );
    }
    return (
      <TransactionStatusScreen
        message={paymentSuccess?.message || paymentError?.message}
        backToCheckout={() => {
          dispatch(ShoppingCartCreators.resetShoppingCartSalePayment());
          history.replace('/checkout');
        }}
        screenType="FAILURE"
      />
    );
  };

  return (
    <Layout>
      <Loader isLoading={loading} />
      <Grid container className="StatusPayment-container" xs={12} justifyContent="center">
        {paymentSuccess?.status === 'SUCCESS' && paymentSuccess?.type === PAYMENT__METHODS.CASH && (
          <TransactionStatusScreen
            paymentInfo={paymentSuccess?.sfId}
            reference={paymentSuccess?.data?.transactionResponse?.extraParameters?.REFERENCE}
            expirationDate={
              paymentSuccess?.data?.transactionResponse?.extraParameters?.EXPIRATION_DATE
            }
            downloadBill={() => {
              const url =
                paymentSuccess?.data?.transactionResponse?.extraParameters?.URL_PAYMENT_RECEIPT_PDF;
              if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.download = url.split('/').pop();
                link.click();
              }
            }}
            backToHome={() => {
              dispatch(ShoppingCartCreators.resetShoppingCartSalePayment());
              history.replace('/checkout');
            }}
            screenType="CASH"
          />
        )}

        {transactionState &&
          paymentSuccess?.type !== PAYMENT__METHODS.CASH &&
          getTransactionStatus()}
      </Grid>
    </Layout>
  );
};

const mapStateToProps = ({
  sales: { paymentError, paymentSuccess, shoppingCart, isPaymentLoading: loading, type, message },
}) => {
  return {
    paymentError,
    shoppingCart,
    paymentSuccess,
    loading,
    type,
    message,
  };
};

const StatusPayment = connect(mapStateToProps)(withRouter(StatusPaymentContainer));
// eslint-disable-next-line import/no-default-export
export default StatusPayment;
