import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import cartIconAsset from '@assets/svg/cart_icon.svg';

import './style.scss';

const CartIconSvg = ({ backgroundColor }) => {
  return (
    <SVG id="CartButtonCartIconSvg" className={`svg ${backgroundColor}`} src={cartIconAsset} />
  );
};

const CartButton = ({ toggleSidecart, quantity, isCartButtonEnabled, id }) => (
  <div
    id={id}
    onClick={() => {
      toggleSidecart();
    }}
    className={`header-cart-button ${
      quantity > 0 && isCartButtonEnabled
        ? 'header-cart-button-background-green'
        : 'header-cart-button-background-gray'
    }`}
    color="secondary"
  >
    <CartIconSvg
      backgroundColor={`${quantity > 0 && isCartButtonEnabled ? 'svg-white' : 'svg-primary-color'}`}
    />
    {isCartButtonEnabled ? quantity : 0}
  </div>
);
CartButton.propTypes = {
  toggleSidecart: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  isCartButtonEnabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

CartButton.defaultProps = {
  quantity: 0,
  isCartButtonEnabled: false,
};

export { CartButton };
