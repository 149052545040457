import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { history } from '@redux/store';
import { TitleSections } from '@components/index';
import { stringNormalize } from '@utils/format/stringNormalize.utils';

import './style.scss';

const CategoryCarouselBase = ({ categories, sections, user }) => {
  const cardsExplore =
    categories &&
    categories?.length > 0 &&
    categories?.map((item, index) => {
      const bannerGeneral =
        item.image.length > 0 && item.image.find((image) => image.type === 'General')
          ? item.image.find((image) => image.type === 'General').url
          : '';
      return (
        <Grid
          id={`ContainerCategoryCard-${stringNormalize(item.name)}`}
          item
          key={index}
          className="card__category"
          onClick={() => {
            history.push(`/categoria/${item.id}`);
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={4} xs={12} className="tex-center">
              {bannerGeneral && (
                <img
                  id={`ContainerCategoryBannerImg-${stringNormalize(item.name)}`}
                  src={bannerGeneral}
                  alt={item.name}
                />
              )}
            </Grid>
            <Grid item md={8} xs={12} className="tex-center">
              <Typography id={`ContainerCategoryTitle-${stringNormalize(item.name)}`} variant="h5">
                {item.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    });

  return (
    <Grid item className="carousel-explorer-category mb-4">
      {(categories.length > 0 || sections.length > 0) && (
        <TitleSections
          id="ContainerCategoryTitleSectionsCategories"
          title="Explora nuestras categorías"
          show={false}
        />
      )}
      <Grid container className="mt-1 container-categorygrid">
        {categories.length > 0 && cardsExplore}
      </Grid>
    </Grid>
  );
};

CategoryCarouselBase.defaultProps = {
  user: [],
  sections: [],
  categories: [],
};

CategoryCarouselBase.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
};

export const CategoryCarousel = withRouter(CategoryCarouselBase);
