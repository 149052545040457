/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  productDetails: {},
  isOpenProductDetailsModal: false,
});
/* ------------- Reducers ------------- */

export const openProductDetailsModal = (state = INITIAL_STATE, { item }) => {
  return {
    ...state,
    isOpenProductDetailsModal: true,
    productDetails: item,
  };
};
export const closeProductDetailsModal = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...INITIAL_STATE,
  };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_PRODUCT_DETAILS_MODAL]: openProductDetailsModal,
  [Types.CLOSE_PRODUCT_DETAILS_MODAL]: closeProductDetailsModal,
});
