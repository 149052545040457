import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Correo electrónico invalido').required('Correo electrónico requerido'),
  password: Yup.string().required('Contraseña requerida'),
});
export const formikConf = {
  validateOnChange: false,
  validateOnBlur: true,
  initialValues: {
    password: '',
    email: '',
  },
  validationSchema,
};
export const onsubmit = (handleSubmit) => (values, formikProps) => {
  formikProps.setSubmitting(false);
  handleSubmit(values);
};
