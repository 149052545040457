import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '@superfuds/saori';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Avatar } from '@mui/material';
import { Creators as AuthCreators } from '@reducers/Auth';
import { ReactComponent as LeftArrow } from '@assets/svg/prev_arrow_icon.svg';
import { TOKEN_KEY } from '@constants/index';

import './style.scss';

const UserInfo = ({ onClose, user, setSelectUserTypeOpen, setAuthModal }) => {
  const [dropdown, setDropdown] = useState(false);
  const token = localStorage.getItem(TOKEN_KEY);
  const history = useHistory();
  const dispatch = useDispatch();
  const userlogOut = () => {
    setSelectUserTypeOpen(true);
    dispatch(AuthCreators.logoutRequest());
  };
  const getColorFromString = (string) => {
    if (!string) return '';
    let hash = 0;

    for (let i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value?.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name) => {
    if (!name) return '';
    return {
      sx: {
        bgcolor: getColorFromString(name),
      },
      children: `${name?.split(' ')[0]?.[0] ?? ''}${name?.split(' ')[1]?.[0] ?? ''}`,
    };
  };
  return (
    <div className="userInfoContainer">
      <Card className="cardContainer">
        <div className="userInfoContainer__menuContent">
          {!token ? (
            <div className="userInfoContainer__menuContent__login">
              <Button
                className="userInfoContainer__menuContent__startSessionButton"
                type="primary"
                onClick={() => {
                  setAuthModal((c) => !c);
                  onClose();
                }}
              >
                Comienza a comprar
              </Button>
              <Button
                className="userInfoContainer__menuContent__startSessionButton mt-3 mb-1"
                type="primary"
                onClick={() => history.push('/proveedor')}
              >
                Comienza a <br />
                vender
              </Button>
            </div>
          ) : (
            <div
              className="userInfoContainer__menuContent__dropdownWrapper"
              tabIndex={0}
              role="button"
              onClick={() => setDropdown(!dropdown)}
            >
              <div
                className="userInfoContainer__menuContent__dropdownInfo"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Avatar
                  {...stringAvatar(user?.name)}
                  style={{
                    width: '35%',
                  }}
                />
                <Typography variant="body1" className="userInfoContainer__menuContent__userName">
                  {user?.name?.split(' ')?.[0] ?? ''}
                </Typography>
                <LeftArrow
                  className="userInfoContainer__menuContent__arrowIcon"
                  style={dropdown ? { transform: 'rotate(180deg)' } : {}}
                  viewBox="-20 -50 80 80"
                  width="30px"
                  height="30px"
                />
              </div>
              {dropdown && (
                <div className="userInfoContainer__menuContent__dropdownMenu">
                  {/*   <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      onClose();
                      history.push('/perfil');
                    }}
                  >
                    <p className="userInfoContainer__menuContent__myAccountTitle">Mi cuenta</p>
                  </div> */}
                  {/*   <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      onClose();
                      history.push('/pedidos');
                    }}
                  >
                    <p className="userInfoContainer__menuContent__myOrdersTitle">Mis pedidos</p>
                  </div> */}
                  <Button
                    className="userInfoContainer__menuContent__closeSessionButton"
                    onClick={() => {
                      userlogOut();
                      window.location.replace(
                        `${window.location.origin}/${window.location.search}`,
                      );
                    }}
                  >
                    Cerrar sesión
                  </Button>
                </div>
              )}
              {!token && (
                <div
                  id="UserInfoButtonLogin"
                  className="button-login"
                  onClick={() => history.push('/proveedor')}
                >
                  <div className="right-column cursor-pointer">
                    <Typography className="name-text" variant="subtitle1">
                      Comienza a <br />
                      Vender
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
UserInfo.defaultProps = {
  setSelectUserTypeOpen: () => { },
};

UserInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  setSelectUserTypeOpen: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.string,
    razonSocial: PropTypes.string,
  }).isRequired,
  setAuthModal: PropTypes.func.isRequired,
};

export { UserInfo };
