import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like loginRequest -> LOGIN_REQUEST
 */
export const { Types, Creators } = createActions({
  loginRequest: ['phone', 'shippingTypeOtp'],
  logoutRequest: null,
  signupRequest: ['userInfo'],
  signUpRequestSuccess: null,
  authFailure: ['error'],
  authSuccess: ['statusCode'],
  validateOtpRequest: ['otp'],
  validateOtpSuccess: ['token', 'statusCode'],
  validateOtpFailure: ['error'],
  validateEmailRequest: ['email'],
  validateEmailSuccess: ['statusCode'],
  emailLoginRequest: ['email'],
  emailLoginSuccess: ['token'],
  emailLoginFailure: null,
  authRetry: null,
  reset: null,
  logoutUserAccount: null,
  openBToCModal: null,
  closeBToCModal: null,
  openBToBModal: null,
  closeBToBModal: null,
  openPasswordRecovery: null,
  closePasswordRecovery: null,
  forgotPasswordRequest: ['email'],
  forgotPasswordSuccess: null,
  updatePassword: ['password'],
  updatePasswordSuccess: ['data'],
  updatePasswordFailure: ['error'],
  updatePasswordReset: null,
  resetOtpSentRequest: null,
  activeScrollBottom: null,
  resetScrollBottom: null,
  signUpBransRequest: ['payload'],
  signUpBransSuccess: null,
  signUpBransFailure: ['error'],
  validateResetOtp: null,
});
