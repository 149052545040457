export const COLD_AND_FROZEN_TYPE = {
  COLD: 'Refrigerado',
  FROZEN: 'Congelado',
};

export const AGE_RESTRICTED_CATEGORIES = [];
export const shippingTypeOtp = {
  phone: 'sms',
  whatsapp: 'whatsapp',
};

export const CLOTHES_CATEGORY = [277, 279, 381, 382];
