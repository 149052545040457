import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import { currencyFormatter } from '@utils/render';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { history } from '@redux/store';
import { MessagesInventory } from '@constants/index';
import { ProductCounter, ResumeOrder } from '@components/index';
import { ReactComponent as CalendarIcon } from '@assets/svg/CalendarTime.icon.svg';
import { ReactComponent as DeliveryIcon } from '@assets/svg/Delivery.icon.svg';
import { ReactComponent as EmptyCartIcon } from '@assets/svg/empty-cart.svg';
import { ReactComponent as NormalShippingIcon } from '@assets/svg/normalShipping.svg';
import { ReactComponent as DropShippingIcon } from '@assets/svg/dropshipping.svg';

import './styles.scss';

import { Layout } from '../Layout/layout';

const updateIsLoading = (items, isLoading) =>
  items?.length > 0
    ? items?.map((item) => ({
      ...item,
      isLoading,
    }))
    : [];

const CartContainer = ({ user, shoppingCart }) => {
  const dispatch = useDispatch();
  const [Qty, setQty] = useState(0);
  const [newItems, setNewItems] = useState({
    cold: [],
    standart: [],
  });

  const updateNewItems = (shoppingCart, setNewItems) => {
    if (!shoppingCart.isLoading) {
      setNewItems({
        cold: updateIsLoading(shoppingCart.items?.cold?.items, shoppingCart?.isLoading),
        standart: updateIsLoading(shoppingCart.items?.standart?.items, shoppingCart?.isLoading),
      });
    }
  };

  useEffect(() => {
    updateNewItems(shoppingCart, setNewItems);
  }, [shoppingCart]);

  useEffect(() => {
    dispatch(ShoppingCartCreators.getTokenZiro());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [...newItems.standart, ...newItems.cold];

  const onDetailsProducts = (id) => {
    history.push(`/p/${id}`);
  };

  const handResetCart = () => {
    const NewItems = items?.map((item) => ({
      ...item,
      quantityInPackages: 0,
      quantity: 0,
    }));
    dispatch(ShoppingCartCreators.startShoppingCartSale());
    dispatch(ShoppingCartCreators.upsertShoppingCart(NewItems));
  };

  const updateItemsShoppingCart = (item) =>
    dispatch(ShoppingCartCreators.upsertShoppingCart([item]));

  const deleteProductOrCartByType = (product) =>
    dispatch(
      ShoppingCartCreators.upsertShoppingCart([
        {
          itemId: product.itemId,
          quantityInPackages: 0,
          isExpress: product.isExpress,
          routeId: product?.routeId,
        },
      ]),
    );

  const handleClick = (id, data, routeType) => {
    const updatedData = data.map((item) =>
      item.itemId === id ? { ...item, isLoading: !item.isLoading } : item,
    );

    const stateKey = routeType === 'standard' ? 'standart' : 'cold';
    setNewItems((prevState) => ({
      ...prevState,
      [stateKey]: updatedData,
    }));
  };
  const itemsCart = (data) => {
    return (
      data.length > 0 && (
        <Grid>
          {data?.map((item) => {
            return (
              <Grid container key={item.itemId} item xs={12} className="item">
                <Grid
                  item
                  sm={2}
                  xs={12}
                  onClick={() => onDetailsProducts(item.itemId)}
                  className="itemImg"
                >
                  {item.discountInPercentage > 0 && (
                    <span className="discountValue"> {item.discountInPercentage}% off</span>
                  )}
                  {item.thumbnail && (
                    <img src={item.thumbnail} alt={item.title_ec} role="presentation" />
                  )}
                </Grid>
                <Grid
                  item
                  sm={7}
                  xs={12}
                  className="description"
                  onClick={() => onDetailsProducts(item.itemId)}
                >
                  <Typography variant="h5">
                    {item.itemTitle}
                    <br />
                    <Typography variant="caption" color="primary">
                      Sku {item.sku}
                    </Typography>
                  </Typography>
                  <Typography variant="h6" className="title_brands">
                    {item.brandName}
                    <Typography variant="h6" className="netContent">
                      Embalaje de {item.unitsPerPackage} Unidades por{' '}
                      <strong>{item.netContent}</strong>
                    </Typography>
                    <Typography variant="h6" className="netContent">
                      Stock disponible <strong>{item.totalStock}</strong> Unidades
                    </Typography>
                  </Typography>
                  {item.isLoading ? (
                    <div className="aa-LoadingIndicator">
                      <svg className="aa-LoadingIcon" viewBox="0 0 100 100" width="20" height="20">
                        <circle
                          cx="50"
                          cy="50"
                          fill="none"
                          r="35"
                          stroke="currentColor"
                          strokeDasharray="164.93361431346415 56.97787143782138"
                          strokeWidth="6"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="1s"
                            values="0 50 50;90 50 50;180 50 50;360 50 50"
                            keyTimes="0;0.40;0.65;1"
                          />
                        </circle>
                      </svg>
                    </div>
                  ) : (
                    <>
                      {item.discountInPercentage === 0 ? (
                        <Typography variant="h6" className="price">
                          {currencyFormatter(item.grandTotal)}
                        </Typography>
                      ) : (
                        <>
                          <p className="discountPrice">{currencyFormatter(item.total)}</p>
                          <span className="boldDiscount">{currencyFormatter(item.grandTotal)}</span>
                        </>
                      )}
                    </>
                  )}

                  {item?.distribuitor?.distribuitor && (
                    <Typography variant="h6" className="delivery">
                      <DeliveryIcon /> <span>Enviado por </span>
                      {item?.distribuitor?.distribuitor}
                    </Typography>
                  )}
                  {item?.distribuitor?.distribuitor &&
                    item?.distribuitor?.estimated_delivery_time !== false && (
                      <Typography variant="h6" className="delivery_time">
                        <CalendarIcon /> <span>Tiempo de </span>
                        {item?.distribuitor?.estimated_delivery_time?.toLowerCase()}
                      </Typography>
                    )}
                </Grid>
                <Grid item sm={3} xs={12} className="buttons">
                  <ProductCounter
                    source="cart"
                    totalStock={item.totalStock}
                    defaultQuantity={item.quantityInPackages}
                    activeAlert={() => {
                      dispatch(
                        ShoppingCartCreators.openSnackbar(
                          `Superó el stock de ${item.totalStock}. Introduce una cantidad válida, por favor.`,
                        ),
                      );
                    }}
                    onChange={(dif) => {
                      handleClick(item?.itemId, data, item?.routeType);
                      if (item.totalStock === dif && dif !== 0) {
                        setQty(dif);
                        dispatch(ShoppingCartCreators.openSnackbar(MessagesInventory));
                      } else if (item.totalStock >= dif) {
                        updateItemsShoppingCart({
                          ...item,
                          quantityInPackages: dif,
                          quantity: dif,
                        });
                      } else {
                        deleteProductOrCartByType(item);
                      }
                    }}
                    disabled={item.totalStock === Qty}
                    className="productDetail__counter"
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )
    );
  };
  return (
    <Layout>
      <Grid container item xs={12} id="cart-page" className="cart-page" justifyContent="center">
        {/*  {items.length > 0 && ( */}
        {/*    {items.length !== 0 && (
          <Alert
            icon={false}
            severity="success"
            sx={{
              margin: '40px 0',
              fontSize: '1rem',
            }}
          >
            <Typography variant="h6" className="text-center">
              ¡Tenemos una excelente noticia para ti!
            </Typography>
            Al realizar tus compras a través de nuestro Ecommerce, recibirás un descuento del 3%
            sobre el total de tu compra.
            <br />
            ¡Aprovecha esta increíble oferta y disfruta de tus compras con un beneficio adicional!
          </Alert>
        )} */}
        {items.length !== 0 && (
          <Grid container item xs={12} spacing={2} className="containers-cart">
            <Grid item xs={12} sm={8} className="containers-items">
              <Grid
                item
                xs={3}
                className="text-right"
                style={{
                  marginTop: 1,
                  marginBottom: 1,
                  marginLeft: 'auto',
                }}
              >
                <Button
                  disabled={shoppingCart.isLoading}
                  size="small"
                  variant="text"
                  onClick={handResetCart}
                  style={{ position: 'relative' }}
                >
                  Vaciar Carrito
                  <DeleteIcon color="white" />
                </Button>
              </Grid>
              {newItems?.standart?.length > 0 && (
                <Grid>
                  <Typography variant="h5" className="title-Cart">
                    <NormalShippingIcon width="60" />
                    <span className="title">Enviado por Superfuds</span>
                    <span className="totalItems" style={{ alignSelf: 'center' }}>
                      {newItems?.standart?.length}
                    </span>
                  </Typography>
                  <Grid className="mt-4">{itemsCart(newItems?.standart)}</Grid>
                </Grid>
              )}

              {newItems?.cold?.length > 0 && (
                <Grid className="mt-5">
                  <Typography variant="h5" className="title-Cart">
                    <DropShippingIcon width="60" />
                    <span className="title">
                      Enviado por la marca
                      <Typography variant="body2" className="text-left">
                        Costo de Envío gratis
                      </Typography>
                    </span>
                    <span className="totalItems" style={{ alignSelf: 'baseline' }}>
                      {newItems?.cold?.length}
                    </span>
                  </Typography>
                  <Grid className="mt-4"> {itemsCart(newItems?.cold)} </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm={4} xs={12} className="container-resume-cart">
              <ResumeOrder shoppingCart={shoppingCart} user={user} show />
            </Grid>
          </Grid>
        )}
        {items.length === 0 && (
          <Grid
            container
            item
            xs={12}
            className="containers-cart mt-4 text-center"
            justifyContent="center"
          >
            <EmptyCartIcon />
            <Typography
              style={{
                width: '100%',
                paddingTop: 20,
              }}
            >
              No tienes productos en el carrito, aún...
            </Typography>
          </Grid>
        )}
        <Grid className="text-left pt-3 pb-3 buttonContinue" item md={12} xs={12}>
          <Button className="button-link" onClick={() => history.push('/')}>
            <ArrowRightAlt />
            Continuar Comprando
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
};

CartContainer.propTypes = {
  user: PropTypes.shape({}),
  shoppingCart: PropTypes.shape({}).isRequired,
};

CartContainer.defaultProps = {
  user: {},
};

const mapStateToProps = ({ user, sales: { shoppingCart } }) => {
  return {
    user,
    shoppingCart,
  };
};

const Cart = connect(mapStateToProps)(CartContainer);

export default Cart;
