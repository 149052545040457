export const BannersService = {
  async getBanners() {
    try {
      const response = await fetch(`https://api-banners.vercel.app/api/banners`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
};
