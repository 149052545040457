import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { legalDocs } from '@constants/index';

import './style.scss';

const CustomerService = ({ getI18nText, handleClick }) => {
  const history = useHistory();
  return (
    <div className="footer-client-service">
      <Typography variant="subtitle1" className="subtitle pb-1 title">
        {getI18nText('footer.service.title')}
      </Typography>
      <Typography
        variant="body2"
        className="contentText text-margin link"
        onClick={() => handleClick(legalDocs.PD)}
      >
        {getI18nText('footer.service.link.option_3')}
      </Typography>
      <Typography
        variant="body2"
        className="contentText text-margin link"
        onClick={() => history.push('/terminos-y-condiciones')}
      >
        {getI18nText('footer.service.link.option_1')}
      </Typography>
      <Typography
        variant="body2"
        className="contentText text-margin link"
        onClick={() => handleClick(legalDocs.PP)}
      >
        {getI18nText('footer.service.link.option_4')}
      </Typography>
      {/*    <Typography
        variant="body2"
        className="contentText text-margin link"
        onClick={() => history.push('/politicas-de-devolucion')}
      >
        {getI18nText('footer.service.link.option_5')}
      </Typography> */}
      <Typography variant="body2" className="contentText text-margin link ">
        <a
          href="https://www.sic.gov.co/"
          target="_blank"
          className="contentText"
          rel="noopener nofollow noreferrer"
        >
          {getI18nText('footer.service.link.option_6')}
        </a>
      </Typography>
      <Typography
        variant="body2"
        className="contentText text-margin link"
        onClick={() => handleClick(legalDocs.DR)}
      >
        {getI18nText('footer.service.link.option_7')}
      </Typography>
      <Typography
        variant="body2"
        className="contentText text-margin link"
        onClick={() => handleClick(legalDocs.SR)}
      >
        {getI18nText('footer.service.link.option_8')}
      </Typography>
    </div>
  );
};

CustomerService.defaultProps = {
  getI18nText: () => {},
};

CustomerService.propTypes = {
  getI18nText: PropTypes.func,
  handleClick: PropTypes.func.isRequired,
};

export { CustomerService };
