import React from 'react';
import ContentLoader from 'react-content-loader';

const BrandsSkeletonLoader = () => (
  <ContentLoader
    width={170}
    height={170}
    viewBox="0 0 400 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
    <rect x="262" y="10" rx="0" ry="0" width="1" height="0" />
  </ContentLoader>
);

export { BrandsSkeletonLoader };
