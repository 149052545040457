import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like loginRequest -> LOGIN_REQUEST
 */
export const { Types, Creators } = createActions({
  startup: null,
  setStartupComplete: null,
});
