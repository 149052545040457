export const getShoppingCartdiscountInMoney = (state) => state.sales.shoppingCart.discountInMoney;
export const getShoppingCart = (state) => state.sales.shoppingCart;
export const getShoppingCartId = (state) => state.sales.shoppingCart.id;
export const getShoppingCartItems = (state) => state.sales.shoppingCart.items;
export const getShoppingCartBillingData = (state) => state.sales.billingData;
export const getHasExpressItems = (state) => state.sales.shoppingCart.hasExpressItems;
export const getHasNormalItems = (state) => state.sales.shoppingCart.hasNormalItems;
export const getExpressCart = (state) => state.sales.shoppingCart.expressCartSelected;
export const getDeliveryExpressCart = (state) => state.sales.shoppingCart.deliveryExpressCart;
export const getHasTicketNumberCart = (state) => state.sales.shoppingCart?.ticketNumber || false;
export const getIsPaymentLoading = (state) => state.sales.isPaymentLoading;
export const getPositiveBallanceState = (state) => state.sales.shoppingCart?.usePositiveBallance;
export const getAllShoppingCartItemsList = (state) => state.sales.shoppingCart.items; 