/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  shoppingCart: {
    isLoading: false,
    id: null,
    quantityInSkus: 0,
    toggleAddProductSnackBar: false,
    items: [],
    totalWithoutTaxes: 0,
    taxes: 0,
    serviceCost: 0,
    discount: null,
    grandTotal: 0,
    coupon: {
      id: null,
    },
    couponInfo: {
      status: null,
      message: '',
      userInput: '',
    },
    rappiPayCartInfo: {
      id: null,
      status: null,
      error: null,
      isLoading: false,
    },
    couponList: [],
    errorCoupon: '',
    isShoppingCartOpen: false,
  },
  billingData: {},
  isPaymentLoading: false,
  paymentError: {},
  paymentSuccess: {},
  listBank: [],
  listCard: [],
  payCreditZiro: {
    isLoading: false,
    validateCode: false,
  },
});

/* ------------- Reducers ------------- */

const toggleShoppingCart = (state = INITIAL_STATE) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      isShoppingCartOpen: !state.shoppingCart.isShoppingCartOpen,
    },
  };
};

const toggleCouponerRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      isOpenCouponer: !state.shoppingCart.isOpenCouponer,
    },
  };
};
const shoppingCartFetchSucceeded = (state, action) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      ...action.shoppingCart,
      quantityInSkus: action.shoppingCart.items.length,
      isLoading: false,
    },
  };
};

export const upesertShoppingCartRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      isLoading: true,
    },
    isPaymentLoading: false,
  };
};

const shoppingCartFetchFailed = (state, action) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      errorCart: action.error,
      isLoading: false,
    },
    payCreditZiro: {
      ...state.payCreditZiro,
      isLoading: false,
      validateCode: false,
    },
    isPaymentLoading: false,
  };
};
const startShoppingCartSale = (state) => {
  return {
    ...state,
    isPaymentLoading: true,
    payCreditZiro: {
      ...state.payCreditZiro,
      isLoading: true,
      validateCode: false,
    },
  };
};

const stopShoppingCartSale = (state) => {
  return {
    ...state,
    isPaymentLoading: false,
  };
};

const shoppingCartSaleSucceeded = (state, { payload }) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
    },
    isPaymentLoading: false,
    paymentSuccess: payload,
  };
};

const shoppingCartSaleFailed = (state, { error }) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      ticketNumber: null,
    },
    paymentError: error,
    isPaymentLoading: false,
  };
};

export const updateBillingData = (state, { billingData }) => {
  return {
    ...state,
    billingData,
  };
};
export const resetShoppingCartRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      isLoading: true,
    },
  };
};

const reset = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...INITIAL_STATE,
    shoppingCart: {
      ...INITIAL_STATE.shoppingCart,
      isLoading: false,
    },
    isPaymentLoading: false,
  };
};

const getCouponsSucceeded = (state, action) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      couponList: action.couponsList,
    },
  };
};

const getCouponsFailed = (state, action) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      couponInfo: action.couponInfo,
    },
  };
};

const addCoupon = (state) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      couponInfo: {},
    },
  };
};

const addCouponSucceded = (state, action) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      couponInfo: action.couponInfo,
      couponList: action.couponInfo.couponsList,
    },
  };
};

const addCouponFailed = (state, action) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      couponInfo: action.couponInfo,
    },
  };
};

const cleanCouponError = (state) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      couponInfo: {
        status: null,
        message: '',
        userInput: '',
      },
    },
  };
};

const openAddProductSnackbar = (state) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      toggleAddProductSnackBar: true,
    },
  };
};

const closeAddProductSnackbar = (state) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      toggleAddProductSnackBar: false,
    },
  };
};
const openSnackbar = (state, { message }) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      snackbarIsOpen: true,
      snackbarMessage: message,
    },
  };
};

const closeSnackbar = (state) => {
  return {
    ...state,
    shoppingCart: {
      ...state.shoppingCart,
      snackbarIsOpen: false,
      snackbarMessage: '',
    },
  };
};
const resetPayment = (state) => ({
  ...state,
  paymentError: {},
  paymentSuccess: {},
  payCreditZiro: {},
});

const PayCreditSucceeded = (state, { payload }) => {
  return {
    ...state,
    ...payload,
    paymentError: {},
  };
};
const listBanckSucceeded = (state, { payload }) => {
  return {
    ...state,
    listBank: payload,
  };
};

const listCardSucceded = (state, { payload }) => ({
  ...state,
  listCard: payload,
});

const listCardFailed = (state, { error }) => ({
  ...state,
  listCard: [],
});

const requestValidateCreditZiro = (state) => ({
  ...state,
  payCreditZiro: {
    isLoading: true,
    validateCode: false,
  },
});

const validateCreditZiroSucceeded = (state, { data }) => ({
  ...state,
  payCreditZiro: {
    isLoading: false,
    ...data,
    validateCode: !!data.validateCode,
  },
});

const validateCreditZiroFailed = (state, { error }) => ({
  ...state,

  shoppingCart: {
    ...state.shoppingCart,
    isLoading: false,
  },
  isPaymentLoading: false,
  payCreditZiro: {
    isLoading: false,
    validateCode: !!error.validateCode,
    ...error,
  },
});
const resetCreditZiro = (state) => ({
  ...state,
  payCreditZiro: {},
});

const requestValidateCodeCreditZiro = (state) => ({
  ...state,
  payCreditZiro: {
    ...state.payCreditZiro,
    isLoading: true,
    validateCode: false,
  },
});
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_SHOPPING_CART]: toggleShoppingCart,
  [Types.SHOPPING_CART_FETCH_SUCCEEDED]: shoppingCartFetchSucceeded,
  [Types.UPSERT_SHOPPING_CART]: upesertShoppingCartRequest,
  [Types.SHOPPING_CART_FETCH_FAILED]: shoppingCartFetchFailed,
  [Types.START_SHOPPING_CART_SALE]: startShoppingCartSale,
  [Types.STOP_SHOPPING_CART_SALE]: stopShoppingCartSale,
  [Types.SHOPPING_CART_SALE_SUCCEEDED]: shoppingCartSaleSucceeded,
  [Types.SHOPPING_CART_SALE_FAILED]: shoppingCartSaleFailed,
  [Types.RESET_SHOPPING_CART]: reset,
  [Types.UPDATE_BILLING_DATA]: updateBillingData,
  [Types.RESET]: reset,
  [Types.GET_COUPONS_SUCCEEDED]: getCouponsSucceeded,
  [Types.GET_COUPONS_FAILED]: getCouponsFailed,
  [Types.ADD_COUPON]: addCoupon,
  [Types.ADD_COUPON_FAILED]: addCouponFailed,
  [Types.ADD_COUPON_SUCCEDED]: addCouponSucceded,
  [Types.CLEAN_COUPON_ERROR]: cleanCouponError,
  [Types.TOGGLE_COUPONER_REQUEST]: toggleCouponerRequest,
  [Types.OPEN_ADD_PRODUCT_SNACKBAR]: openAddProductSnackbar,
  [Types.CLOSE_ADD_PRODUCT_SNACKBAR]: closeAddProductSnackbar,
  [Types.OPEN_SNACKBAR]: openSnackbar,
  [Types.CLOSE_SNACKBAR]: closeSnackbar,
  [Types.RESET_SHOPPING_CART_SALE_PAYMENT]: resetPayment,
  [Types.SHOPPING_CART_SALE_PAY_CREDIT_SUCCEEDED]: PayCreditSucceeded,
  [Types.LIST_BANCK_SUCCEEDED]: listBanckSucceeded,
  [Types.GET_LIST_CARD_SUCCEEDED]: listCardSucceded,
  [Types.GET_LIST_CARD_FAILED]: listCardFailed,
  [Types.VALIDATE_CREDIT_ZIRO]: requestValidateCreditZiro,
  [Types.VALIDATE_CREDIT_ZIRO_SUCCEEDED]: validateCreditZiroSucceeded,
  [Types.VALIDATE_CREDIT_ZIRO_FAILED]: validateCreditZiroFailed,
  [Types.RESET_CREDIT_ZIRO]: resetCreditZiro,
  [Types.VALIDATE_CODE_CREDIT_ZIRO]: requestValidateCodeCreditZiro,
});
