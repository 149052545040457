/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  items: [],
  isLoading: true,
  currentPage: null,
  hasMore: false,
});
/* ------------- Reducers ------------- */
export const getProductsRequest = (state = INITIAL_STATE, { clean }) => {
  return {
    ...state,
    items: clean ? [] : state.items,
    currentPage: clean ? null : state.currentPage,
    isLoading: true,
    hasMore: false,
  };
};
export const getProductsSuccess = (state = INITIAL_STATE, { items }) => {
  return {
    ...state,
    items: [...state.items, ...items.data],
    currentPage: items.currentPage,
    hasMore: items.hasMore,
    isLoading: false,
  };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCTS_BY_CATEGORY_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_BY_BRAND_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_BY_DIETS_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_BY_HINT_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
});
