import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@superfuds/sf-app';
import SVG from 'react-inlinesvg';
import {
  Grow,
  Paper,
  Popper,
  MenuItem,
  Typography,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core';
import { EMAIL_REGISTER, TOKEN_KEY } from '@constants/index';
import user from '@assets/svg/user_icon.svg';

import './style.scss';

const UserIcon = () => <SVG id="UserInfoUserIcon" className="svg svg-white" src={user} />;

const UserInfo = ({
  token,
  name,
  clientType,
  OptionsAccount,
  titleButton,
  OptionsLogin,
  setAuthModal,
  user,
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const completeProfile = name.length > 0;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (option) => {
    setOpen(false);
    if (option.onClick) {
      option.onClick();
    }
  };

  const showOption = (item) => {
    const optionText = i18n.getText('header.menu-list-Account.option_4');
    if (
      (item.option === optionText && localStorage.getItem(EMAIL_REGISTER)) ||
      (item.option === optionText && user.isEmployee)
    )
      return false;
    if (
      (item.clientType &&
        parseInt(item.clientType, 10) === parseInt(clientType, 10) &&
        (item.isUserComplete === -1 || completeProfile === item.isUserComplete)) ||
      (!item.clientType && item.isUserComplete === -1)
    )
      return true;

    if (completeProfile === item.isUserComplete) return true;

    return false;
  };

  const listItemsAccount = OptionsAccount.filter(showOption).map((item, index) => (
    <MenuItem id={item.id} key={index} onClick={() => handleClose(item)}>
      {item.option}
    </MenuItem>
  ));

  const listItemLogin = OptionsLogin.map((item, i) => (
    <MenuItem id={item.id} key={i} onClick={() => handleClose(item)}>
      {item.option}
    </MenuItem>
  ));
  return (
    <div id="UserInfoContainer" className="user-info">
      {!localStorage.getItem(TOKEN_KEY) && (
        <>
          <div
            id="UserInfoButtonLogin"
            className="button-login"
            ref={anchorRef}
            onClick={setAuthModal}
          >
            <div className="right-column cursor-pointer">
              <Typography className="name-text" variant="subtitle1">
                {titleButton}
              </Typography>
            </div>
          </div>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            className="user-menu"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="menu-list-grow">{listItemLogin}</MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
      {localStorage.getItem(TOKEN_KEY) && (
        <>
          <div className="user-section-avatar" ref={anchorRef} onClick={handleToggle}>
            {/*   <img className="user-picture cursor-pointer" alt="foto de perfil" src={picture} /> */}
            <UserIcon />
            <div className="right-column cursor-pointer">
              <Typography id="UserInfoUserName" className={`name-text `} variant="subtitle1" noWrap>
                {name}
              </Typography>
            </div>
          </div>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            className="user-menu"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {listItemsAccount}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </div>
  );
};
UserInfo.propTypes = {
  isMasquerading: PropTypes.bool,
  titleButton: PropTypes.string,
  myAccountText: PropTypes.string,
  OptionsAccount: PropTypes.arrayOf(PropTypes.object),
  OptionsLogin: PropTypes.arrayOf(PropTypes.object),
  setAuthModal: PropTypes.func.isRequired,
  token: PropTypes.string,
  picture: PropTypes.string,
  name: PropTypes.string,
  clientType: PropTypes.number.isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isEmployee: PropTypes.bool,
  }).isRequired,
};

UserInfo.defaultProps = {
  isMasquerading: false,
  OptionsAccount: [],
  OptionsLogin: [],
  titleButton: '',
  myAccountText: '',
  picture: '',
  name: '',
  token: null,
};
export { UserInfo };
