import { EMAIL_DEMO } from '@constants/env-vars.constants';

export const B2C_USER = 2842;
export const B2B_USER = 4893;
export const CURRENCY = 'COP';
export const PLATFORM = 'ECOMMERCE';

export const CODE_COMERCIO = 65579747;
export const ZIRO_API_USER = '$JxD1vxIi9oUOi8%u1ia';
export const ZIRO_API_SECRET = 'lqJA3a12Z4Ju1*iBc^ss%G^4ugVn9q';

export const ZIRO_API_USER_SANDBOX = 'vYtJnZLFRnHxJSRSKQA4';
export const ZIRO_API_SECRET_SANDBOX = 'Ed3zLpVdmvnBBKbWVF2S6eG687vEPj';
export const CODE_COMERCIO_SANDBOX = 52994995;

export const CLOUDINARY_KEY = 'dzpafdn7s';
export const CLOUDINARY_URL = `https://res.cloudinary.com/${CLOUDINARY_KEY}/image/fetch/f_webp,q_auto/`;

export const UserType = {
  B2B: '1',
  B2C: '2',
};

export const CC_CODE = 13;
export const DocumentTypes = [
  {
    id: '',
    label: '',
  },
  {
    id: 'CC',
    label: 'Cédula de ciudadanía',
  },
  { id: 'CE', label: 'Cédula de extranjería' },
  { id: 'NIT', label: 'NIT' },
  { id: 'TI', label: 'Tarjeta de identidad' },
  { id: 'PP', label: 'Pasaporte' },
  { id: 'DE', label: 'Documento de identificación extranjero' },
];

export const ZIRO_HOST = 'https://api.somosziro.com';
/*   NODE_ENVIRONMENT === 'production'
    ? 'https://api.somosziro.com'
    : 'https://demo2612726.mockable.io'; */

export const ODOO_HOST = 'https://superfuds-odoo.odoo.com';
/*   NODE_ENVIRONMENT === 'production'
    ? 'https://superfuds-odoo.odoo.com'
    : 'https://superfuds-staging.odoo.com'; */

export const ODOO_HOST_DEMO = 'https://superfuds-odoo-demo-8248311.dev.odoo.com';
export const ZIRO_HOST_DEMO = 'https://demo2612726.mockable.io';
export const DEMO_USER = EMAIL_DEMO;
