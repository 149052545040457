import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { CircleLoader, Card } from '@superfuds/saori';
import mixpanel from 'mixpanel-browser';

import { ResumeOrder, ShippingInfo, PaymentMethods, MinimumOrder } from '@components/index';
import { ValidateZiro } from '@components/organisms/PaymentMethods/ValidateZiro';

import { PAYMENT__METHODS } from '@constants/index';
import { PaymentService } from '@services/payment.service';
import { Creators as SalesCreators } from '@reducers/Sales';
import { mapCreditCardBody, mapCreditCardBodySave } from '@utils/payu/payu.parser';

import './styles.scss';

import { Layout } from '../Layout/layout';

export const CheckoutBase = ({
  user,
  shoppingCart,
  delivery,
  isLoading,
  listBank,
  listCard,
  payCreditZiro,
}) => {
  const dispatch = useDispatch();
  const [userAddress, setUserAddress] = useState(null);
  const [open, setOpen] = useState(false);
  const [userCity, setUserCity] = useState(null);
  const [codeZiro, setCodeZiro] = useState('');
  const [userAddressName, setUserAddressName] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [openMinimum, setOpenMinimum] = useState(false);

  useEffect(() => {
    dispatch(SalesCreators.getTokenZiro());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabledPaymentButton =
    (Object.keys(paymentMethod).length === 0 && shoppingCart.grandTotal !== 0) ||
    !userAddressName ||
    !userAddress;

  const handlePaymentMethod = (values) => setPaymentMethod(values);
  const cleanPaymentMethod = () => setPaymentMethod({});

  const handleCASHPayment = () => {
    dispatch(SalesCreators.startShoppingCartSale());
    dispatch(SalesCreators.payWithCashRequest());
  };

  const handlePSEPayment = (values) => {
    dispatch(SalesCreators.startShoppingCartSale());
    mixpanel.track('Purchase', { ...shoppingCart });
    const bodyTransInToken = PaymentService.reqTransferToken(values, shoppingCart);
    dispatch(SalesCreators.payWithPse(bodyTransInToken));
  };

  const handleTCPayment = (values) => {
    dispatch(SalesCreators.startShoppingCartSale());
    mixpanel.track('Purchase', { ...shoppingCart });
    const bodyCard = mapCreditCardBody(values);
    dispatch(SalesCreators.payWithCreditCard(bodyCard));
  };

  const handleTCSave = (values) => {
    dispatch(SalesCreators.startShoppingCartSale());
    mixpanel.track('Purchase', { ...shoppingCart });
    const bodyCard = mapCreditCardBodySave(values);
    dispatch(SalesCreators.payWithCreditCard(bodyCard));
  };

  const handleTCdelete = (id) => {
    dispatch(
      SalesCreators.deleteCard({
        id,
      }),
    );
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (delivery.userLocations.length) {
      const [
        {
          address: userAddressInfo,
          name: addressName,
          city: { name: cityName },
        },
      ] = delivery.userLocations.filter((currentAddress) => currentAddress.isSelected);

      if (userAddressInfo && userAddress?.name !== '') {
        setUserAddress(userAddressInfo);
        setUserCity(cityName);
        setUserAddressName(addressName);
      }
    }
  }, [delivery, userAddress]);

  const handleConfirm = (document) => {
    const payload = {
      numero_identificacion: document,
    };

    if (payCreditZiro && payCreditZiro.numero_obligacion && codeZiro !== '') {
      payload.codigo = parseInt(codeZiro, 10);
      payload.numero_obligacion = parseInt(payCreditZiro.numero_obligacion, 10);
      dispatch(SalesCreators.validateCodeCreditZiro(payload));
    } else {
      payload.valor_credito = parseInt(shoppingCart.grandTotal, 10);
      dispatch(SalesCreators.validateCreditZiro(payload));
    }
  };
  const applayCoupon = (coupon) => dispatch(SalesCreators.addCoupon(coupon));

  const handleResendCode = () => {
    dispatch(
      SalesCreators.resendCodeZiro({
        numero_obligacion: parseInt(payCreditZiro?.numero_obligacion, 10),
        numero_identificacion: parseInt(user?.documentNumber, 10),
      }),
    );
  };

  const handleCloseModal = () => {
    setOpen(!open);
    dispatch(SalesCreators.resetCreditZiro());
  };

  const routerPage = () => {
    switch (paymentMethod?.method) {
      case PAYMENT__METHODS.PSE:
        handlePSEPayment(paymentMethod);
        return true;
      case PAYMENT__METHODS.TC:
        handleTCPayment(paymentMethod);
        return true;
      case PAYMENT__METHODS.CASH:
        handleCASHPayment(paymentMethod);
        return true;
      case PAYMENT__METHODS.TCSUBSCRIPTION:
        handleTCSave(paymentMethod);
        return true;
      case PAYMENT__METHODS.ZIRO:
        setOpen(true);
        return true;
      default:
        return false;
    }
  };
  const handlePayment = () => {
    if (user.isEmployee) {
      routerPage();
    } else if (shoppingCart?.totalWithoutTaxes >= shoppingCart?.minimunAmountOrder) {
      routerPage();
    } else {
      setOpenMinimum(true);
    }
  };

  return (
    <Layout>
      <ValidateZiro
        open={open}
        payCreditZiro={payCreditZiro}
        disabled={codeZiro}
        setCodeZiro={setCodeZiro}
        closeModal={handleCloseModal}
        resendcode={handleResendCode}
        confirm={handleConfirm}
      />

      <MinimumOrder
        open={openMinimum}
        closeModal={() => setOpenMinimum(!openMinimum)}
        minimunAmountOrder={shoppingCart?.minimunAmountOrder}
      />
      <Grid container xs={12} justifyContent="center" classes={{ root: 'checkout' }}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            className="text-left"
            style={{
              paddingBottom: 0,
            }}
          >
            Dirección de entrega
          </Typography>
          <ShippingInfo
            user={user}
            location={{ userCity, userAddress, userAddressName }}
            id="CheckoutShippingInfo"
          />
        </Grid>
        <Grid container xs={12} justifyContent="center" className=" mt-3">
          <Grid item xs={12}>
            <Typography variant="h5" className="text-left">
              Método de pago
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Card className="paymentMethods">
              <addi-widget price={shoppingCart.grandTotal} ally-slug="superfuds-ecommerce" />
              {/*   {shoppingCart.grandTotal > 0 && (
              <Alert
                icon={false}
                severity="success"
                sx={{
                  margin: '20px 0',
                  fontSize: '1rem',
                }}
              >
                <Typography variant="h6" className="text-center">
                  ¡Tenemos una excelente noticia para ti!
                </Typography>
                Al realizar tus compras a través de nuestro Ecommerce, recibirás un descuento del 3%
                sobre el total de tu compra.
                <br />
                ¡Aprovecha esta increíble oferta y disfruta de tus compras con un beneficio
                adicional!
              </Alert>
            )} */}
              <PaymentMethods
                listBank={listBank}
                reexpedicionCarrier={shoppingCart?.reexpedicionCarrier}
                listCard={listCard}
                total={shoppingCart.grandTotal}
                user={user}
                paymentMethod={paymentMethod}
                cleanPaymentMethod={cleanPaymentMethod}
                handlePaymentMethod={handlePaymentMethod}
                handleTCdelete={handleTCdelete}
              />
            </Card>
          </Grid>
          <Grid item sm={4} xs={12}>
            <ResumeOrder shoppingCart={shoppingCart} user={user} applayCoupon={applayCoupon} />
            <Grid item style={{ padding: '0 16px' }}>
              <Button
                id="CheckoutShippingFinalizeOrderButton"
                type="button"
                disabled={isDisabledPaymentButton || isLoading}
                classes={{
                  root:
                    isDisabledPaymentButton || isLoading
                      ? 'checkout__button--disabled'
                      : 'checkout__button',
                }}
                onClick={handlePayment}
              >
                {isLoading ? <CircleLoader width="20" height="20" /> : 'Finalizar compra'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

CheckoutBase.defaultProps = {
  listCard: [],
  payCreditZiro: {},
};

CheckoutBase.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    clientType: PropTypes.number,
  }).isRequired,
  listBank: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  payCreditZiro: PropTypes.arrayOf(PropTypes.shape({})),
  listCard: PropTypes.arrayOf(PropTypes.shape({})),
  shoppingCart: PropTypes.shape({
    ticketNumber: PropTypes.number,
    id: PropTypes.number,
    status: PropTypes.number,
    totalWithoutTaxes: PropTypes.number,
    taxes: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    grandTotal: PropTypes.number,
    discountInMoney: PropTypes.number,
    serviceCost: PropTypes.number,
    sfId: PropTypes.string,
    usePositiveBallance: PropTypes.shape({
      success: PropTypes.string,
      error: PropTypes.string,
      enabled: PropTypes.bool,
    }),
    positiveBalance: PropTypes.number,
    defaultGrandTotal: PropTypes.number,
  }).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  delivery: PropTypes.shape({
    isLocationLoading: PropTypes.bool.isRequired,
    isOpenGoogleMapsModal: PropTypes.bool.isRequired,
    isOpenLocationModal: PropTypes.bool.isRequired,
    userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    anonLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLocationSaved: PropTypes.bool.isRequired,
    filterCity: PropTypes.shape({
      city: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      department: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  sales: { shoppingCart, isPaymentLoading: isLoading, listBank, listCard, payCreditZiro },
  user,
  delivery,
}) => ({
  shoppingCart,
  user,
  delivery,
  isLoading,
  listBank,
  listCard,
  payCreditZiro,
});

const Checkout = connect(mapStateToProps)(CheckoutBase);

export default Checkout;
