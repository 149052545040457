export const setInitialValues = (user, paymentMethod) => {
  return {
    bank: paymentMethod?.bank || '',
    email: paymentMethod?.email || user?.email || '',
    documentNumber:
      paymentMethod?.documentNumber === '12'
        ? ''
        : paymentMethod?.documentNumber || user?.documentNumber || '',
  };
};
