import React from 'react';
import PropTypes from 'prop-types';
import { SubCategoriesSidebarList } from '@components/molecules/SubCategoriesSidebarList/subCategoriesSidebarList.molecule';

const SubCategoriesSidebar = ({
  subcategories,
  subCategoryClickHandler,
  selectedSubCategory,
  hasAll,
}) => {
  return (
    <>
      <SubCategoriesSidebarList
        subcategories={subcategories}
        subCategoryClickHandler={subCategoryClickHandler}
        selectedSubCategory={selectedSubCategory}
        hasAll={hasAll}
      />
    </>
  );
};

SubCategoriesSidebar.defaultProps = {
  hasAll: true,
  selectedSubCategory: {},
};

SubCategoriesSidebar.propTypes = {
  subcategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subCategoryClickHandler: PropTypes.func.isRequired,
  selectedSubCategory: PropTypes.shape({} || undefined),
  hasAll: PropTypes.bool,
};

export { SubCategoriesSidebar };
