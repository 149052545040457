import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { COOKIE_ACCEPT } from '@constants/config.constants';
import { legalDocs } from '@constants/legal-docs';
import { ReactComponent as CloseIcon } from '@assets/svg/close_icon.svg';
import './style.scss';

const MessageCookies = () => {
  const cookies = new Cookies();
  const [checkMessageCookie, setCheckMessageCookie] = useState(cookies.get(COOKIE_ACCEPT));
  const [modalLegalDocs, setModalLegalDocs] = useState(false);
  const [contentLegalDocs, setContentLegalDocs] = useState(false);

  const handleAcceptCookie = {
    ACCEPT: () => {
      setCheckMessageCookie('true');
      cookies.set(COOKIE_ACCEPT, 'true', { path: '/' });
    },
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      const url = legalDocs.DATA;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setContentLegalDocs(data);
      } catch (e) {
        throw new Error(e.message);
      }
    };
    fetchPlaces();
  }, []);

  return (
    <>
      <Dialog
        open={modalLegalDocs}
        fullWidth
        onClose={() => setModalLegalDocs(false)}
        maxWidth="md"
        classes={{ root: 'cookie-dialog-root' }}
        style={{ zIndex: 1301 }}
      >
        <IconButton
          onClick={() => setModalLegalDocs(false)}
          classes={{ root: 'coupons-modal__close-icon' }}
        >
          <CloseIcon height="17px" />
        </IconButton>
        <DialogTitle disableTypography>
          <Typography variant="h5" className="cookie-dialog-root-modal-title-modal">
            Política de cookies
          </Typography>
        </DialogTitle>
        <div className="flex direction-column cookie-dialog-root-container__user-type cookie-dialog-root-modal">
          <h5 className="content-modal">
            {contentLegalDocs !== false
              ? Object.values(contentLegalDocs.legalterms.confidenciality[19].description).map(
                  (item, idx) => (
                    <div key={idx}>
                      {item}
                      <br />
                    </div>
                  ),
                )
              : 'Cargando...'}
          </h5>
        </div>
      </Dialog>
      <div
        className={`flex ${
          checkMessageCookie === 'true'
            ? 'cookie-dialog-root-none'
            : 'cookie-dialog-root-message--cookies'
        }`}
      >
        <span className="cta-text">
          Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúas la
          navegación, consideraremos que aceptas su uso. Conoce más en la política de cookies
        </span>
        <div className="cta-buttons">
          <Button
            className="button button-secondary"
            color="primary"
            variant="outlined"
            onClick={() => setModalLegalDocs(true)}
          >
            Políticas de cookies
          </Button>
          <Button
            className="button"
            variant="contained"
            color="primary"
            onClick={handleAcceptCookie.ACCEPT}
          >
            Entendido
          </Button>
        </div>
      </div>
    </>
  );
};

export { MessageCookies };
