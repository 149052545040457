import * as Yup from 'yup';
import { isValidNit } from '@utils/validations/nit.validations';

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Nombre requerido'),
    phone: Yup.string().required('Teléfono requerido'),
    notes: Yup.string().required('Las notas son requerido'),
    businessName: Yup.string().required('Nombre de marca requerido'),
    website: Yup.string().required('Sitio web o Instagram requerido'),
    email: Yup.string()
      .email('Correo electrónico invalido')
      .required('Correo electrónico requerido'),
    documentNumber: Yup.number().required('NIT requerido'),
    verification_digit: Yup.number()
      .required('Dígito de verificación requerido')
      .test({
        name: 'digitVerification',
        message: 'Dígito incorrecto',
        test(value) {
          return isValidNit(`${this.parent.documentNumber}${value}`);
        },
      }),
  });

export const formikConf = () => ({
  validateOnChange: true,
  validateOnBlur: false,
  initialValues: {
    name: '',
    businessName: '',
    documentNumber: '',
    phone: '',
    verification_digit: '',
    email: '',
    instagram: '',
    website: '',
    notes: '',
  },
  validationSchema: validationSchema(),
});

export const onsubmit = (handleSubmit) => (values, formikProps) => {
  formikProps.setSubmitting(false);
  handleSubmit(values);
};
