import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { ReactComponent as NextIcon } from '@assets/svg/next.carousel.icon.svg';
import { ReactComponent as BackIcon } from '@assets/svg/back.carousel.icon.svg';
import { useWindowSize } from '@hooks/use-windows-size.hook';
import styles from './categoriesHeader.module.scss';
import './styles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CategoriesHeaderList = ({ categories, activeCategory, children }) => {
  const { width } = useWindowSize();
  const carrouselWidth = 80;
  const buttonWidth = 100;
  const sliderRef = useRef(null);
  const [initialSlide, setInitialSlide] = useState(0);

  const getButtonsQtyToShow = () => {
    const isValidValues = width && carrouselWidth && buttonWidth;

    if (isValidValues) {
      return Math.round((width * carrouselWidth) / 100 / buttonWidth);
    }
    return 3;
  };

  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    arrows: true,
    initialSlide,
    slidesToShow: getButtonsQtyToShow(),
    slidesToScroll: 3,
    lazyLoad: true,
    adaptiveHeight: true,
    nextArrow: <NextIcon width={32} height={32} />,
    prevArrow: <BackIcon width={32} height={32} />,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: getButtonsQtyToShow(),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: getButtonsQtyToShow(),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1070,
        settings: {
          slidesToShow: getButtonsQtyToShow(),
          slidesToScroll: 3,
        },
      },
    ],
  };

  useEffect(() => {
    const activeCategoryIndex = categories?.findIndex((x) => x.id === activeCategory?.id);
    setInitialSlide(activeCategoryIndex);
    if (sliderRef.current && activeCategoryIndex !== -1) {
      sliderRef.current.slickGoTo(activeCategoryIndex);
    }
  }, [activeCategory, categories]);

  return (
    <div className={`custom ${styles.categoriesHeader}`}>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

CategoriesHeaderList.defaultProps = {
  activeCategory: {},
};

CategoriesHeaderList.propTypes = {
  children: PropTypes.node.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeCategory: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export { CategoriesHeaderList };
