import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import loadable from '@loadable/component';
import { Loader, ProductAdditionalInfo } from '@superfuds/saori';
import { Button, Grid, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { useParams, withRouter } from 'react-router-dom';
import cartIconAsset from '@assets/svg/cart_icon.svg';
import { Creators as ProductCreators } from '@reducers/Product';
import { Creators as CategoriesCreators } from '@reducers/Categories';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { Creators as UserCreators } from '@reducers/User';
import { history } from '@redux/store';
import { ProductCounter, BreadCrumb } from '@components/index';
import { MessagesInventory } from '@constants/index';
import { ProductViewerModal as ProductViewer } from '@components/organisms/ProductDetails/ProductViewer';
import { currencyFormatter } from '@utils/render';
import './detail.container.scss';

import { Layout } from '../Layout/layout';

const ProductMarker = loadable(() => import('./ProductMarker'));

const ProductDetailContainer = ({ product, categories, isLoading, token, user }) => {
  const dispatch = useDispatch();
  const [quantity, setQty] = useState(1);
  const [isEmpty] = useState(false);
  const { productId, templateId } = useParams();

  let category;
  const breadCrumbData = [];
  const mapArrayFeature = [];
  const otherFeature = [];

  const {
    categoryId,
    shortName,
    brand,
    packPrice,
    brandId,
    discount,
    price_real: priceReal,
    short_description: shortDescription,
    total_inventory: totalInventory,
    description,
    publicSugeredPrice,
    // percentageMargin,
  } = product || {};

  const brandName = product && brand ? brand : 'Superfüds';
  const listFeature = description ? description.split(';') : [];

  listFeature.forEach((feature, index) => {
    if (feature.indexOf(':') > -1) {
      const content = feature.split(':');
      mapArrayFeature.push({ label: content[0], detail: content[1] });
    } else {
      otherFeature.push(feature);
    }

    if (listFeature.length - 1 === index && otherFeature.length > 0) {
      mapArrayFeature.push({ label: 'Otros beneficios', detail: otherFeature.join() });
    }
  });

  const onBrandNameClick = () => {
    if (product && product.brandId) {
      history.push(`/marca/${brandId}`);
    }
  };

  if (categoryId && categoryId.length > 0) {
    category = categories.find(
      (category) =>
        (categoryId.length > 1 && category.id === categoryId[1]) || category.id === categoryId[0],
    );

    if (category) breadCrumbData.push({ name: category.name, route: `/categoria/${category.id}` });
  } else if (brandId) {
    breadCrumbData.push({ name: brandName, route: `/marca/${brandId}` });
  }

  breadCrumbData.push({ name: shortName, route: `/producto/${productId}` });

  useEffect(() => {
    dispatch(ProductCreators.getProductByIdRequest({ productId, templateId }));
  }, [dispatch, productId, templateId]);

  const formattedPackPrice = currencyFormatter(packPrice);
  const formattedPriceReal = currencyFormatter(priceReal);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <Layout>
      <Grid container item>
        <Grid container className="productDetail" />
        <BreadCrumb urls={breadCrumbData} />
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ marginTop: 25, paddingRight: 15, paddingLeft: 15 }}
        >
          <Grid item xs={12} md={6} className="productDetail__left" spacing={3}>
            {product.images && <ProductViewer product={product} />}
          </Grid>
          <Grid
            container
            className="productDetail__right text-left right-side pl-1"
            item
            alignItems="flex-end"
            xs={12}
            md={6}
          >
            {product?.recommendationSales > 2 && (
              <Typography className="productDetail__recommended" variant="h4">
                Recomendados Superfüds
              </Typography>
            )}
            <Typography className="productDetail__name name" variant="h1">
              {shortName}
            </Typography>
            <Typography
              className="productDetail__brand brand brandname"
              variant="h3"
              onClick={onBrandNameClick}
            >
              {brandName}
            </Typography>
            <Typography className="productDetail__unit" variant="h5">
              Embalaje de {product.units} Unidad por {product.net_content}
            </Typography>
            <Grid container className="rightSide">
              <Grid item xs={12}>
                <div className={`productDetail__price `}>
                  <div>
                    {token ? (
                      <>
                        {discount > 0 ? (
                          <>
                            <p className="discountPrice">Precio normal: {formattedPriceReal}</p>
                            <span className="boldDiscount">{formattedPackPrice}</span>
                            <span className="discountValue">-{discount}%</span>
                          </>
                        ) : (
                          <span className="bold">{formattedPackPrice}</span>
                        )}
                      </>
                    ) : (
                      <span
                        className="bold"
                        style={{
                          position: 'relative',
                        }}
                        onClick={() => dispatch(UserCreators.setAuthModal(true))}
                      >
                        ${' '}
                        <LockIcon
                          fontSize="inherit"
                          style={{
                            position: 'absolute',
                            top: 2,
                          }}
                        />
                      </span>
                    )}
                    {!token && discount > 0 && <span className="discountValue">-{discount}%</span>}
                    {publicSugeredPrice > 0 && (
                      <Typography className="productDetail__pvps mt-2" variant="h5">
                        Revende por
                        <strong
                          style={{
                            paddingLeft: 5,
                            fontSize: '1.2rem',
                          }}
                        >
                          {currencyFormatter(publicSugeredPrice)}
                        </strong>
                      </Typography>
                    )}
                    {/*   {percentageMargin > 0 && (
                      <Typography className="productDetail__margin mb-3" variant="h5">
                        Ganancia por unidad <CheckIcon />
                        <strong> {currencyFormatter(product.priceMargin)}</strong>
                        <span>{percentageMargin}%</span>
                      </Typography>
                    )} */}
                  </div>
                </div>
              </Grid>
            </Grid>
            <div style={product.isClothes ? { marginBottom: 16 } : {}} />
            <p className="productDetail__resume shortDescription mb-2">{shortDescription}</p>
            {product?.expirationDate && product?.shortDateExpiry && (
              <>
                <p
                  style={{
                    lineHeight: '1.5',
                    color: '#2D2D2D',
                    width: '100%',
                    fontWeight: 100,
                  }}
                >
                  <span
                    style={{
                      color: '#F65858',
                      fontWeight: 600,
                    }}
                  >
                    ¡Atención!
                  </span>
                  <br />
                  Solo faltan{' '}
                  <span
                    style={{
                      color: '#F65858',
                      fontWeight: 600,
                    }}
                  >{` ${product?.daysUntilExpiration} días`}</span>{' '}
                  para el vencimiento de este producto. <br /> ¡Aprovecha y cómpralo antes de que se
                  agoten las existencias!
                </p>
                <p className="observations mb-2">
                  Este producto próximo a fecha de expiración no admite devoluciones.
                </p>
              </>
            )}
            {(product.warranty && product.warranty.qty) ||
              (product.distributor && product.distributor.length > 0) ||
              product?.hasPresale ? (
              <ProductMarker
                warranty={product.warranty}
                distributor={product.distributor}
                hasPresale={product?.hasPresale}
              />
            ) : null}
            <div className="productDetail__attributes mb-2">
              {product?.attributes.length > 0 &&
                product?.attributes.slice(0, 3).map((attribute) => {
                  return (
                    <div
                      key={`healthyHabitStamp-${attribute.id}`}
                      className="productDetail__attributes__item"
                      onClick={() => {
                        dispatch(CategoriesCreators.openModalDiets(true));
                      }}
                    >
                      {attribute.stamp && <img src={attribute.stamp} alt={attribute.name} />}
                    </div>
                  );
                })}
            </div>
            {token && (
              <Grid container className="rightSide" xs={12}>
                <Grid item xs={6} sm={6}>
                  <ProductCounter
                    source="details"
                    totalStock={totalInventory}
                    productQty={quantity}
                    onChange={(dif) => {
                      if (totalInventory === dif) {
                        dispatch(ShoppingCartCreators.openSnackbar(MessagesInventory));
                      }

                      setQty(dif);
                    }}
                    disabled={totalInventory === 0}
                    className="productDetail__counter"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  {totalInventory > 0 ? (
                    <Button
                      id="add-cart-button"
                      className="productDetail__addToCart add-cart-button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isEmpty}
                      onClick={() => {
                        dispatch(
                          ShoppingCartCreators.addToShoppingCart({
                            ...product,
                            itemId: product.id,
                            quantityInPackages: quantity,
                            quantity,
                          }),
                        );
                      }}
                      style={{ width: '100%', textTransform: 'capitalize' }}
                    >
                      <SVG
                        className="svg svg-white productDetail__addToCart__icon"
                        src={cartIconAsset}
                      />
                      Agregar
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="default"
                      className="productDetail__addToCart productDetail__addToCart--disabled"
                      disabled={isEmpty}
                      style={{ width: '100%', textTransform: 'capitalize' }}
                    >
                      Agotado
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
            {!token && (
              <Button
                id="add-cart-button"
                className="productDetail__addToCart add-cart-button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => dispatch(UserCreators.setAuthModal(true))}
                style={{ width: 'auto', textTransform: 'capitalize' }}
              >
                Ver precio
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      {mapArrayFeature && mapArrayFeature.length > 0 && (
        <Grid
          xs={12}
          item
          className="productDetail__features text-left"
          justifyContent="space-between"
          container
        >
          <Typography className="productDetail__features__title" variant="h2">
            Información adicional
          </Typography>
          <ul className="productDetail__features__list">
            {mapArrayFeature.map((e, index) => (
              <li className="productDetail__features__listItem" key={index}>
                <span className="productDetail__features__listItemText productDetail__features__listItemText--label">
                  {e.label}:
                </span>
                <span className="productDetail__features__listItemText productDetail__features__listItemText--detail">
                  {e.detail}
                </span>
              </li>
            ))}
          </ul>
        </Grid>
      )}

      <Grid
        className="productDetail__extraInfo"
        item
        xs={12}
        justifyContent="space-between"
        container
      >
        {product?.includes && product.includes?.length > 0 && (
          <ProductAdditionalInfo list={product.includes} title="¿Qué incluye?" />
        )}

        {product?.extraInfo && product.extraInfo?.length > 0 && (
          <ProductAdditionalInfo list={product.extraInfo} title="Información adicional" />
        )}
      </Grid>
    </Layout>
  );
};

ProductDetailContainer.defaultProps = {
  token: null,
};

ProductDetailContainer.propTypes = {
  product: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    clientType: PropTypes.number,
  }).isRequired,
  token: PropTypes.string,
};

const mapStateToProps = ({ product, user, categories, delivery, auth }) => {
  return {
    product: product.data,
    isLoading: product.isLoading,
    categories: categories.categories,
    user,
    userLocations: delivery.userLocations,
    token: auth.token,
  };
};

const ProductDetail = connect(mapStateToProps)(withRouter(ProductDetailContainer));

export default ProductDetail;
