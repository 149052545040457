import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '@assets/svg/close_icon.svg';
import { legalDocs } from '@constants/index';

import './styles.scss';

export const LegalDocsModal = ({ isOpen, setModalLegalDocs, typeOption, data, setTypeOption }) => {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [returnPolicy, setreturnPolicy] = useState([]);
  const [confidenciality, setconfidenciality] = useState([]);

  const [faq, setFaq] = useState(false);
  const renderOptions = useCallback(
    (item, data) => {
      switch (item) {
        case legalDocs.PF:
          setTitle('Preguntas frecuentes');
          setFaq(true);
          setreturnPolicy([]);
          setModalLegalDocs(true);
          setconfidenciality([]);
          break;
        case legalDocs.PD:
          setTitle('Términos de devolucion');
          setreturnPolicy(data.legalterms.returnPolicy);
          setMessage('');
          setModalLegalDocs(true);
          setconfidenciality([]);
          break;
        case legalDocs.PP:
          setconfidenciality(data?.legalterms?.confidenciality);
          setMessage('');
          setTitle(data ? data?.legalterms?.confidenciality[0].title || [] : 'Cargando...');
          setModalLegalDocs(true);
          setreturnPolicy([]);
          break;
        case legalDocs.DR:
          setTitle('Derecho de retracto');
          setreturnPolicy(data.legalterms?.rightofWithdrawal || []);
          setMessage('');
          setModalLegalDocs(true);
          setconfidenciality([]);
          break;
        case legalDocs.SR:
          setTitle('Reversión de pago');
          setreturnPolicy(data.legalterms?.reversalOfPayments || []);
          setMessage('');
          setModalLegalDocs(true);
          setconfidenciality([]);
          break;
        default:
          item = '';
          break;
      }
    },
    [setMessage, setTitle, setModalLegalDocs, setFaq],
  );
  useEffect(() => {
    if (typeOption) renderOptions(typeOption, data);
  }, [typeOption, renderOptions, data]);

  const renderFaq = () => (
    <div
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{
        __html:
          "<iframe src='https://docs.google.com/document/d/e/2PACX-1vQeiALbxhuf4o81KW_1o_-4qp85MWir0IqdilLgg0lpxRfdeIuW0xpdjCEMGuifDVvUHMkwU0JKoqM6/pub?embedded=true' />",
      }}
    />
  );

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={() => {
        setModalLegalDocs(false);
        setFaq(false);
        setTypeOption('');
      }}
      maxWidth="md"
      classes={{ root: 'legal-docs-dialog-root-modal' }}
      style={{ zIndex: 150001 }}
    >
      <DialogTitle disableTypography style={{ textAlign: 'center' }}>
        <Typography variant="h5" className="cookie-dialog-root-modal-title-modal">
          {title}
        </Typography>
        <IconButton
          onClick={() => {
            setModalLegalDocs(false);
            setFaq(false);
            setTypeOption('');
          }}
          classes={{ root: 'coupons-modal__close-icon' }}
        >
          <CloseIcon height="17px" />
        </IconButton>
      </DialogTitle>
      <div className="flex direction-column cookie-dialog-root-container__user-type cookie-dialog-root-modal">
        <h5 className="content-modal"> {faq ? renderFaq() : message} </h5>

        {returnPolicy.length > 0 && (
          <article className="content-modal">
            <h5 style={{ fontWeight: 500 }}>{returnPolicy[0]?.title}</h5>
            {returnPolicy[0]?.description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5 style={{ fontWeight: 500 }}>{returnPolicy[1]?.title}</h5>
            {returnPolicy[1]?.description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
          </article>
        )}
        {confidenciality?.length > 0 && (
          <article className="content-modal">
            <h5>{confidenciality[0].title}</h5>
            {confidenciality[0].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[1].title}</h5>
            {confidenciality[1].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[2].title}</h5>
            {confidenciality[2].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[3].title}</h5>
            {confidenciality[3].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[4].title}</h5>
            {confidenciality[4].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[5].title}</h5>
            <h5 style={{ textAlign: 'justify' }}>{confidenciality[5].description}</h5>
            <h5>{confidenciality[6].title}</h5>
            {confidenciality[6].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[7].title}</h5>
            <h5 style={{ textAlign: 'justify' }}>{confidenciality[7].description}</h5>
            <h5>{confidenciality[8].title}</h5>
            {confidenciality[8].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[9].title}</h5>
            {confidenciality[9].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[10].title}</h5>
            {confidenciality[10].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[11].title}</h5>
            {confidenciality[11].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[12].title}</h5>
            {confidenciality[12].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[13].title}</h5>
            <h5 style={{ textAlign: 'justify' }}>{confidenciality[13].description}</h5>
            <h5>{confidenciality[14].title}</h5>
            {confidenciality[14].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[15].title}</h5>
            {confidenciality[15].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[16].title}</h5>
            {confidenciality[16].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[17].title}</h5>
            {confidenciality[17].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[18].title}</h5>
            {confidenciality[18].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[19].title}</h5>
            {confidenciality[19].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[20].title}</h5>
            {confidenciality[20].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[21].title}</h5>
            <h5 style={{ textAlign: 'justify' }}>{confidenciality[21].description}</h5>
            <h5>{confidenciality[22].title}</h5>
            <h5 style={{ textAlign: 'justify' }}>{confidenciality[22].description}</h5>
            <h5>{confidenciality[23].title}</h5>
            <h5 style={{ textAlign: 'justify' }}>{confidenciality[23].description}</h5>
            <h5>{confidenciality[24].title}</h5>
            {confidenciality[24].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[25].title}</h5>
            {confidenciality[25].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[26].title}</h5>
            {confidenciality[26].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
            <h5>{confidenciality[27].title}</h5>
            {confidenciality[27].description.map((paragraph) => (
              <h5 style={{ textAlign: 'justify' }}>{paragraph}</h5>
            ))}
          </article>
        )}
      </div>
    </Dialog>
  );
};

LegalDocsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setModalLegalDocs: PropTypes.func.isRequired,
  typeOption: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  setTypeOption: PropTypes.func.isRequired,
};

LegalDocsModal.defaultProps = {};
