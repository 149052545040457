import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like bannersRequest -> banners_REQUEST
 */
export const { Types, Creators } = createActions({
  bannersRequest: null,
  bannersSuccess: ['data'],
  bannersFailed: ['error'],
});
