import { put, select } from 'redux-saga/effects';
import { Creators as ShoppingCartCreators, getShoppingCartId } from '@reducers/Sales';
import { userHasLocation } from '@reducers/Locations';
import { ShoppingCartService } from '@services/shopping-cart.service';
import { transformCoupons } from '@transforms/ApiToCart.transform';
import { CouponErrors } from '@constants/sales.constants';

export function* getCoupons() {
  try {
    const userHasActiveLocation = yield select(userHasLocation);
    if (userHasActiveLocation) {
      const couponsResponse = yield ShoppingCartService.getCoupons();
      const couponsList = transformCoupons(couponsResponse);
      yield put(ShoppingCartCreators.getCouponsSucceeded(couponsList));
    }
  } catch (error) {
    yield put(
      ShoppingCartCreators.getCouponsFailed({
        status: 400,
        message: CouponErrors[error?.parsedBody?.errors],
        couponInput: '',
      }),
    );
  }
}

export function* addCoupon({ coupon }) {
  try {
    console.log(coupon);
    const ordenId = yield select(getShoppingCartId);
    yield ShoppingCartService.addCoupon(coupon, ordenId);
    yield put(ShoppingCartCreators.getShoppingCart());
    const couponInfo = {
      status: 200,
      message: 'Aplicado exitosamente',
      couponInput: coupon,
    };
    yield put(ShoppingCartCreators.addCouponSucceded(couponInfo));
  } catch (error) {
    console.log(error);
    yield put(ShoppingCartCreators.openSnackbar('Cupón no valido'));
  }
}

export function* selectCoupon({ coupon }) {
  try {
    const ordenId = yield select(getShoppingCartId);
    yield ShoppingCartService.selectCoupon(coupon, ordenId);
    const couponsResponse = yield ShoppingCartService.getCoupons();
    const couponsList = transformCoupons(couponsResponse);
    const couponInfo = {
      status: 200,
      message: 'Aplicado exitosamente',
      couponInput: coupon,
      couponsList,
    };
    yield put(ShoppingCartCreators.openSnackbar(couponInfo.message));
    yield put(ShoppingCartCreators.getShoppingCart());
    yield put(ShoppingCartCreators.addCouponSucceded(couponInfo));
  } catch (error) {
    yield put(ShoppingCartCreators.getShoppingCart());
  }
}

export function* deleteCoupon({ coupon }) {
  try {
    const ordenId = yield select(getShoppingCartId);
    yield ShoppingCartService.deleteCoupon(ordenId);
    yield put(ShoppingCartCreators.getShoppingCart());
    yield put(ShoppingCartCreators.getCoupons());
  } catch (error) {
    yield put(ShoppingCartCreators.getShoppingCart());
  }
}
