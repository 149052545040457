import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Typography,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import * as moment from 'moment';
import 'moment/locale/es';

import { useDispatch } from 'react-redux';
import { ProductCounter } from '@components/index';
import leaves from '@assets/images/leaves_color.png';
import { currencyFormatter } from '@utils/render';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { Creators as LocationCreators } from '@reducers/Locations';
import { Creators as AuthCreators } from '@reducers/Auth';
import { UserType, DEPARTMENT_ID } from '@constants/index';

moment().locale('es');

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div" className="tab-content">
      {children}
    </Typography>
  );
}
TabContainer.defaultProps = {
  children: null,
};

TabContainer.propTypes = {
  children: PropTypes.node,
};

const ProductInfo = ({
  product: { id, packPriceWithTax, total_inventory, description, short_description },
  product,
  token,
  filterCity,
  user: { clientType },
  user,
}) => {
  const [page, setPage] = useState(0);
  const [quantity, setQty] = useState(1);

  const dispatch = useDispatch();

  const [isEmpty] = useState(false);
  const arrayDescription = description ? description.split(';') : [];
  function handleTabChange(event, newValue) {
    setPage(newValue);
  }

  const ImagenDescription = () => (
    <div>
      <List component="nav" aria-label="secondary mailbox folders">
        {arrayDescription.length > 0
          ? arrayDescription.map((e, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    alt="Remy Sharp"
                    src={leaves}
                  />
                </ListItemAvatar>
                <ListItemText primary={e} />
              </ListItem>
            ))
          : ''}
      </List>
    </div>
  );
  return (
    <Grid container direction="column" justify="space-between" className="product-info">
      <Grid item className="tab-name-container">
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={page}
          onChange={handleTabChange}
        >
          <Tab className="tabName" label="Información" />
          <Tab className="tabName" label="Descripción" />
        </Tabs>
      </Grid>

      <Grid item className="tab-container">
        {page === 0 && <TabContainer>{ImagenDescription()}</TabContainer>}
        {page === 1 && <TabContainer> {short_description}</TabContainer>}
      </Grid>
      <Grid
        className="product-counter-total-container"
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        item
      >
        <Grid container direction="column" alignItems="flex-start" item xs>
          <Typography variant="subtitle1">Cantidad</Typography>
          <ProductCounter
            productQty={quantity}
            onChange={(dif) => setQty(dif)}
            disabled={total_inventory === 0}
          />
        </Grid>

        <Grid container direction="column" item xs>
          <Typography variant="subtitle1">Precio Total</Typography>
          <Typography variant="h6">{currencyFormatter(quantity * packPriceWithTax)}</Typography>
        </Grid>
      </Grid>

      {total_inventory > 0 ? (
        <Button
          id="add-cart-button"
          className="add-cart-button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isEmpty}
          onClick={() => {
            if (user.isLoggedIn) {
              dispatch(
                ShoppingCartCreators.addToShoppingCart({
                  itemId: id,
                  quantityInPackages: quantity,
                  product,
                }),
              );
            } else if (!user.isLoggedIn) {
              if (!localStorage.getItem(DEPARTMENT_ID)) {
                if (clientType === parseInt(UserType.B2B, 10)) {
                  dispatch(AuthCreators.openBToBModal());
                } else {
                  dispatch(LocationCreators.toggleGoogleMapsModal());
                }
              }
            }
          }}
          style={{ width: '100%', textTransform: 'capitalize' }}
        >
          Agregar
        </Button>
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="default"
          disabled={isEmpty}
          style={{ width: '100%', textTransform: 'capitalize' }}
        >
          Agotado
        </Button>
      )}
    </Grid>
  );
};
ProductInfo.propTypes = {
  product: PropTypes.shape({
    shortName: PropTypes.string.isRequired,
    brand: PropTypes.string,
    net_content: PropTypes.string.isRequired,
    brandId: PropTypes.number,
    units: PropTypes.number,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  token: PropTypes.string,
  filterCity: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
  user: PropTypes.shape({
    clientType: PropTypes.number,
  }).isRequired,
};

ProductInfo.defaultProps = {
  token: null,
};

export { ProductInfo };
