import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';

import 'react-toastify/dist/ReactToastify.css';

import styles from './dinamicToastNotification.module.scss';

export const DinamicToastNotification = ({ message }) => {
  const dispatch = useDispatch();
  const notify = React.useCallback(
    () =>
      toast.dark(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        className: styles.snackBar,
      }),
    [message],
  );

  React.useEffect(() => {
    notify();
    setTimeout(() => dispatch(ShoppingCartCreators.closeSnackbar()), 2000);
  }, [dispatch, notify]);

  return <ToastContainer style={{ top: '2em' }} />;
};

DinamicToastNotification.defaultProps = {
  message: 'Alcanzaste el limite de unidades en el inventario',
};

DinamicToastNotification.propTypes = {
  message: PropTypes.string,
};
