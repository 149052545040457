import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';
import { ProductList, CategoriesHeaderList } from '@components/index';
import { Creators as ProductsCreators } from '@reducers/Products';
import { history } from '@redux/store';
import { SubCategoriesSidebar } from '@containers/SubCategoriesSidebar/subCategoriesSidebar.container';
import { TOKEN_KEY } from '@constants/index';

import './by-diets.container.scss';

import { Layout } from '../Layout/layout';

const ProductsByDietsContainer = ({ diets, products, isLoading, hasMore }) => {
  const dispatch = useDispatch();
  const { dietsId } = useParams();
  const [activeDiet, setActiveDiet] = useState(
    diets.find((diet) => diet.id === parseInt(dietsId, 10) || undefined),
  );
  const dietClickHandler = (id) => {
    history.push(`/habitos-saludables/${id || ''}`);
  };

  useEffect(() => {
    const diet = diets.find((diet) => diet.id === parseInt(dietsId, 10) || undefined);
    if (diet) {
      setActiveDiet(diet);
    }
  }, [dispatch, diets, dietsId]);

  useEffect(() => {
    dispatch(ProductsCreators.getProductsByDietsRequest(dietsId, true));
  }, [dispatch, dietsId]);

  const isTabletOrWeb = useMediaQuery('(min-width: 992px)');
  const isTablet = useMediaQuery('(min-width: 599px)');

  return (
    <Layout isFullWidth>
      <Grid container item xs={12} className="by-diets-container">
        <Grid
          container
          item
          xs={12}
          className="by-diets-container__slider"
          hidden={isTabletOrWeb}
          style={{
            top: !isTablet ? (localStorage.getItem(TOKEN_KEY) ? '90px' : '53px') : '',
          }}
        >
          <CategoriesHeaderList categories={diets} activeCategory={activeDiet}>
            {diets &&
              diets?.length &&
              diets?.map((diet, idx) => {
                const isActive = activeDiet?.id === diet.id;
                return (
                  <Grid
                    item
                    container
                    key={diet.id}
                    onClick={() => {
                      history.push(`/habitos-saludables/${diet.id}`);
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      className={`by-diets-container__card-diet   ${
                        isActive ? 'by-diets-container__card-diet__active' : ''
                      }`}
                    >
                      <img
                        data-sizes="auto"
                        className={`logo lazyload  Brand-${idx}`}
                        src={diet.icon}
                        alt={diet.name}
                        width="50px"
                        height="50px"
                      />
                      <span
                        id={`DietsCarouselDietTitle-${diet.name}`}
                        className="diet-card-component__title"
                      >
                        {diet.name}
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
          </CategoriesHeaderList>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            item
            md={isTabletOrWeb && 2}
            hidden={!isTabletOrWeb}
            className="by-diets-container__sidebar"
          >
            <SubCategoriesSidebar
              subcategories={diets}
              subCategoryClickHandler={dietClickHandler}
              selectedSubCategory={activeDiet}
              hasAll={false}
            />
          </Grid>
          <Grid container item md={isTabletOrWeb ? 10 : 12} justifyContent="center">
            <Typography variant="h3" className="by-diets-container__title_page">
              Hábitos saludables {activeDiet && <span>{activeDiet?.name}</span>}
            </Typography>
            <ProductList
              getMore={() => {
                if (!isLoading && hasMore) {
                  dispatch(ProductsCreators.getProductsByDietsRequest(dietsId));
                }
              }}
              isLoading={isLoading}
              products={products}
            />
          </Grid>
        </Grid>
        {/*    <Grid item xs={12} className="by-diets-container__sidebar">
            <SubCategoriesSidebar
              subcategories={diets}
              subCategoryClickHandler={dietClickHandler}
              selectedSubCategory={activeDiet}
              hasAll={false}
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            style={{ margin: 'auto', alignItems: 'flex-start', minHeight: '600px' }}
          >
            <Typography variant="h3" className="by-diets-container__title_page">
              Hábitos saludables
            </Typography>
            <ProductList
              getMore={() => {
                if (!isLoading && hasMore) {
                  dispatch(ProductsCreators.getProductsByDietsRequest(dietsId));
                }
              }}
              isLoading={isLoading}
              products={products}
            />
          </Grid> */}
      </Grid>
    </Layout>
  );
};

ProductsByDietsContainer.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      brand: PropTypes.string,
      dietsId: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  diets: PropTypes.array.isRequired,
};

ProductsByDietsContainer.defaultProps = {
  products: [],
};

const mapStateToProps = ({ products: { items, isLoading, hasMore }, diets: { data } }) => {
  return {
    products: items,
    isLoading,
    hasMore,
    diets: data,
  };
};

const ProductsByDiets = connect(mapStateToProps)(withRouter(ProductsByDietsContainer));
export default ProductsByDiets;
