import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { SubCategoriesSidebarLink } from '@components/atoms/SubCategoriesSidebarLink/subCategoriesSidebarLink.atom';
import { stringNormalize } from '@utils/format/stringNormalize.utils';
import styles from './subCategoriesSidebarList.module.scss';

const SubCategoriesSidebarList = ({
  subcategories,
  subCategoryClickHandler,
  selectedSubCategory,
  hasAll,
}) => {
  const location = useLocation();

  return (
    <ul className={styles.subCategories__sidebarList}>
      <Typography
        style={{
          padding: '0',
          fontWeight: 700,
          borderBottom: '1px solid #818181',
          color: '#818181',
        }}
      >
        Filtrar Por:
      </Typography>
      <Typography style={{ padding: '10px 0 10px 0', fontWeight: 700, color: '#818181' }}>
        {!location.pathname.includes('habitos-saludables') ? 'Sub Categorias' : 'Habito Saludable'}
      </Typography>
      {hasAll && (
        <SubCategoriesSidebarLink
          id="SubCategoriesSidebarCategory-AllCategories"
          text="Todos"
          onClickHandler={() => subCategoryClickHandler(undefined, 'Todos')}
          isActive={Object.keys(selectedSubCategory).length === 0}
        />
      )}
      {subcategories?.map((subCategory, index) => {
        return (
          <SubCategoriesSidebarLink
            id={`SubCategoriesSidebarCategory-${stringNormalize(subCategory.name)}`}
            key={index}
            text={subCategory.name}
            onClickHandler={() => subCategoryClickHandler(subCategory.id, subCategory.name)}
            isActive={subCategory.id === selectedSubCategory?.id}
          />
        );
      })}
    </ul>
  );
};

SubCategoriesSidebarList.defaultProps = {
  hasAll: false,
  selectedSubCategory: {},
};

SubCategoriesSidebarList.propTypes = {
  subcategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSubCategory: PropTypes.shape({} || undefined),
  subCategoryClickHandler: PropTypes.func.isRequired,
  hasAll: PropTypes.bool,
};

export { SubCategoriesSidebarList };
