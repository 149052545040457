/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  data: [],
  loading: true,
  bannersError: null,
});
/* ------------- Reducers ------------- */
export const bannersRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
  };
};

export const bannersSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    data,
    bannersError: null,
    loading: false,
  };
};
export const bannersFailed = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    bannersError: error,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BANNERS_REQUEST]: bannersRequest,
  [Types.BANNERS_SUCCESS]: bannersSuccess,
  [Types.BANNERS_FAILED]: bannersFailed,
});
