import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like loginRequest -> LOGIN_REQUEST
 */

export const { Types, Creators } = createActions({
  ordersRequest: null,
  ordersSuccess: ['orders'],
  getOrders: ['date'],
  getOrdersByMonth: ['page', 'month'],
  ordersSeasonRequest: null,
  ordersSeasonSuccess: ['seasons'],
  getSeasons: null,
  ordersExperienceRequest: null,
  postExperience: ['orderId', 'body'],
  openExperienceUserModalRequest: null,
  closeExperienceUserModalRequest: null,
});
