import { select, put, call, putResolve } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import {
  Creators as LocationsCreators,
  getAnonActiveLocation,
  getActiveLocation,
  getUserAnonLocations,
  delivery,
} from '@reducers/Locations';
import {
  Creators as ShoppingCartCreators,
  getHasTicketNumberCart,
  getIsPaymentLoading,
  getShoppingCart,
} from '@reducers/Sales';

import { Creators as AuthCreators, userIsLoggedIn } from '@reducers/Auth';
import { LocationService } from '@services/location.service';
import { transformLocations } from '@transforms/ApiToLocations.transforms';
import { fetchAll } from '@sagas/StartupSagas';
import { TOKEN_VALIDATION } from '@constants/config.constants';
import { getStatus } from '@utils/errors';
import { ADD_TO_CART_PENDING, LOGIN_FROM_ADD_TO_CART } from '@constants/payment.constants';

export function* getUserLocations() {
  const isPaymentLoading = yield select(getIsPaymentLoading);
  const hasTicketNumberCart = yield select(getHasTicketNumberCart);
  try {
    const { data: userLocations } = yield LocationService.getUserLocations();
    const hasSelectedLocation = userLocations.find((location) => location.isSelected);
    if (hasSelectedLocation) {
      const anonLocation = yield select(getAnonActiveLocation);
      if (!anonLocation?.city && !hasSelectedLocation.name) {
        yield put(LocationsCreators.addFilterCity(hasSelectedLocation));
      }
    }
    const mappedLocations = transformLocations(userLocations);
    yield put(LocationsCreators.userLocationFetchSucceeded({ userLocations: mappedLocations }));
    if (!isPaymentLoading) {
      if (!hasTicketNumberCart) {
        if (userLocations.find((location) => location.isSelected)) {
          yield put(ShoppingCartCreators.getShoppingCart());
        }
      }
    }
    yield call(fetchAll);
  } catch (error) {
    const message = error?.parsedBody?.data?.message;
    if (getStatus(error) === 401 && message === TOKEN_VALIDATION) {
      yield put(AuthCreators.logoutRequest());
    }
  }
}

export function* getAnonLocations() {
  const anonLocations = LocationService.getAnonLocations();
  yield put(LocationsCreators.anonLocationFetchSucceeded(anonLocations));
  yield call(fetchAll);
}

export function* changeSelectedLocation({ payload: { locationId, forceSelection = false } }) {
  try {
    const { data: userLocations } = yield LocationService.changeSelectedLocation({
      locationId,
      forceSelection,
    });

    const mappedLocations = transformLocations(userLocations);
    yield put(LocationsCreators.userLocationFetchSucceeded({ userLocations: mappedLocations }));

    yield call(fetchAll);
    yield put(ShoppingCartCreators.getShoppingCart());
  } catch (error) {
    yield put(LocationsCreators.stopLoading());
  }
}

export function* addUserLocation({ payload: { location, forceSelection = false } }) {
  const { isOpenSuperFastCobertureModal } = yield select(delivery);
  const { isShoppingCartOpen } = yield select(getShoppingCart);
  try {
    let activeLocation = yield select(getActiveLocation);
    if (activeLocation === undefined) {
      const { data: userLocations } = yield LocationService.getUserLocations();
      activeLocation = userLocations.find((location) => location.isSelected);
    }
    const { data: userLocations } = yield LocationService.addUserLocation({
      location,
      forceSelection,
    });

    /* Logic to handle SFModal */
    const canShowSFModal = Object.prototype.hasOwnProperty.call(
      userLocations,
      'lossOfItemsIfChange',
    );
    if (canShowSFModal) {
      const { data: userLocations } = yield LocationService.getUserLocations();
      activeLocation = userLocations.find((location) => location.isSelected);
      yield put(
        LocationsCreators.openSuperFastModalRequest({
          confirmAddress: true,
          changeForceLocation: true,
          data: { locationId: activeLocation.id, forceSelection: true },
        }),
      );
      /*   yield put(LocationsCreators.stopLoading());
        yield put(LocationsCreators.toggleGoogleMapsModal());
        return; */
    }
    /* End Logic */
    const mappedLocations = transformLocations(userLocations);
    yield put(
      LocationsCreators.userLocationFetchSucceeded({
        userLocations: mappedLocations,
        isLocationSaved: true,
      }),
    );

    yield put(LocationsCreators.toggleGoogleMapsModal());
    if (localStorage.getItem(ADD_TO_CART_PENDING)) {
      const product = JSON.parse(localStorage.getItem(ADD_TO_CART_PENDING));
      yield put(ShoppingCartCreators.addToShoppingCart(product));
      localStorage.removeItem(ADD_TO_CART_PENDING);
    }

    if (isOpenSuperFastCobertureModal) {
      yield put(LocationsCreators.closeSuperFastModalRequest());
      if (isShoppingCartOpen) yield put(ShoppingCartCreators.toggleShoppingCart());
    }

    yield call(fetchAll);
    yield put(ShoppingCartCreators.openSnackbar('¡Dirección guardada!'));
    if (localStorage.getItem(LOGIN_FROM_ADD_TO_CART))
      localStorage.removeItem(LOGIN_FROM_ADD_TO_CART);
    yield put(ShoppingCartCreators.getShoppingCart());
  } catch (error) {
    captureException(error);
  }
}

export function* deleteUserLocation({ locationId }) {
  const isLoggedIn = yield select(userIsLoggedIn);

  if (isLoggedIn) {
    try {
      yield LocationService.deleteUserLocation({ locationId });
    } catch (e) {}
    const userLocations = yield LocationService.getUserLocations();
    const mappedLocations = transformLocations(userLocations);
    yield put(LocationsCreators.userLocationFetchSucceeded({ userLocations: mappedLocations }));
  } else {
    const anonLocations = LocationService.getAnonLocations();
    const locations = anonLocations.filter((location) => location.id !== locationId);
    yield put(LocationsCreators.anonLocationFetchSucceeded(locations));
  }
}

export function* updateUserLocation({ location }) {
  const isLoggedIn = yield select(userIsLoggedIn);
  if (isLoggedIn) {
    try {
      yield LocationService.updateUserLocation({ location });
      const { data: userLocations } = yield LocationService.getUserLocations();
      const mappedLocations = transformLocations(userLocations);
      yield put(LocationsCreators.closeLocationModal());
      yield put(LocationsCreators.toggleGoogleMapsModal());
      yield put(ShoppingCartCreators.openSnackbar('¡Dirección guardada!'));
      yield put(
        LocationsCreators.userLocationFetchSucceeded({
          userLocations: mappedLocations,
          isLocationSaved: true,
        }),
      );
    } catch (error) {
      captureException(error);
    }
  } else {
    const { address, city, department, isSelected, aditionalInfo, latitude, longitude } = location;
    const anonLocations = yield select(getUserAnonLocations);
    const currentAnonLocations = anonLocations.map((anonLocation) => {
      if (anonLocation.id === location.id) {
        return {
          ...anonLocation,
          address,
          city,
          department,
          isSelected,
          aditionalInfo,
          latitude,
          longitude,
          name: location.name,
        };
      }
      return anonLocation;
    });
    const newAnonLocations = yield LocationService.updateAnonLocations({
      location,
      currentAnonLocations,
    });
    if (location.isSelected) {
      yield putResolve(LocationsCreators.setDepartmentId({ departamentId: department.id }));
      yield call(fetchAll);
    }
    yield put(LocationsCreators.anonLocationFetchSucceeded(newAnonLocations));
    yield put(ShoppingCartCreators.openSnackbar('¡Dirección guardada!'));
  }
}
