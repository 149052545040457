import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like categoriesRequest -> CATEGORIES_REQUEST
 */
export const { Types, Creators } = createActions({
  categoriesRequest: null,
  categoriesSuccess: ['payload'],
  openModalDiets: ['payload'],
});
