import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import { SHOW_CHECKOUT } from '@constants/config.constants';
import { Creators as AuthCreators } from '@reducers/Auth';
import { Creators as UserCreators } from '@reducers/User';
import { ReactComponent as CloseIcon } from '@assets/svg/close_icon.svg';
import styles from './completeUserData.module.scss';
import './formStyle.scss';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#25C16A',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#25C16A',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#25C16A',
      },
    },
  },
})(TextField);

const CompleteUserData = ({ user }) => {
  const dispatch = useDispatch();
  const [documentType, setDocumentType] = useState('');
  const [document, setDocument] = useState('');

  const defaultUserData = {
    email: user.email || '',
    password: '',
    name: user.name || '',
    lastName: user.lastName || '',
    phone: user.phone || '',
    businessName: '',
    documentType,
    document,
    verificationDigit: '',
    sector: '',
    sectorDescription: '',
  };

  const isDisabled = () => !documentType || !document || (document && !document.match(/^\d+$/));
  return (
    <Dialog
      onClose={() => {}}
      disableEscapeKeyDown
      open
      style={{ zIndex: 2000 }}
      disableEnforceFocus
    >
      <section className={styles.completeUserDataModal}>
        <div className={styles.completeUserDataModal__warning}>
          <CloseIcon
            className={styles.completeUserDataModal__close}
            onClick={() => {
              dispatch(UserCreators.toggleCompleteDocumentModal(false));
            }}
          />
          <h3 className={styles.completeUserDataModal__title}>Completar datos</h3>
          <p className={styles.completeUserDataModal__description}>
            Antes de finalizar tu compra necesitamos conocer tu documento de identidad para generar
            tu factura
          </p>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl fullWidth>
                <InputLabel id="documentTypeLabel">Tipo de documento</InputLabel>
                <Select
                  native
                  labelId="documentTypeLabel"
                  id="documentType"
                  key="documentType"
                  value={documentType}
                  label="Tipo de documento"
                  input={<OutlinedInput label="Tipo de documento" id="demo-dialog-native" />}
                  onChange={(event) => {
                    setDocumentType(event.target.value);
                  }}
                >
                  <option key={0} value="" />
                  <option key={5} value={5}>
                    CC
                  </option>
                  <option key={13} value={13}>
                    CE
                  </option>
                  <option key={2} value={2}>
                    Pasaporte
                  </option>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <CssTextField
                  id="document"
                  key="document"
                  className={styles.completeUserDataModal__input}
                  size="small"
                  placeholder="Numero documento"
                  variant="outlined"
                  name="document"
                  label="Numero documento"
                  type="text"
                  value={document}
                  onChange={(e) => setDocument(e.target.value)}
                  style={{ height: '50px', marginTop: '20px' }}
                />
              </FormControl>
            </Box>
          </DialogContent>

          <div className={styles.completeUserDataModal__buttonsContainer}>
            <button
              disabled={isDisabled()}
              type="button"
              onClick={() => {
                dispatch(AuthCreators.signupRequest({ ...defaultUserData }));
                dispatch(UserCreators.toggleCompleteDocumentModal(false));
                localStorage.setItem(SHOW_CHECKOUT, 'true');
              }}
              className={styles.completeUserDataModal__buttonsContainer__buttonGreen}
            >
              Continuar
            </button>
          </div>
        </div>
      </section>
    </Dialog>
  );
};

CompleteUserData.propTypes = {
  user: PropTypes.shape({}).isRequired,
};
export { CompleteUserData };
