/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  items: [],
  isLoading: true,
  currentPage: null,
  hasMore: true,
  detailsBrand: [],
});
/* ------------- Reducers ------------- */
export const brandsRequest = (state = INITIAL_STATE, { clean }) => {
  return {
    ...state,
    items: clean ? [] : state.items,
    currentPage: clean ? null : state.currentPage,
    isLoading: true,
  };
};
export const brandsSuccess = (state = INITIAL_STATE, { items }) => {
  return {
    ...state,
    items: [...state.items, ...items.data],
    currentPage: items.currentPage,
    hasMore: items.total > 0,
    isLoading: false,
  };
};

export const brandsDetailsRequest = (state = INITIAL_STATE, { clean }) => {
  return {
    ...state,
    items: clean ? [] : state.items,
    currentPage: clean ? null : state.currentPage,
    isLoading: true,
  };
};

export const brandsDetailsSuccess = (state = INITIAL_STATE, { item }) => {
  return {
    ...state,
    isLoading: false,
    detailsBrand: [
      {
        url: item?.banner,
        id: item?.id?.toString(),
        alt: item?.name,
        name: item?.name,
        image: item?.image,
        description: item?.description,
        discount: item?.discount_percentage,
        position: item?.position,
      },
    ],
  };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BRANDS_REQUEST]: brandsRequest,
  [Types.BRANDS_SUCCESS]: brandsSuccess,
  [Types.BRANDS_DETAILS]: brandsDetailsRequest,
  [Types.BRANDS_DETAILS_SUCCESS]: brandsDetailsSuccess,
});
