/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  categories: [],
  sections: [],
  expressRoutes: [],
  exploreCategories: [],
  loading: false,
  error: null,
  isOpenModalDiets: false,
});
/* ------------- Reducers ------------- */
export const categoriesRequest = (state = INITIAL_STATE) => {
  return { ...state, loading: true };
};

export const categoriesSuccess = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    categories: payload.categories,
    sections: payload.sections,
    expressRoutes: payload.expressRoutes,
    exploreCategories: payload.exploreCategories,
    error: null,
    loading: false,
  };
};
export const openModalDiets = (state, { payload }) => {
  return {
    ...state,
    isOpenModalDiets: payload,
  };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CATEGORIES_REQUEST]: categoriesRequest,
  [Types.CATEGORIES_SUCCESS]: categoriesSuccess,
  [Types.OPEN_MODAL_DIETS]: openModalDiets,
});
