import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { history } from '@redux/store';
import { TitleSections } from '@components/index';
import { stringNormalize } from '../../../utils/format/stringNormalize.utils';

import './style.scss';

const DietsCardCarouselBase = ({ diets, viewMoreUrl, user }) => {
  const [filterDiets, setFilterDiets] = useState([]);
  useEffect(() => {
    setFilterDiets(diets.slice(0, 20));
  }, [diets]);

  const cards = filterDiets.map((diet, index) => {
    if (diets) {
      return (
        <Grid
          item
          container
          xs={3}
          id={`DietsCarouselDietCard-${stringNormalize(diet.name)}`}
          key={diet.id}
          className={`diet-card-component cursor-pointer Brand-${index}`}
          onClick={() => {
            history.push(`/habitos-saludables/${diet.id}`);
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={4} xs={12} className="card-img">
            <img
              data-sizes="auto"
              className={`logo lazyload  Brand-${index}`}
              src={diet.icon}
              alt={diet.name}
              width="100%"
              height="100%"
            />
          </Grid>
          <Grid item md={8} xs={12} className="card-text">
            <span
              id={`DietsCarouselDietTitle-${stringNormalize(diet.name)}`}
              className="diet-card-component__title"
            >
              {diet.name}
            </span>
          </Grid>
        </Grid>
      );
    }
    return false;
  });

  return (
    <Grid item md={12} className="carousel-diets mb-5 mt-5">
      <TitleSections
        id="DietsCarouselTitleSections"
        title="Hábitos saludables"
        onClick={() => {
          history.push(viewMoreUrl);
        }}
        show={false}
      />
      <Grid container justifyContent="flex-start" alignItems="baseline">
        {cards}
      </Grid>
    </Grid>
  );
};

DietsCardCarouselBase.propTypes = {
  diets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  viewMoreUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export const DietsCardCarousel = withRouter(DietsCardCarouselBase);
