import React from 'react';
import propTypes from 'prop-types';
import { FormControl, InputLabel, Select, TextField, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Field, Form, Formik } from 'formik';
import { validateEmail, validateDocumentType, validateDocument } from '@utils/form/form.utils';
import { DocumentTypes } from '@constants/odoo.constants';
import { PAYMENT__METHODS } from '@constants/sales.constants';
import { stringNormalize } from '../../../../utils/format/stringNormalize.utils';
import { setInitialValues } from './pse.formik';

import './style.scss';

export const PSEForm = ({
  user,
  toggleForm,
  paymentMethod,
  handlePaymentMethod,
  cleanPaymentMethod,
  handleWarningPSEPayment,
  listBank,
}) => {
  if (user?.loading) {
    return null;
  }
  return (
    <div className="pse-form">
      <Formik
        onSubmit={(values) => {
          if (paymentMethod?.method === PAYMENT__METHODS.TC) {
            handleWarningPSEPayment({ ...values, method: 'PSE' });
            return;
          }
          cleanPaymentMethod();
          handlePaymentMethod({ ...values, method: 'PSE' });
          toggleForm();
        }}
        initialValues={setInitialValues(user, paymentMethod)}
      >
        {({ errors, handleChange, handleBlur, touched, dirty, setFieldValue }) => (
          <Form className="pse-form-container">
            <div className="form__container-pse">
              <div className="form__email">
                <Field id="PaymentMethodsPSEFormEmailField" name="email" validate={validateEmail}>
                  {({ field }) => (
                    <TextField
                      id="PaymentMethodsPSEFormEmailTextField"
                      size="small"
                      label="Correo electrónico"
                      error={Boolean(errors.email) && touched.email}
                      helperText={errors.email && touched.email && String(errors.email)}
                      variant="outlined"
                      name="email"
                      type="text"
                      value={field.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
              </div>
              <div className="form__document">
                <Field
                  id="PaymentMethodsPSEFormDocumentTypeField"
                  key="documentType"
                  name="documentType"
                  validate={validateDocumentType}
                >
                  {({ field }) => (
                    <FormControl
                      id="PaymentMethodsPSEFormDocumentTypeFormControl"
                      size="small"
                      error={Boolean(errors.documentType)}
                      variant="outlined"
                    >
                      <InputLabel id="PaymentMethodsPSEFormDocumentTypeInputLabel">
                        Tipo de documento
                      </InputLabel>
                      <Select
                        id="PaymentMethodsPSEFormDocumentTypeSelect"
                        native
                        labelId="document-type-label"
                        name="documentType"
                        onChange={handleChange}
                        label="Tipo de documento"
                      >
                        {DocumentTypes.map((option) => (
                          <option
                            id={`PaymentMethodsPSEFormDocumentTypeSelectOption-${stringNormalize(
                              option.label,
                            )}`}
                            key={option.id}
                            value={option.id}
                          >
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Field
                  id="PaymentMethodsPSEFormDocumentNumberField"
                  key="documentNumber"
                  name="documentNumber"
                  validate={validateDocument}
                >
                  {({ field }) => (
                    <TextField
                      id="PaymentMethodsPSEFormDocumentNumberTextField"
                      size="small"
                      label="N° de documento"
                      error={Boolean(errors.documentNumber) && touched.documentNumber}
                      helperText={
                        errors.documentNumber &&
                        touched.documentNumber &&
                        String(errors.documentNumber)
                      }
                      variant="outlined"
                      name="documentNumber"
                      inputMode="numeric"
                      value={field.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
              </div>
              <div className="form__bank" style={{ marginBottom: 16 }}>
                <Field id="PaymentMethodsPSEFormBankField" key="bank" name="bank">
                  {({ field }) => (
                    <Autocomplete
                      options={listBank.slice(1)}
                      onChange={(_, value) => setFieldValue('bank', value?.pseCode || '')}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.bank) && touched.bank}
                          helperText={touched.bank && errors.bank}
                          label="¿Cuál es tu banco?"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
            <Button
              id="PaymentMethodsPSEFormContinueButton"
              onClick={() => window.scroll(0, 0)}
              type="submit"
              className={
                dirty &&
                !errors.bank &&
                !errors.documentNumber &&
                !errors.documentType &&
                !errors.email
                  ? 'form__button--pse'
                  : 'form__button--disabled'
              }
              disabled={
                !dirty ||
                errors.bank ||
                errors.documentNumber ||
                errors.documentType ||
                errors.email
              }
            >
              Agregar
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PSEForm.propTypes = {
  user: propTypes.shape({
    loading: propTypes.bool,
  }).isRequired,
  toggleForm: propTypes.func.isRequired,
  cleanPaymentMethod: propTypes.func.isRequired,
  paymentMethod: propTypes.shape({
    bank: propTypes.string,
    documentNumber: propTypes.string,
    documentType: propTypes.string,
    email: propTypes.string,
    cardName: propTypes.string,
    cardNumber: propTypes.string,
    cvv: propTypes.string,
    date: propTypes.string,
    method: propTypes.string,
  }).isRequired,
  handlePaymentMethod: propTypes.func.isRequired,
  handleWarningPSEPayment: propTypes.func.isRequired,
  listBank: propTypes.arrayOf(propTypes.shape({})).isRequired,
};
