import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { history } from '@redux/store';
import Error500Img from '@assets/images/error500.png';

import './styles.scss';

import { Layout } from '../Layout/layout';

const Error500Container = () => (
  <Layout>
    <Container id="page-500" className="page-500">
      <Grid
        container
        className="pt-6"
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12} md={4}>
          <Typography variant="h5" className="title-page-500 ">
            Algo inesperado ha pasado
          </Typography>
          <Typography className="pt-6 paragraph-500">
            Lo sentimos, estamos trabajando en resolver el problema, si necesitas ayuda{' '}
            <a
              href="https://api.whatsapp.com/send/?phone=+57310 2042338&text&app_absent=0"
              rel="nofollow"
            >
              escríbenos
            </a>{' '}
            <br /> o intentalo más tarde.
          </Typography>
          <Button
            className="mt-6 link"
            color="primary"
            variant="contained"
            onClick={() => history.push('/')}
          >
            <Typography>Regresar al home</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <img src={Error500Img} className="image-page-500" alt="imagen-500" />
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

Error500Container.propTypes = {};

Error500Container.defaultProps = {};

export const Error500 = withRouter(Error500Container);

// export default Error500;
