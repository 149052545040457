import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WAImage from '@assets/svg/whatsapp.icon.svg';

import './styles.scss';

const CustomerServiceButton = ({ user }) => {
  const [whatsappLink, setWhatsappLink] = useState('');

  async function obtenerUbicacion() {
    try {
      const respuesta = await fetch(`https://ipinfo.io/json`);
      if (!respuesta.ok) throw new Error('No se pudo obtener la ubicación');

      const datos = await respuesta.json();

      if (datos.country === 'CO' && datos.city === 'Bogotá') {
        setWhatsappLink('https://wa.link/r9h215'); // Link para Bogotá
      } else {
        setWhatsappLink('https://wa.link/bar9rx'); // Link para el resto de Colombia
      }
    } catch (error) {
      setWhatsappLink('https://wa.link/r9h215'); // Link para Bogotá
    }
  }

  useEffect(() => {
    obtenerUbicacion();
  }, []);
  console.log(whatsappLink);

  return (
    <div id="sirena_button" className="right title" role="presentation">
      <a
        id="floating_button_sirena"
        className="widget_bg1 right"
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <img src={WAImage} id="widget_logo" alt="Whatsapp Logo" />
      </a>
    </div>
  );
};

CustomerServiceButton.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export { CustomerServiceButton };
