/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
  Container,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
  Link,
} from '@material-ui/core';
import {
  Loader,
  AuthModal,
  LocationModalV2,
  UserLocationV4,
  DietsDictionaryModal,
  PasswordRecovery,
} from '@superfuds/saori';

import {
  TOKEN_KEY,
  QueryParams,
  shippingTypeOtp,
  legalDocs,
  COOKIE_ACCEPT,
  EMAIL_DEMO,
  PASSWORD_DEMO,
} from '@constants/index';

import { Creators as LocationsCreators } from '@reducers/Locations';
import { Creators as AuthCreators } from '@reducers/Auth';
import { Creators as UserCreators } from '@reducers/User';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { Creators as CategoriesCreators } from '@reducers/Categories';

import { useKeepUiVersionInQueryStringIfPresent } from '@hooks/use-keep-ui-version';
import { useKeepClientIdInQueryStringIfPresent } from '@hooks/use-keep-client-id';
import { useLegalDocs } from '@hooks/use-legal-documents';
import { useQueryParam } from '@hooks/use-query-param.hook';
import { usePlaces } from '@hooks/use-places';
import { useScrollToTop } from '@hooks/use-windows-top';

import {
  Footer,
  Header,
  MessageCookies,
  NavigationDrawer,
  ProductAddToast,
  SignupBrands,
  DinamicToastNotification,
  LegalDocsModal,
} from '@components/index';

import { history as url } from '@redux/store';
import { CustomerServiceButton } from '@components/atoms';
import banner from '@assets/images/banner.webp';
import megafono from '@assets/images/megafono.png';

import { ReactComponent as ClaraLogo } from '@assets/svg/clara.svg';
import { SectionOperation } from './SectionLayout/ContainerSectionOperation';
import './style.scss';
import 'react-toastify/dist/ReactToastify.css';

const LayoutBase = ({
  token,
  user,
  categories,
  children,
  loading,
  isFullWidth,
  shoppingCart,
  isAuthLoading,
  otpError,
  otpSent,
  passwordError,
  enableEditMode,
  delivery,
  isLocationSaved,
  isOpenLocationModal,
  isLocationLoading,
  userLocations,
  anonLocations,
  isEnabledBack,
  isOpenModalDiets,
  diets,
  allowedUser,
  scroll,
  brandError,
  bransisLoading,
  statusCodeBrand,
  recoveryModalOpen,
  errorUpdatePassword,
}) => {
  useKeepUiVersionInQueryStringIfPresent();
  useKeepClientIdInQueryStringIfPresent();
  useScrollToTop();

  const emailToken = useQueryParam(QueryParams.EMAIL_TOKEN);
  const isDemo = useQueryParam(QueryParams.DEMO) === 'demo';
  const isRedirectedFromEmail = useQueryParam(QueryParams.RECOVERY_PASSWORD) === 'true';
  const ref = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const contentLegalDocs = useLegalDocs();
  const cookies = new Cookies();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeMenssage, setMenssage] = useState(false);
  const [modalLegalDocs, setModalLegalDocs] = useState(false);
  const [typeOption, setTypeOption] = useState('');

  const { isAuthModal } = user;
  const { places } = usePlaces({ clientType: user.clientType, isLoggedIn: user.isLoggedIn });

  useEffect(() => {
    if (isDemo) {
      localStorage.setItem('DEMO_USER', EMAIL_DEMO);
      dispatch(
        AuthCreators.emailLoginRequest({
          email: EMAIL_DEMO,
          password: PASSWORD_DEMO,
        }),
      );
    }
  }, [dispatch, isDemo]);

  const handleEditAddress = useCallback(
    (id) => {
      dispatch(LocationsCreators.enableUserAddressEditMode(id));
      dispatch(LocationsCreators.toggleGoogleMapsModal());
    },
    [dispatch],
  );

  useEffect(() => {
    setMenssage(allowedUser);
    const timer = setTimeout(() => {
      setMenssage(false);
      if (!user.isLoggedIn && allowedUser) {
        dispatch(AuthCreators.authFailure({}));
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [allowedUser, dispatch, user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (recoveryModalOpen && errorUpdatePassword) {
        dispatch(AuthCreators.updatePasswordReset());
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [recoveryModalOpen, dispatch, errorUpdatePassword]);

  useEffect(() => {
    if (scroll) {
      dispatch(AuthCreators.resetScrollBottom());
      ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [scroll, ref]);

  useEffect(() => {
    if (isRedirectedFromEmail && emailToken) {
      dispatch(UserCreators.setAuthModal(false));
      dispatch(AuthCreators.openPasswordRecovery());
    }
  }, [dispatch, isRedirectedFromEmail, emailToken]);

  useEffect(() => {
    if (user.isLoggedIn && !!localStorage.getItem(TOKEN_KEY)) {
      dispatch(UserCreators.setAuthModal(false));
      dispatch(ShoppingCartCreators.getShoppingCart());
      dispatch(ShoppingCartCreators.getListBank());
      dispatch(ShoppingCartCreators.getListCard());
      dispatch(ShoppingCartCreators.getTokenZiro());
    }
    dispatch(ShoppingCartCreators.stopShoppingCartSale());
  }, [dispatch, user.isLoggedIn]);

  const handleClose = () => {
    setOpen(false);
    cookies.set('ClaraNotification', 'true');
  };

  return (
    <Container
      style={{ padding: 0, maxWidth: '100%', textAlign: 'center' }}
      className={
        (location.pathname === '/404' && 'backgroundGreen') ||
        (location.pathname === '/500' && 'backgroundYellow') ||
        ''
      }
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
        className="modal-clara"
        style={{ zIndex: 1301 }}
      >
        <DialogTitle id="alert-dialog-title">
          <img
            src={megafono}
            fetchPriority="high"
            alt="Super Anuncio"
            style={{
              width: 50,
              marginBottom: '-15px',
            }}
          />
          ¿Quieres recibir $500.000 GRATIS?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="text-center">
            <Typography variant="h6">
              ¡Solo debes hacer tu primera compra con la{' '}
              <strong>Tarjeta de Credito BLACK de Clara</strong>! y además, obtendrás 30 días de
              plazos de pago SIN INTERESES.
            </Typography>
            <ClaraLogo width={200} />
            <Typography variant="h5">
              Pídela
              <Link href="https://share.clara.com/mQqSOVm" target="_blank">
                {' '}
                YA aquí{' '}
              </Link>{' '}
              o{' '}
              <Link href="https://wa.link/6l1gp9" target="_blank">
                {' '}
                comunícate{' '}
              </Link>{' '}
              con un asesor
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Loader isLoading={loading} />
      <Header
        diets={diets}
        toggleSideCart={() => url.push('/cart')}
        shoppingCartItemsQty={shoppingCart.totalItems || 0}
        toggleLocationModal={() => {
          isOpenLocationModal
            ? dispatch(LocationsCreators.closeLocationModal())
            : dispatch(LocationsCreators.openLocationModal());
        }}
        toggleDrawer={() => setDrawerIsOpen(!drawerIsOpen)}
        user={user}
        setAuthModal={() => dispatch(UserCreators.setAuthModal(!isAuthModal))}
        token={localStorage.getItem(TOKEN_KEY)}
        drawerIsOpen={drawerIsOpen}
        categories={categories}
      />
      {shoppingCart.toggleAddProductSnackBar && <ProductAddToast />}
      {drawerIsOpen && (
        <NavigationDrawer
          isOpen={drawerIsOpen}
          onClose={() => setDrawerIsOpen(false)}
          categories={categories}
          user={user}
          setAuthModal={() => dispatch(UserCreators.setAuthModal(!isAuthModal))}
          toggleLocationModal={() => {
            isOpenLocationModal
              ? dispatch(LocationsCreators.closeLocationModal())
              : dispatch(LocationsCreators.openLocationModal());
          }}
        />
      )}
      <LegalDocsModal
        isOpen={modalLegalDocs}
        setModalLegalDocs={setModalLegalDocs}
        typeOption={typeOption}
        data={contentLegalDocs}
        setTypeOption={setTypeOption}
      />
      <DietsDictionaryModal
        toggleShowDictionaryModal={() => {
          dispatch(CategoriesCreators.openModalDiets(false));
        }}
        onClick={(id) => {
          dispatch(CategoriesCreators.openModalDiets(false));
          url.push(`/habitos-saludables/${id}`);
        }}
        isOpen={isOpenModalDiets}
        zIndex={9999}
        diets={diets.data}
      />
      <AuthModal
        isVisible={isAuthModal}
        accessTypeClick={() => { }}
        zIndex={130012}
        onClose={(isAnonym = false) => {
          dispatch(AuthCreators.logoutRequest());
        }}
        backButton={() => {
          dispatch(AuthCreators.validateResetOtp());
        }}
        showTerms={() => setTypeOption(legalDocs.PP)}
        isB2B
        allowedUser={activeMenssage}
        dataUser={{ ...user }}
        status={user.status}
        changeUserType={user.changeUserType}
        isLoading={isAuthLoading}
        otpError={otpError}
        passwordError={passwordError}
        otpSent={otpSent}
        otpErrorMessage="El código ingresado es incorrecto"
        onSubmitPhone={(phone, type) =>
          dispatch(AuthCreators.loginRequest(phone, shippingTypeOtp[type]))
        }
        onSubmitOtp={(data) => dispatch(AuthCreators.validateOtpRequest(data))}
        onRegister={(userInfo) => {
          user.changeUserType
            ? dispatch(UserCreators.userChangeTypeRequest(userInfo))
            : dispatch(AuthCreators.signupRequest({ ...userInfo }));
        }}
        onEmailLogin={(email) => dispatch(AuthCreators.emailLoginRequest(email))}
        validateEmail={(email) => dispatch(AuthCreators.validateEmailRequest(email))}
        onForgotPassword={({ email }) => {
          dispatch(AuthCreators.forgotPasswordRequest({ email }));
        }}
        name={user.name}
        onSupport={() => console.log('click')}
        handleChageToB2B={() => { }}
        changeUserStatus={() => {
          dispatch(UserCreators.changeStatus({ status: 0 }));
          dispatch(AuthCreators.resetOtpSentRequest());
        }}
        activeCloseButton
      />
      {!cookies.get(COOKIE_ACCEPT) && <MessageCookies />}
      <CustomerServiceButton />
      {isOpenLocationModal && (
        <LocationModalV2
          addressesList={user.isLoggedIn ? userLocations : anonLocations}
          anonAddress={
            delivery.anonLocations.length
              ? delivery.anonLocations.filter((address) => address.isSelected)
              : []
          }
          isOpenLocationModal={isOpenLocationModal}
          toggleLocationModal={() => {
            dispatch(LocationsCreators.closeLocationModal());
          }}
          isLoggedIn={user.isLoggedIn}
          selectAddress={(locationId) =>
            dispatch(LocationsCreators.changeSelectedLocation({ locationId }))
          }
          handleEditAddress={handleEditAddress}
          toggleUserLocationModal={() => dispatch(LocationsCreators.toggleGoogleMapsModal())}
          addUserAddress={(location) => dispatch(LocationsCreators.addUserLocation(location))}
          deleteUserAddress={(locationId) =>
            dispatch(LocationsCreators.deleteUserLocation(locationId))
          }
          toggleShoppinCart={() => dispatch(ShoppingCartCreators.toggleShoppingCart())}
          isLocationSaved={isLocationSaved}
          canEditAddress={false}
        />
      )}
      {shoppingCart.snackbarIsOpen && (
        <DinamicToastNotification message={shoppingCart.snackbarMessage} />
      )}
      {recoveryModalOpen && (
        <PasswordRecovery
          message={errorUpdatePassword}
          isVisible={recoveryModalOpen}
          onClose={() => {
            dispatch(UserCreators.setAuthModal(true));
            window.location.replace(`${window.location.origin}/${window.location.search}`);
            dispatch(AuthCreators.closePasswordRecovery());
          }}
          isLoading={isAuthLoading}
          onUpdatePassword={(data) => {
            localStorage.setItem(TOKEN_KEY, emailToken);
            dispatch(AuthCreators.updatePassword(data));
          }}
        />
      )}
      {delivery.isOpenGoogleMapsModal && (
        <UserLocationV4
          addUserAddress={(location) => dispatch(LocationsCreators.addUserLocation({ location }))}
          editUserAddress={(location) => dispatch(LocationsCreators.updateUserLocation(location))}
          places={places}
          isOpenModal={delivery.isOpenGoogleMapsModal}
          isEnabledBack={!isEnabledBack}
          isLoggedin={user.isLoggedIn}
          toggleUserLocation={() => dispatch(LocationsCreators.toggleGoogleMapsModal())}
          addresses={user.isLoggedIn ? delivery.userLocations : delivery.anonLocations}
          enableEditMode={enableEditMode.enable ? enableEditMode : false}
          disableEditMode={() => dispatch(LocationsCreators.disableUserAddressEditMode())}
          isLocationSaved={isLocationSaved}
          resetIsLocationSaved={() => dispatch(LocationsCreators.resetIsLocationSaved())}
          isLocationLoading={isLocationLoading}
          isEnableCobertureRange
          requestStatus={null}
        />
      )}
      <SectionOperation />
      <Container
        classes={{
          root: 'childrens-container',
          maxWidthLg:
            location.pathname.includes('categoria') ||
              location.pathname.includes('habitos-saludables') ||
              location.pathname.includes('marca')
              ? `childrens-container__p_lg_category`
              : `childrens-container__p_${isFullWidth ? 'full-width' : 'lg'}`,
        }}
        style={{
          marginBottom: location.pathname === '/proveedor' && 50,
        }}
      >
        <Grid container item>
          {children}
        </Grid>
      </Container>
      {location.pathname === '/proveedor' && (
        <Grid
          component={Box}
          item
          ref={ref}
          display={{ xs: 'flex', md: 'flex' }}
          className="register-container"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item md={7} xs={12} className="text-center">
              <Typography variant="h4">
                ¡Conquista nuevos clientes y aumenta tus ventas en toda las zonas donde tenemos
                cobertura, en muy poco tiempo!
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className="text-center">
              {statusCodeBrand !== 200 ? (
                <SignupBrands
                  brandError={brandError}
                  onSubmit={(values) => dispatch(AuthCreators.signUpBransRequest({ ...values }))}
                  bransisLoading={bransisLoading}
                />
              ) : (
                <Grid item xs={12} className="text-center">
                  <Typography variant="h4">¡Perfecto!</Typography>
                  <Typography variant="h5">
                    ¡Un asesor se pondrá en contacto contigo en breve!
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {location.pathname === '/' && !token && (
        <Grid
          component={Box}
          item
          display={{ xs: 'flex', md: 'flex' }}
          className="register_section"
          style={{
            justifyContent: 'center',
            color: 'white',
            backgroundImage: `url(${banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: '5%',
          }}
        >
          <Typography className="text-center" variant="h4">
            ¿Estas listo para empezar?
            <br />
            Compre productos al por mayor que a sus clientes les encantarán.
            <br />
            <div
              className="button-login-home"
              onClick={() => dispatch(UserCreators.setAuthModal(!isAuthModal))}
            >
              <div className="right-column cursor-pointer">
                <Typography className="name-text" variant="subtitle1">
                  Comienza a comprar
                </Typography>
              </div>
            </div>
          </Typography>
        </Grid>
      )}
      <Footer handleClick={setTypeOption} />
    </Container>
  );
};

LayoutBase.defaultProps = {
  token: null,
  loading: false,
  isFullWidth: false,
  otpSent: null,
  isLocationSaved: null,
  deliveryLocation: {},
  isOpenModalDiets: false,
  allowedUser: false,
  scroll: false,
  brandError: '',
  errorUpdatePassword: '',
  bransisLoading: false,
  statusCodeBrand: false,
};

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string,
  brandError: PropTypes.string,
  isLocationSaved: PropTypes.bool,
  bransisLoading: PropTypes.bool,
  statusCodeBrand: PropTypes.number,
  loading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    d: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    clientType: PropTypes.number,
    isAuthModal: PropTypes.bool,
    snackbarMessage: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    places: PropTypes.arrayOf(PropTypes.shape({})),
    isOpenWarningProducts: PropTypes.bool.isRequired,
    status: PropTypes.number,
    changeUserType: PropTypes.bool,
    source: PropTypes.string,
    loading: PropTypes.bool,
    isEmployee: PropTypes.bool,
  }).isRequired,
  otpSent: PropTypes.bool,
  shoppingCart: PropTypes.shape({
    quantityInSkus: PropTypes.number,
    hasExpressItems: PropTypes.bool,
    hasNormalItems: PropTypes.bool,
    ticketNumber: PropTypes.number,
    totalItems: PropTypes.number,
    isOpenCouponer: PropTypes.bool,
    toggleAddProductSnackBar: PropTypes.bool,
    snackbarIsOpen: PropTypes.bool,
    snackbarMessage: PropTypes.string,
  }).isRequired,
  isFullWidth: PropTypes.bool,
  isAuthLoading: PropTypes.bool.isRequired,
  otpError: PropTypes.bool.isRequired,
  passwordError: PropTypes.bool.isRequired,
  deliveryLocation: PropTypes.shape({
    department: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
  isOpenLocationModal: PropTypes.bool.isRequired,
  isOpenGoogleMapsModal: PropTypes.bool.isRequired,
  scroll: PropTypes.bool,
  delivery: PropTypes.shape({
    isLocationLoading: PropTypes.bool.isRequired,
    isOpenGoogleMapsModal: PropTypes.bool.isRequired,
    isOpenLocationModal: PropTypes.bool.isRequired,
    isOpenSuperFastCobertureModal: PropTypes.bool.isRequired,
    showAddressConfirmUI: PropTypes.bool.isRequired,
    changeForceLocation: PropTypes.bool.isRequired,
    data: PropTypes.shape({}),
    userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    anonLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLocationSaved: PropTypes.bool,
    filterCity: PropTypes.shape({
      city: PropTypes.shape({
        name: PropTypes.string,
      }),
      department: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  isLocationLoading: PropTypes.bool.isRequired,
  isEnabledBack: PropTypes.bool.isRequired,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  anonLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enableEditMode: PropTypes.shape({
    enable: PropTypes.bool,
    addressId: null || PropTypes.number,
  }).isRequired,
  diets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenModalDiets: PropTypes.bool,
  allowedUser: PropTypes.bool,
  recoveryModalOpen: PropTypes.bool.isRequired,
  errorUpdatePassword: PropTypes.string,
};

const mapStateToProps = ({
  categories: { categories, isOpenModalDiets },
  user,
  auth: {
    token,
    isB2CModalOpen,
    isB2BModalOpen,
    error: authError,
    isLoading: isAuthLoading,
    otpError,
    otpSent,
    passwordError,
    recoveryModalOpen,
    OtpStatus,
    allowedUser,
    scroll,
    brandError,
    bransisLoading,
    statusCodeBrand,
    errorUpdatePassword,
  },
  delivery: {
    userLocations,
    anonLocations,
    isOpenLocationModal,
    isOpenGoogleMapsModal,
    willPickUpInWarehouse,
    isLocationSaved,
    isLocationLoading,
    enableEditMode,
    isEnabledBack,
  },
  sales: { shoppingCart },
  delivery,
  diets,
}) => ({
  isOpenModalDiets,
  token,
  isB2CModalOpen,
  isB2BModalOpen,
  authError,
  isAuthLoading,
  otpError,
  otpSent,
  passwordError,
  recoveryModalOpen,
  OtpStatus,
  categories,
  user,
  shoppingCart,
  userLocations,
  anonLocations,
  isOpenLocationModal,
  isOpenGoogleMapsModal,
  isEnabledBack,
  willPickUpInWarehouse,
  isLocationSaved,
  isLocationLoading,
  enableEditMode,
  delivery,
  diets,
  allowedUser,
  scroll,
  brandError,
  bransisLoading,
  statusCodeBrand,
  errorUpdatePassword,
});

export const Layout = connect(mapStateToProps)(withRouter(LayoutBase));
