import { call } from 'redux-saga/effects';
import { ODOO_HOST, ODOO_HOST_DEMO, DEMO_USER } from '@constants/index';

import { odooApi } from './api';

export const AuthService = {
  *loginOtp(phone, shippingTypeOtp) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/login/otp',
      {
        type: shippingTypeOtp,
      },
      {
        phone,
        send_by_email: false,
      },
    );
  },
  *loginEmail({ email, password }) {
    odooApi.basePath = email === DEMO_USER ? `${ODOO_HOST_DEMO}/api` : `${ODOO_HOST}/api`;
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/login',
      {},
      {
        email,
        password,
      },
    );
  },
  *validateOtp(data) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/validate-otp',
      {},
      {
        ...data,
      },
    );
  },
  *signup({ userInfo, option }) {
    const params = option || 'email';
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/register',
      {
        option: params,
      },
      userInfo,
    );
  },
  *validateEmail(email) {
    odooApi.basePath = email?.email === DEMO_USER ? `${ODOO_HOST_DEMO}/api` : `${ODOO_HOST}/api`;
    if (email?.email === DEMO_USER) {
      localStorage.setItem('DEMO_USER', DEMO_USER);
    }
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/validate-email',
      {},
      {
        ...email,
      },
    );
  },
  *sendForgotPasswordEmail(email) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/forgot-password',
      {},
      {
        ...email,
      },
    );
  },
  *resetPassword(password) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/change-password',
      {},
      {
        password,
        password_confirmation: password,
      },
    );
  },
};
