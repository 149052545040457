import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { history } from '@redux/store';
import { TitleSections, Carousel, MoreCard, ProductCard } from '@components/index';
import MoreProductAsset from '@assets/svg/MoreProducts.svg';
import { responsiveSettings } from './data';

import './style.scss';

const itemsToSkeletonLoad = 5;

const ProductCarouselBase = ({
  data,
  viewMoreUrl,
  title,
  sources,
  isLoading,
  token,
  filterCity,
  clientType,
  shoppingCart,
  isLoggedIn,
}) => {
  const items = useMemo(
    () =>
      data.map((product, index) => {
        return <ProductCard product={product} idx={index} isLoading={isLoading} />;
      }),
    [data, isLoading],
  );

  if (isLoading) {
    return (
      <Grid item xs={12} className="mt-6">
        <TitleSections id="ContainerCategoryTitleSections" title={title} onClick={() => {}} />
        <Carousel sources={sources} responsiveSettings={responsiveSettings}>
          {Array.from(Array(itemsToSkeletonLoad)).map((item, idx) => (
            <ProductCard key={`loading-${idx}`} isLoading />
          ))}
        </Carousel>
      </Grid>
    );
  }

  return (
    <Grid item lg={12} md={10} sm={10} className="carrousel-container mt-7">
      <TitleSections id="ContainerCategoryTitleSectionsProducts" title={title} onClick={() => history.push(viewMoreUrl)} />
      <Carousel sources={sources} slidesToShow={6} responsiveSettings={responsiveSettings}>
        {items}
        {viewMoreUrl && data.length > 0 && (
          <MoreCard
            key={viewMoreUrl}
            url={MoreProductAsset}
            className="more-product-card"
            title={title}
            onClick={() => history.push(viewMoreUrl)}
          >
            <Typography>
              Ver todos <br /> los productos
            </Typography>
          </MoreCard>
        )}
      </Carousel>
    </Grid>
  );
};

ProductCarouselBase.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  viewMoreUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sources: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  token: PropTypes.string,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
    }),
  ).isRequired,
  filterCity: PropTypes.shape(
    PropTypes.shape({
      city: PropTypes.shape({ name: PropTypes.string }),
    }),
  ).isRequired,
  clientType: PropTypes.number.isRequired,
  shoppingCart: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
  }).isRequired,
};

ProductCarouselBase.defaultProps = {
  data: [],
  isLoading: false,
  token: '',
};

const mapStateToProps = ({
  auth: { token },
  delivery: { userLocations, filterCity },
  user: { clientType, isLoggedIn },
  sales: { shoppingCart },
}) => {
  return {
    token,
    userLocations,
    filterCity,
    clientType,
    shoppingCart,
    isLoggedIn,
  };
};

export const ProductCarousel = connect(mapStateToProps)(ProductCarouselBase);
