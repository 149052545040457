import { put, all, select } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import { UserService } from '@services/user.service';
import { Creators as UserCreators } from '@reducers/User';
import { Creators as LocationsCreators } from '@reducers/Locations';
import { Creators as AuthCreators, userIsLoggedIn } from '@reducers/Auth';
import { EMAIL_REGISTER, TOKEN_KEY, TOKEN_VALIDATION } from '@constants/config.constants';
import { getStatus } from '@utils/errors';

export function* getUserData() {
  try {
    const userData = yield UserService.getUserData();
    mixpanel.identify(userData);

    yield put(
      UserCreators.userDataFetchSucceeded({
        ...userData,
      }),
    );
  } catch (error) {
    const message = error?.parsedBody?.data?.message;
    if (getStatus(error) === 401 && message === TOKEN_VALIDATION) {
      yield put(AuthCreators.logoutRequest());
    }
  }
}

export function* userDataFetchSucceeded() {
  yield all([put(LocationsCreators.getUserLocations())]);
}

export function* updateUserData({ billingData }) {
  try {
    const isLoggedIn = yield select(userIsLoggedIn);
    if (isLoggedIn) {
      yield put(UserCreators.updateProfileRequest());
      yield UserService.updateUserProfile({ ...billingData, document: billingData.documentNumber });
      const userData = yield UserService.getUserData();
      yield put(UserCreators.userDataFetchSucceeded(userData));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* updateUserProfile({ userProfile }) {
  try {
    const isLoggedIn = yield select(userIsLoggedIn);
    if (isLoggedIn) {
      yield put(UserCreators.updateProfileRequest());
      yield UserService.updateUserProfile(userProfile);
      const userData = yield UserService.getUserData();
      yield put(UserCreators.userDataFetchSucceeded(userData));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* changePassword({ password }) {
  try {
    const isLoggedIn = yield select(userIsLoggedIn);
    if (isLoggedIn) {
      const { success: status } = yield UserService.changePassword(password);
      if (status) {
        yield put(AuthCreators.logoutRequest());
      }
    }
  } catch (error) {
    captureException(error);
  }
}

export function* requestNewPassword({ payload }) {
  const { values, setSubmitting, setErrorHttpRequest, onSuccess } = payload;
  const email = values.email.toLowerCase().trim();
  try {
    const { success: status } = yield UserService.requestNewPassword(email);
    if (!status) {
      setErrorHttpRequest('Tu correo no se encuentra registrado en nuestra tienda');
      setSubmitting(false);
      return;
    }
    onSuccess(true);
  } catch (error) {
    setErrorHttpRequest('Tu correo no se encuentra registrado en nuestra tienda');
    setSubmitting(false);
  }
}

export function* changeUserType({ userInfo }) {
  try {
    if (userInfo.type === 'email') localStorage.setItem(EMAIL_REGISTER, true);
    const { success: status, data } = yield UserService.changeUserType(userInfo);

    if (!status) {
      yield put(UserCreators.userChangeTypeFailure({ status: 400 }));
      return;
    }

    if (data.access_token) {
      localStorage.setItem(TOKEN_KEY, data.access_token);
      yield put(UserCreators.getUserData());
      yield put(UserCreators.userDataUpdateSucceeded({ email: userInfo.email }));
      yield put(AuthCreators.signUpRequestSuccess());
    }
  } catch (error) {
    const errorStatus = getStatus(error);
    if (errorStatus === 409 || errorStatus === 500 || errorStatus === 400)
      yield put(UserCreators.userChangeTypeFailure({ status: 409 }));
  }
}
