import React from 'react';
import { Field } from 'formik';
import { FormControl, InputLabel, Select } from '@material-ui/core';

const InstallmentsSelect = ({ setFieldValue }) => (
  <>
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="installments-number">Número de cuotas</InputLabel>
      <Field name="installmentsNumber">
        {({ field }) => (
          <Select
            native
            label="Número de cuotas"
            id="installments-number"
            name="installmentsNumber"
            value={field.value}
            onChange={(e) => setFieldValue('installmentsNumber', e.target.value)}
          >
            <>
              <option value="1">1</option>
            </>
            {[...Array(23)].map((_, index) => (
              <option key={index + 2} value={index + 2}>
                {index + 2}
              </option>
            ))}
          </Select>
        )}
      </Field>
    </FormControl>
  </>
);

export { InstallmentsSelect };
