import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '@superfuds/saori';
import { MINIMUM_PSE_PAYMENT, PAYMENT__METHODS } from '@constants/index';
import { currencyFormatter } from '@utils/render';

import { ReactComponent as EnabledIcon } from '@assets/svg/statusIconEnabled.svg';
import { ReactComponent as DisabledIcon } from '@assets/svg/statusIconDisabled.svg';
import { ReactComponent as PSEIcon } from '@assets/svg/pse.svg';
import { ReactComponent as PSEDisabledIcon } from '@assets/svg/PseDisabled.svg';

import './styles.scss';

const PSEOption = ({ open, paymentMethod, isDisabled }) => {
  return (
    <Card
      className={`paymentOption ${
        paymentMethod?.method === PAYMENT__METHODS.PSE
          ? 'paymentOption-active'
          : 'paymentOption-whiteBg'
      } ${
        (paymentMethod?.method === PAYMENT__METHODS.RAPPI_PAY ||
          paymentMethod?.method === PAYMENT__METHODS.CASH) &&
        'paymentOption-whiteBg'
      }`}
    >
      <Grid container classes={{ root: 'paymentOption__dataContainer' }}>
        <Grid
          container
          justifyContent="flex-start"
          classes={{ root: 'paymentOption__paymentDetail' }}
        >
          {isDisabled ? (
            <PSEDisabledIcon
              id="PaymentMethodsPaymentOptionPSEPayPaymentMethodOptionPSEDisabledIcon"
              className="paymentOption__icon--disabled"
            />
          ) : (
            <PSEIcon
              id="PaymentMethodsPaymentOptionPSEPayPaymentMethodOptionPSEIcon"
              className="paymentOption__icon"
            />
          )}
          <Grid container direction="column" style={{ maxWidth: '80%' }}>
            {paymentMethod?.method === PAYMENT__METHODS.PSE && (
              <Typography
                classes={{
                  root: isDisabled
                    ? 'paymentOption__paymentDetail--disabled'
                    : 'paymentOption__paymentDetail',
                }}
              >
                Pago PSE
              </Typography>
            )}
            {!paymentMethod?.method || paymentMethod?.method !== PAYMENT__METHODS.PSE ? (
              <Typography
                classes={{
                  root: isDisabled ? 'paymentOption__label--disabled' : 'paymentOption__label',
                }}
              >
                Pago PSE
              </Typography>
            ) : null}
            {isDisabled && (
              <Typography component="span" classes={{ root: 'paymentOption__info' }}>
                Pago para compra mínima de {currencyFormatter(MINIMUM_PSE_PAYMENT)}
              </Typography>
            )}
          </Grid>
        </Grid>
        {(open || paymentMethod?.method === PAYMENT__METHODS.PSE) && !isDisabled && (
          <EnabledIcon
            id="PaymentMethodsPaymentOptionPSEPayPaymentMethodOptionEnabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
        {!open && paymentMethod?.method !== PAYMENT__METHODS.PSE && !isDisabled && (
          <DisabledIcon
            id="PaymentMethodsPaymentOptionPSEPayPaymentMethodOptionDisabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
      </Grid>
    </Card>
  );
};

PSEOption.propTypes = {
  open: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.shape({
    bank: PropTypes.string,
    documentNumber: PropTypes.string,
    documentType: PropTypes.string,
    email: PropTypes.string,
    cardName: PropTypes.string,
    cardNumber: PropTypes.string,
    cvv: PropTypes.string,
    date: PropTypes.string,
    method: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export { PSEOption };
