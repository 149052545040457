import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase } from '@material-ui/core';
import { ReactComponent as AddButton } from '@assets/svg/addButton.svg';
import { ReactComponent as ReduceButton } from '@assets/svg/reduceButton.svg';
import { ReactComponent as TrashIcon } from '@assets/svg/trash_icon.svg';

import './styles.scss';

const ProductCounter = ({
  onChange,
  className,
  defaultQuantity,
  disabled,
  minQty,
  source,
  totalStock,
  activeAlert,
}) => {
  const [Qty, setQty] = useState(defaultQuantity);

  const increase = (e) => {
    e.stopPropagation();
    setQty((quantity) => quantity + 1);
    onChange(Qty + 1);
  };

  const decrease = (e) => {
    e.stopPropagation();
    if (Qty > minQty) {
      setQty((quantity) => quantity - 1);
      onChange(Qty - 1);
    } else {
      setQty(1);
      onChange(0);
    }
  };

  return (
    <div className={`product-counter ${className}`}>
      {source === 'cart' &&
        (Qty > 1 ? (
          <ReduceButton
            onClick={decrease}
            width="24"
            className={`product-counter-button ${Qty <= minQty && 'product-counter-button--disabled'
              }`}
          />
        ) : (
          <TrashIcon onClick={decrease} width="24" />
        ))}
      {source !== 'cart' && (
        <ReduceButton
          onClick={decrease}
          className={`product-counter-button ${Qty <= minQty && 'product-counter-button--disabled'
            }`}
        />
      )}
      <InputBase
        value={Qty}
        onChange={(e) => setQty(e.target.value)}
        onBlur={(event) => {
          const value = parseInt(event.target.value, 10);
          if (!isNaN(value) && value > 0 && totalStock >= value) {
            onChange(value);
          } else {
            activeAlert();
          }
        }}
        error={totalStock <= Qty}
        className="product-quantity-input"
      />
      <AddButton
        onClick={increase}
        width="24"
        className={`product-counter-button ${totalStock <= Qty && 'product-counter-button--disabled'
          }`}
      />
    </div>
  );
};
ProductCounter.defaultProps = {
  disabled: false,
  defaultQuantity: 1,
  minQty: 1,
  className: '',
  source: null,
  totalStock: 0,
  activeAlert: () => { },
};

ProductCounter.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultQuantity: PropTypes.number,
  className: PropTypes.string,
  source: PropTypes.string,
  minQty: PropTypes.number,
  totalStock: PropTypes.number,
  activeAlert: PropTypes.func,
};

export { ProductCounter };
