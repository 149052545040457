import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { rootSaga } from '@redux/sagas';
import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { reducer as AuthReducer } from '@reducers/Auth';
import { reducer as BannersReducer } from '@reducers/Banners';
import { reducer as BrandsReducer } from '@reducers/Brands';
import { reducer as SalesReducer } from '@reducers/Sales';
import { reducer as DietsReducer } from '@reducers/Diets';
import { reducer as CategoriesReducer } from '@reducers/Categories';
import { reducer as LocationsReducer } from '@reducers/Locations';
import { reducer as OrdersReducer } from '@reducers/Orders';
import { reducer as ProductReducer } from '@reducers/Product';
import { reducer as ProductsReducer } from '@reducers/Products';
import { reducer as ProductDetailsReducer } from '@reducers/ProductDetails';
import { reducer as ProductNewReducer } from '@reducers/ProductNew';
import { reducer as UserReducer } from '@reducers/User';
import { reducer as StartupReducer } from '@reducers/Startup';
import { configureStore } from './configureStore';

export const history = createBrowserHistory();
/* ------------- Assemble The Reducers ------------- */
export const reducers = () =>
  combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    sales: SalesReducer,
    delivery: LocationsReducer,
    orders: OrdersReducer,
    banners: BannersReducer,
    brands: BrandsReducer,
    categories: CategoriesReducer,
    product: ProductReducer,
    products: ProductsReducer,
    productsDetails: ProductDetailsReducer,
    productNew: ProductNewReducer,
    startup: StartupReducer,
    diets: DietsReducer,
  });

export const createStore = () => {
  let finalReducers = reducers();
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (reduxPersistConfig.active) {
    const persistConfig = reduxPersistConfig.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers());
  }

  const storeData = configureStore(finalReducers, rootSaga);

  const { store, sagaMiddleware } = storeData;
  let { sagasManager } = storeData;
  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(reducers);

      // eslint-disable-next-line global-require
      const newYieldedSagas = require('../sagas').rootSaga;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }
  return store;
};
