import React from 'react';
import PropTypes from 'prop-types';

import styles from './subCategoriesSidebarLink.module.scss';

const SubCategoriesSidebarLink = ({ text, onClickHandler, isActive, id }) => {
  return (
    <li
      id={id}
      role="presentation"
      className={`${styles.subCategories__link} ${isActive ? styles.subCategories__active : ''}`}
      tabIndex={0}
      onClick={onClickHandler}
    >
      {text}
    </li>
  );
};

SubCategoriesSidebarLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export { SubCategoriesSidebarLink };
