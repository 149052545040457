import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Select,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { ReactComponent as HidePassword } from '@assets/svg/hidePassword.svg';
import { legalDocs } from '@constants/index';
import { formikConf, onsubmit } from './signup.formik';

import './style.scss';

export const EmailSignup = ({ onSubmit, isB2B, user, handleClick }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Grid item xs={10} className="email-signup">
      <Typography variant="h5" className="email-signup__title">
        Crear cuenta
      </Typography>
      <Formik {...formikConf(isB2B, user)} onSubmit={onsubmit(onSubmit)}>
        {({ errors, handleBlur, handleChange, isValid, touched }) => (
          <Form>
            <Grid container className="email-signup__fields">
              {isB2B && (
                <Grid item xs={12} className="email-signup__field">
                  <Field name="businessName">
                    {({ field }) => (
                      <TextField
                        label="Nombre de la empresa o negocio"
                        error={Boolean(errors.businessName && touched.businessName)}
                        variant="outlined"
                        name="businessName"
                        value={field.value}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.businessName && touched.businessName && String(errors.businessName)
                        }
                        style={{ width: '100%' }}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              {isB2B && (
                <Grid
                  container
                  item
                  xs={12}
                  className="email-signup__field"
                  justify="space-between"
                >
                  <div className="email-signup__document-number-field">
                    <Field name="documentNumber">
                      {({ field }) => (
                        <TextField
                          label="NIT"
                          error={Boolean(errors.documentNumber && touched.documentNumber)}
                          variant="outlined"
                          name="documentNumber"
                          value={field.value}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.documentNumber &&
                            touched.documentNumber &&
                            String(errors.documentNumber)
                          }
                          style={{ width: '100%' }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="email-signup__verification-digit-field">
                    <Field name="verificationDigit">
                      {({ field }) => (
                        <TextField
                          label="DV"
                          error={Boolean(errors.verificationDigit && touched.verificationDigit)}
                          variant="outlined"
                          name="verificationDigit"
                          value={field.value}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Field>
                  </div>
                </Grid>
              )}
              {!isB2B && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  className="email-signup__field"
                >
                  <Grid item xs={5} className="documentType-signup__field">
                    <Field name="documentType">
                      {({ field }) => (
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="documentType">Documento</InputLabel>
                          <Select
                            native
                            error={Boolean(errors.documentType && touched.documentType)}
                            name="documentType"
                            label="Documento"
                            value={field.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '100%' }}
                          >
                            <option aria-label="None" value="" />
                            <option value="13">CC</option>
                            <option value="22">CE</option>
                            <option value="41">Pasaporte</option>
                          </Select>
                          <FormHelperText>
                            {errors.documentType &&
                              touched.documentType &&
                              String(errors.documentType)}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={7} className="documentNumber-signup__field">
                    <Field name="documentNumber">
                      {({ field }) => (
                        <TextField
                          label="Numero Documento"
                          error={Boolean(errors.documentNumber && touched.documentNumber)}
                          variant="outlined"
                          name="documentNumber"
                          value={field.value}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.documentNumber &&
                            touched.documentNumber &&
                            String(errors.documentNumber)
                          }
                          style={{ width: '100%' }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                className="email-signup__field"
                wrap="nowrap"
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Nombre Completo"
                      error={Boolean(errors.name && touched.name)}
                      variant="outlined"
                      name="name"
                      value={field.value}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.name && touched.name && String(errors.name)}
                      style={{ width: '100%' }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} className="email-signup__field">
                <Field name="phone">
                  {({ field, form }) => (
                    <TextField
                      label="N° celular"
                      variant="outlined"
                      name="phone"
                      value={user.phone}
                      type="text"
                      disabled
                      style={{ width: '100%' }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} className="email-signup__field">
                <Field name="email">
                  {({ field }) => (
                    <TextField
                      label="Correo electrónico"
                      error={Boolean(errors.email && touched.email)}
                      variant="outlined"
                      name="email"
                      value={field.value}
                      type="email"
                      autoComplete=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email && String(errors.email)}
                      style={{ width: '100%' }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} className="email-signup__field">
                <Field name="password">
                  {({ field }) => (
                    <TextField
                      label="Contraseña"
                      error={Boolean(errors.password && touched.password)}
                      variant="outlined"
                      autoComplete=""
                      type={isPasswordVisible ? 'text' : 'password'}
                      name="password"
                      value={field.value}
                      helperText={errors.password && touched.password && String(errors.password)}
                      style={{ width: '100%' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <HidePassword
                            className="email-signup__password-btn"
                            onClick={() => setIsPasswordVisible((visible) => !visible)}
                            width="20px"
                            role="button"
                          />
                        ),
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <FormControlLabel
              classes={{ label: 'email-signup__accept-alert-field' }}
              control={<Field name="alert" component={Checkbox} type="checkbox" color="primary" />}
              label="Acepto recibir alertas en mi correo electrónico"
            />
            <Typography variant="caption" className="email-signup__policies">
              Al ingresar aceptas nuestros
            </Typography>
            <Typography variant="caption" className="email-signup__policies-link">
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleClick(legalDocs.PP);
                }}
                className="email-signup__policies-link"
              >
                términos y condiciones y políticas de privacidad
              </div>
            </Typography>
            <Button type="submit" className="form__button">
              Crear cuenta
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

EmailSignup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isB2B: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

EmailSignup.defaultProps = {
  isB2B: false,
};
