import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@superfuds/sf-app';
import { ReactComponent as SuperfudsLogoFull } from '@assets/svg/superfuds_full_logo.svg';
import { AboutSuperfuds, CustomerService, ContactInfo } from './components';

import './style.scss';

const getI18nText = (text) => i18n.getText(text);

export const Footer = ({ handleClick }) => {
  return (
    <footer>
      <div className="footer">
        <div className="col order-one">
          <div className="footer__logoDesktop">
            <SuperfudsLogoFull width="242" />
            <div style={{ marginTop: '24px' }} />
          </div>
          <div className="footer__storeLinks">
            <SuperfudsLogoFull width="242" />
          </div>
        </div>
        <div className="col order-three">
          <AboutSuperfuds getI18nText={getI18nText} />
        </div>
        <div className="col order-two">
          <CustomerService getI18nText={getI18nText} handleClick={handleClick} />
        </div>
        <div className="col order-fourth">
          <ContactInfo getI18nText={getI18nText} />
        </div>
      </div>
      <div className="logos">
        <div className="storeLinks">
          <SuperfudsLogoFull width="242" />
        </div>
      </div>
    </footer>
  );
};
Footer.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
