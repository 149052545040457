import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyA4pJjezIDD4zABq9fAVNUIniOkCEmNNPw',
  authDomain: 'superfuds-305815.firebaseapp.com',
  projectId: 'superfuds-305815',
  storageBucket: 'superfuds-305815.appspot.com',
  messagingSenderId: '98511649972',
  appId: '1:98511649972:web:9f3a07fde0298e5379ebe1',
  measurementId: 'G-T80Z78SCTG',
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BMejg6GAK0fLp6NlVOW_ZqnTCt6aklodtOLrrArzYHHMTlXQOkg-zKcMPY1aGW7PZxxWw-eJZR94FU_LGjmpSiw',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('payload', payload);
      resolve(payload);
    });
  });
