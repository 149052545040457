import * as Sentry from '@sentry/react';

export const identifySentryUser = (userData) => {
  try {
    Sentry.setUser({
      email: userData.email,
      id: userData.eid,
      businessType: userData.clientType === 1 ? 'B2B' : 'B2C',
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const logoutSentry = () => {
  try {
    Sentry.setUser(null);
  } catch (e) {
    Sentry.captureException(e);
  }
};
