import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Select, FormControl, InputLabel, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutline from '@material-ui/icons/HelpOutline';
import {
  PAYMENT__METHODS,
  MINIMUM_PSE_PAYMENT,
  MINIMUM_TC_PAYMENT,
  // MINIMUM_CASH_PAYMENT,
  MINIMUM_ZIRO_PAYMENT,
  DEMO_USER,
} from '@constants/index';
import { PSEOption, TCOption, TCOptionSave, ZiroOption } from './PaymentOption';
import { PSEForm } from './PSEForm';
import { CreditCardForm } from './CreditCartForm';
import './styles.scss';

const PaymentMethods = ({
  user,
  paymentMethod,
  handlePaymentMethod,
  cleanPaymentMethod,
  total,
  listBank,
  listCard,
  handleTCdelete,
}) => {
  const [togglePSEForm, setTogglePSEForm] = useState(false);
  const [toggleTCForm, setToggleTCForm] = useState(false);
  const [toggleCashPayment, setToggleCashPayment] = useState(false);
  const [canChangePaymentMethod, setCanChangePaymentMethod] = useState(false);
  const [PSEPayment, setPSEPayment] = useState(null);
  const [ZiroPayment, setZiroPayment] = useState(null);
  const [togglecardSave, setToggleCardSave] = useState(false);
  const handleWarningPSEPayment = (values) => {
    setPSEPayment(values);
  };

  useEffect(() => {
    if (canChangePaymentMethod && PSEPayment !== null) {
      handlePaymentMethod(PSEPayment);
      setPSEPayment(null);
      setTogglePSEForm(false);
      setToggleCardSave(false);
      setCanChangePaymentMethod(false);
      ZiroPayment(false);
    }
  }, [canChangePaymentMethod, PSEPayment, handlePaymentMethod, ZiroPayment]);

  const handlePaymentButtonClick = () => {
    if (togglePSEForm) setTogglePSEForm((c) => !c);
    if (toggleTCForm) setToggleTCForm((c) => !c);
    if (ZiroPayment) setZiroPayment((c) => !c);
    if (toggleCashPayment) setToggleCashPayment((c) => !c);
    if (togglecardSave) setToggleCardSave((c) => !c);
  };

  return (
    <div>
      <Grid container classes={{ root: 'paymentMethods__dataContainer' }}>
        <Grid
          key={user.id}
          classes={{
            root:
              paymentMethod?.method === PAYMENT__METHODS.TC
                ? 'paymentMethods__active'
                : 'paymentMethods__paymentBoxTC',
          }}
        >
          {listCard.length > 0 &&
            listCard?.map((item) => {
              return (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={togglecardSave ? 8 : 10}
                    onClick={() => {
                      if (total >= MINIMUM_TC_PAYMENT) {
                        handlePaymentButtonClick();
                        handlePaymentMethod({ method: PAYMENT__METHODS.TCSUBSCRIPTION, ...item });
                        setToggleCardSave((c) => !c);
                      }
                    }}
                  >
                    <TCOptionSave
                      open={togglecardSave}
                      paymentMethod={{
                        ...item,
                        active: togglecardSave,
                      }}
                      isDisabled={total < MINIMUM_TC_PAYMENT}
                    />
                  </Grid>
                  {togglecardSave && (
                    <Grid item xs={4}>
                      <FormControl variant="outlined">
                        <InputLabel>Número de cuotas</InputLabel>
                        <Select
                          native
                          label="Número de cuotas"
                          id="installments-number"
                          onChange={(e) => {
                            handlePaymentMethod({
                              method: PAYMENT__METHODS.TCSUBSCRIPTION,
                              ...item,
                              installmentsNumber: e.target.value,
                            });
                          }}
                        >
                          <option value="1">Número de cuotas</option>
                          {[...Array(24)].map((_, index) => (
                            <option key={index + 1} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {!togglecardSave && (
                    <Grid item xs={2}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          handleTCdelete(item.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          alignItems="center"
          classes={{
            root:
              paymentMethod?.method === PAYMENT__METHODS.ZIRO
                ? 'paymentMethods__active'
                : 'paymentMethods__paymentBoxTC',
          }}
        >
          <Grid item xs={11}>
            <Grid
              container
              onClick={() => {
                if (total >= MINIMUM_ZIRO_PAYMENT) {
                  handlePaymentMethod({ method: '' });
                  handlePaymentButtonClick();
                  handlePaymentMethod({ method: PAYMENT__METHODS.ZIRO });
                  setZiroPayment((c) => !c);
                }
              }}
              alignItems="center"
            >
              <ZiroOption
                open={ZiroPayment}
                paymentMethod={paymentMethod}
                isDisabled={total < MINIMUM_ZIRO_PAYMENT}
              />
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="¿Que es Zíro?">
              <a href="https://somosziro.com/" target="_blank" rel="noopener noreferrer">
                <IconButton color="primary">
                  <HelpOutline />
                </IconButton>
              </a>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          classes={{
            root:
              paymentMethod?.method === PAYMENT__METHODS.TC
                ? 'paymentMethods__active'
                : 'paymentMethods__paymentBoxTC',
          }}
        >
          <Grid
            container
            onClick={() => {
              if (total >= MINIMUM_TC_PAYMENT) {
                handlePaymentButtonClick();
                handlePaymentMethod({ method: '' });
                setToggleTCForm((c) => !c);
              }
            }}
          >
            <TCOption
              open={toggleTCForm}
              paymentMethod={paymentMethod}
              isDisabled={
                total < MINIMUM_TC_PAYMENT || localStorage.getItem('DEMO_USER') === DEMO_USER
              }
            />
          </Grid>
          {toggleTCForm && (
            <CreditCardForm
              paymentMethod={paymentMethod}
              handlePaymentMethod={handlePaymentMethod}
              cleanPaymentMethod={cleanPaymentMethod}
              toggleForm={() => setToggleTCForm((c) => !c)}
            />
          )}
        </Grid>
        <Grid
          classes={{
            root:
              paymentMethod?.method === PAYMENT__METHODS.PSE
                ? 'paymentMethods__active'
                : 'paymentMethods__paymentBoxPSE',
          }}
        >
          <Grid
            container
            onClick={() => {
              if (total >= MINIMUM_PSE_PAYMENT) {
                handlePaymentButtonClick();
                handlePaymentMethod({ method: '' });
                setTogglePSEForm((c) => !c);
              }
            }}
          >
            <PSEOption
              open={togglePSEForm}
              paymentMethod={paymentMethod}
              isDisabled={
                total < MINIMUM_TC_PAYMENT || localStorage.getItem('DEMO_USER') === DEMO_USER
              }
            />
          </Grid>
          {togglePSEForm && (
            <PSEForm
              paymentMethod={paymentMethod}
              cleanPaymentMethod={cleanPaymentMethod}
              user={user}
              listBank={listBank}
              handlePaymentMethod={handlePaymentMethod}
              handleWarningPSEPayment={handleWarningPSEPayment}
              toggleForm={() => setTogglePSEForm((c) => !c)}
            />
          )}
        </Grid>

        {/* <Grid
          classes={{
            root:
              paymentMethod?.method === PAYMENT__METHODS.CASH
                ? 'paymentMethods__active'
                : 'paymentMethods__paymentBoxCash',
          }}
        >
          <Grid
            container
            onClick={() => {
              if (total >= MINIMUM_CASH_PAYMENT) {
                handlePaymentMethod({ method: '' });
                setToggleCashPayment((c) => !c);
                handlePaymentButtonClick();
                handlePaymentMethod({ method: PAYMENT__METHODS.CASH });
              }
            }}
          >
            <CashOption
              open={toggleCashPayment}
              paymentMethod={paymentMethod}
              isDisabled={
                total < MINIMUM_CASH_PAYMENT || localStorage.getItem('DEMO_USER') === DEMO_USER
              }
            />
          </Grid>
        </Grid> */}
      </Grid>

      {/*      {user?.creditEnable && (
        <Grid
          style={{
            marginTop: 30,
          }}
          classes={{
            root:
              paymentMethod?.method === PAYMENT__METHODS.TC
                ? 'paymentMethods__active'
                : 'paymentMethods__paymentBoxTC text-left',
          }}
          onClick={handlePayWithCreditSF}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: 'inherit',
            }}
          >
            Pagar con Credito Superfuds
          </Button>
        </Grid>
      )} */}
    </div>
  );
};
PaymentMethods.defaultProps = {
  listCard: [],
  handleTCdelete: () => { },
};

PaymentMethods.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    clientType: PropTypes.number.isRequired,
  }).isRequired,
  paymentMethod: PropTypes.shape({
    bank: PropTypes.string,
    documentNumber: PropTypes.string,
    documentType: PropTypes.string,
    email: PropTypes.string,
    cardName: PropTypes.string,
    cardNumber: PropTypes.string,
    cvv: PropTypes.string,
    date: PropTypes.string,
    method: PropTypes.string,
  }).isRequired,
  listBank: PropTypes.shape({}).isRequired,
  listCard: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number.isRequired,
  cleanPaymentMethod: PropTypes.func.isRequired,
  handlePaymentMethod: PropTypes.func.isRequired,
  handleTCdelete: PropTypes.func,
};

export { PaymentMethods };
