import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '@superfuds/saori';

import { ReactComponent as LocationIcon } from '@assets/svg/locationIcon.svg';

import './styles.scss';

const ShippingInfo = ({ location, id, user }) => {
  return (
    <Card className="shippingInfo mb-2" id={id}>
      <Grid container xs={12} md={8} classes={{ root: 'shippingInfo__dataContainer' }}>
        <Grid item>
          <Typography variant="h5" className="text-left">
            <LocationIcon style={{ paddingTop: 5 }} />
          </Typography>
        </Grid>
        <Grid item xs={10} md={12} style={{ marginLeft: 10 }} className="text-left">
          <Typography classes={{ root: 'shippingInfo__addressLabel' }}>
            Enviar a {user?.name}
          </Typography>
          <Typography classes={{ root: 'shippingInfo__addressLabel' }}>
            {location?.userAddress ?? ''} {location?.userAddressName ?? ''}
          </Typography>
          <Typography classes={{ root: 'shippingInfo__cityLabel' }}>
            <strong>{location?.userCity ?? ''}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

ShippingInfo.defaultProps = {
  location: {
    userCity: null,
    userAddress: null,
    userAddressName: null,
  },
};

ShippingInfo.propTypes = {
  location: PropTypes.shape({
    userCity: PropTypes.string,
    userAddress: PropTypes.string,
    userAddressName: PropTypes.string,
  }),
  user: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
};

export { ShippingInfo };
