import { Fragment, createElement } from 'react';
import { parseAlgoliaHitHighlight } from '@algolia/autocomplete-preset-algolia';

export const Highlight = ({ hit, attribute, tagName = 'mark' }) =>
  createElement(
    Fragment,
    {},
    parseAlgoliaHitHighlight({ hit, attribute }).map(({ value, isHighlighted }, index) => {
      if (isHighlighted) {
        return createElement(tagName, { key: index }, value);
      }

      return value;
    }),
  );
