/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  items: [],
  isLoading: true,
  currentPage: null,
  hasMore: true,
});
/* ------------- Reducers ------------- */
export const productNewRequest = (state = INITIAL_STATE, { clean }) => {
  return {
    ...state,
    items: clean ? [] : state.items,
    currentPage: clean ? null : state.currentPage,
    isLoading: true,
  };
};
export const productNewSuccess = (state = INITIAL_STATE, { items }) => {
  return {
    ...state,
    items: [...state.items, ...items.data],
    currentPage: items.currentPage,
    hasMore: items.hasMore,
    isLoading: false,
  };
};
export const reset = () => INITIAL_STATE;
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCT_NEW_REQUEST]: productNewRequest,
  [Types.PRODUCT_NEW_SUCCESS]: productNewSuccess,
  [Types.RESET]: reset,
});
