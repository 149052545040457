import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { UserType } from '@constants/odoo.constants';
import { Creators as UserCreators } from '@reducers/User';
import { EmailSignup } from '@components/molecules';

import './style.scss';

export const EmailAuth = ({ clientType, handleClick, user }) => {
  const dispatch = useDispatch();
  return (
    <Grid container justifyContent="center">
      <EmailSignup
        user={user}
        handleClick={handleClick}
        isB2B={clientType === UserType.B2B}
        onSubmit={(values) => {
          const user = {
            ...values,
            name: values.name.toUpperCase(),
            company_name: values.name.toUpperCase(),
            verification_digit: values.verificationDigit,
            document: values.documentNumber.toString(),
          };
          dispatch(UserCreators.updateUserProfile(user));
        }}
      />
    </Grid>
  );
};

EmailAuth.propTypes = {
  clientType: PropTypes.oneOf([UserType.B2C, UserType.B2B]).isRequired,
  handleClick: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};
