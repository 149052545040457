import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import { useDispatch } from 'react-redux';
import { history } from '@redux/store';
import { Creators as ShoppingCartCreators } from '@reducers/Sales';
import { currencyFormatter } from '@utils/render';
import { MinimumOrder } from '@components/index';

import './style.scss';

export const ResumeOrder = ({ shoppingCart, user, show, applayCoupon }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [cupon, setCupon] = useState('');

  let valueWillPickUp = '';
  if (shoppingCart?.willPickUpInWarehouse) {
    valueWillPickUp = 'IN_WAREHOUSE';
  } else if (shoppingCart?.willPickUpInOffice) {
    valueWillPickUp = 'IN_OFFICE';
  } else {
    valueWillPickUp = '';
  }

  const [value, setValue] = useState('' || valueWillPickUp);
  const handleRadioChange = (event) => {
    setValue(event.target.value);
    dispatch(ShoppingCartCreators.setWillPickUpInWarehouse(event.target.value));
    if (event.target.value === 'NOT_PICK_UP') {
      setValue('');
    }
  };
  const handleIrpaid = () => {
    /*    const AMOUNT =
         user?.email?.endsWith('@superfuds.com.co') || user?.email?.endsWith('@somospurpose.com')
           ? 0
           : 250000; */
    /*   if (shoppingCart?.grandTotal >= AMOUNT) { */
    if (user.documentNumber === false && user.name === '' && user.email === '') {
      history.push('/register');
    } else {
      history.push('/checkout');
    }
    /*   } else {
        setOpen(true);
      } */
  };

  return (
    <Grid item container xs={12} className="resumenOrder">
      <MinimumOrder open={false} closeModal={() => setOpen(!open)} />
      <Grid xs={12} item className="container pt-3">
        <Typography variant="h5" className="text-center pb-2">
          Resumen de tu pedido
        </Typography>

        <Grid item xs={12} className="text-left">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                paddingLeft: 10,
              }}
            >
              # Pedido <strong>{shoppingCart.sfId}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Subtotal <strong>{currencyFormatter(shoppingCart?.totalWithoutTaxes)}</strong>
            </Typography>
          </Grid>
          {shoppingCart?.taxes > 0 && (
            <Grid item xs={12}>
              <Typography>
                IVA <strong>{currencyFormatter(shoppingCart?.taxes)}</strong>
              </Typography>
            </Grid>
          )}
          {Math.abs(shoppingCart?.discountInMoney) > 0 && (
            <Grid item xs={12}>
              <Typography color="primary">
                Descuento{' '}
                <strong> -{currencyFormatter(Math.abs(shoppingCart?.discountInMoney))}</strong>
              </Typography>
            </Grid>
          )}
          {shoppingCart?.serviceCost > 0 && (
            <Grid item xs={12} container>
              <Tooltip
                title="Costo servicio aplica para los envíos por Superfuds"
                arrow
                placement="top"
              >
                <Typography
                  style={{
                    position: 'relative',
                  }}
                >
                  Costo servicio <strong>{currencyFormatter(shoppingCart?.serviceCost)}</strong>
                  <HelpIcon
                    width="5"
                    style={{
                      fill: '#25c16a',
                      position: 'absolute',
                      top: 0,
                      marginLeft: 10,
                    }}
                  />
                </Typography>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>
              Total <strong>{currencyFormatter(shoppingCart?.grandTotal)}</strong>
            </Typography>
          </Grid>
          {location.pathname === '/checkout' && (
            <Grid item xs={12} className="text-left mt-4">
              <Typography>
                <strong>Ingresa el código del cupón aquí</strong>
              </Typography>
              <Grid
                item
                container
                className="mt-2 mb-4"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={9} className="pl-2 pr-5">
                  <TextField
                    id="standard-basic"
                    label=""
                    value={cupon}
                    fullWidth
                    onChange={(e) => setCupon(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    disabled={!cupon}
                    className="button"
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      applayCoupon(cupon?.trim());
                      setCupon('');
                    }}
                  >
                    Aplicar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {location.pathname === '/cart' && user?.email?.endsWith('@superfuds.com.co') && (
            <Grid item xs={12}>
              <RadioGroup aria-label="quiz" value={value} onChange={handleRadioChange}>
                <FormControlLabel
                  value="IN_WAREHOUSE"
                  control={<Radio color="primary" />}
                  label="Recoger en Bodega"
                />
                {value && (
                  <FormControlLabel
                    value="NOT_PICK_UP"
                    control={<Radio color="primary" />}
                    label="Enviar a mi Ubicación"
                  />
                )}
              </RadioGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            {value === 'IN_WAREHOUSE' && (
              <Typography variant="body2" className="infoPickUp">
                Calle 18a # 69f - 56 <br /> <strong>Bogotá-Colombia</strong>
              </Typography>
            )}
            {/*  {value === 'IN_OFFICE' && (
              <Typography variant="body2" className="infoPickUp">
                Carrera 11 # 92 - 20 <br /> <strong>Bogotá-Colombia</strong>
              </Typography>
            )} */}
            {value === 'IN_WAREHOUSE' && (
              <Typography variant="body2" className="infoPickUp">
                Confirmada tu compra puedes pasar por nuestra bodega al siguiente día
              </Typography>
            )}
          </Grid>
        </Grid>

        {show && (
          <Grid xs={12} className="container pt-3">
            <Grid className="text-center pt-3 pb-3">
              <Button className="button" onClick={handleIrpaid} variant="contained">
                Ir a Pagar
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ResumeOrder.defaultProps = {
  user: {},
  show: false,
};

ResumeOrder.propTypes = {
  shoppingCart: PropTypes.shape({}).isRequired,
  show: PropTypes.bool,
  user: PropTypes.shape({}),
};
