/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionTypes';

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  orders: [],
  seasons: [],
  loading: false,
  error: null,
  isOpenExperienceUserModal: false,
});

/* ------------- Reducers ------------- */
export const ordersRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

export const ordersSuccess = (state = INITIAL_STATE, { orders }) => {
  return {
    ...state,
    orders,
    error: null,
    loading: false,
  };
};

/* ------------------ Seasons -------------*/
export const ordersSeasonRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

export const ordersSeasonSuccess = (state = INITIAL_STATE, { seasons }) => {
  return {
    ...state,
    seasons,
    error: null,
    loading: false,
  };
};

/* ------------------ Experience Rating -------------*/
export const ordersExperienceRequest = (state = INITIAL_STATE, { orderId, body }) => {
  return {
    ...state,
    loading: true,
  };
};

export const openExperienceUserModalRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isOpenExperienceUserModal: true,
  };
};

export const closeExperienceUserModalRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isOpenExperienceUserModal: false,
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ORDERS_REQUEST]: ordersRequest,
  [Types.ORDERS_SUCCESS]: ordersSuccess,
  [Types.ORDERS_SEASON_REQUEST]: ordersSeasonRequest,
  [Types.ORDERS_SEASON_SUCCESS]: ordersSeasonSuccess,
  [Types.ORDERS_EXPERIENCE_REQUEST]: ordersExperienceRequest,
  [Types.OPEN_EXPERIENCE_USER_MODAL_REQUEST]: openExperienceUserModalRequest,
  [Types.CLOSE_EXPERIENCE_USER_MODAL_REQUEST]: closeExperienceUserModalRequest,
});
