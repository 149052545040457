// eslint-disable-next-line react/prop-types
import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import menuIcon from '@assets/svg/menu_icon.svg';
import closeIcon from '@assets/svg/close_icon_ligth.svg';

import './style.scss';

const MenuButton = ({ onClick, drawerIsOpen }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <div className={`header-menu-button ${!drawerIsOpen ? 'open' : 'close'}`} onClick={handleClick}>
      {!drawerIsOpen ? <MenuIconSvg isOpen={drawerIsOpen} /> : <XIconSvg isOpen={drawerIsOpen} />}
    </div>
  );
};

const MenuIconSvg = ({ isOpen }) => (
  <SVG
    id="MenuButtonMenuIconSvg"
    className={`svg ${isOpen ? 'svg-opaque-grey' : ''}`}
    src={menuIcon}
  />
);

const XIconSvg = ({ isOpen }) => (
  <SVG
    id="MenuButtonMenuIconSvg"
    className={`svg ${!isOpen ? 'svg-primary-color' : ''}`}
    src={closeIcon}
  />
);
MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  drawerIsOpen: PropTypes.bool.isRequired,
};

export { MenuButton };
