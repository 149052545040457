import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const ContactInfo = ({ getI18nText }) => (
  <div className="footer-contact-info">
    <Typography variant="subtitle1" className="subtitle pb-1 title">
      {getI18nText('footer.contact.title')}
    </Typography>
    <Typography variant="body2" className="contentText text-margin">
      {getI18nText('footer.contact.link.option_4')}
    </Typography>
    <Typography variant="body2" className="contentText text-margin">
      {getI18nText('footer.contact.link.option_1')}
    </Typography>
    <Typography variant="body2" className="contentText text-margin">
      {getI18nText('footer.contact.link.option_5')}
    </Typography>
  </div>
);

ContactInfo.defaultProps = {
  getI18nText: () => { },
};

ContactInfo.propTypes = {
  getI18nText: PropTypes.func,
};

export { ContactInfo };
