import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { CreditCard } from '@superfuds/saori';
import { TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';

import { InstallmentsSelect } from '@components/index';
import {
  validateCardNumber,
  validateName,
  validateCardDate,
  validateCVV,
  getPaymentMethod,
} from '@utils/index';

import { setInitialValues } from './credit-card.formik';

import './styles.scss';

export const CreditCardForm = ({
  toggleForm,
  paymentMethod,
  handlePaymentMethod,
  cleanPaymentMethod,
}) => {
  return (
    <div className="credit-card-form">
      <Formik
        onSubmit={(values) => {
          cleanPaymentMethod();
          handlePaymentMethod({
            ...values,
            method: 'TC',
            paymentMethod: getPaymentMethod(values.cardNumber.replace(/\s/g, '')),
          });
          toggleForm();
        }}
        initialValues={setInitialValues(paymentMethod)}
      >
        {({ errors, values, setFieldValue, handleBlur, touched, dirty }) => (
          <Form>
            <div className="form__card">
              <CreditCard
                number={values.cardNumber || ''}
                name={values.cardName || ''}
                expireDate={values.date || ''}
                cvc={values.cvv ? new Array(values.cvv.length + 1).join('*') : ''}
                focus=""
              />
            </div>
            <div className="form__container">
              <div className="form__cardNumber">
                <Field
                  id="PaymentMethodsCreditCardFormCardNumberField"
                  name="cardNumber"
                  validate={validateCardNumber}
                >
                  {({ field }) => (
                    <TextField
                      id="PaymentMethodsCreditCardFormCardNumberTextField"
                      size="small"
                      error={Boolean(errors.cardNumber) && touched.cardNumber}
                      label="Número de tarjeta"
                      variant="outlined"
                      name="cardNumber"
                      inputMode="numeric"
                      value={field.value}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let masked = e.target.value.replace(/([\d]{4})(?=\d)/g, '$1 ').slice(0, 19);
                        if (
                          e.target.value.slice(0, 2) === '34' ||
                          e.target.value.slice(0, 2) === '37'
                        ) {
                          masked = e.target.value
                            .replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1 $2 $3')
                            .slice(0, 17);
                        }
                        setFieldValue('cardNumber', masked);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="form__cardName">
                <Field
                  id="PaymentMethodsCreditCardFormCardNameField"
                  name="cardName"
                  validate={validateName}
                >
                  {({ field }) => (
                    <TextField
                      id="PaymentMethodsCreditCardFormCardNameTextField"
                      size="small"
                      error={Boolean(errors.cardName) && touched.cardName}
                      label="Nombre impreso en la tarjeta"
                      variant="outlined"
                      name="cardName"
                      type="text"
                      value={field.value}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(
                          'cardName',
                          e.target.value.toUpperCase().replace(/[0-9]/g, ''),
                        );
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="form__group">
                <div className="form__expiry">
                  <Field
                    id="PaymentMethodsCreditCardFormCardDateField"
                    name="date"
                    validate={validateCardDate}
                  >
                    {({ field }) => (
                      <TextField
                        id="PaymentMethodsCreditCardFormCardDateTextField"
                        size="small"
                        error={Boolean(errors.date) && touched.date}
                        label="Fecha vencimiento"
                        placeholder="00/00"
                        variant="outlined"
                        name="date"
                        inputMode="numeric"
                        value={field.value}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const masked = e.target.value
                            .replace(/(\d{2})(?=\d)/g, '$1/')
                            .slice(0, 5);
                          setFieldValue('date', masked);
                        }}
                      />
                    )}
                  </Field>
                </div>
                <div className="form__cvv">
                  <Field
                    id="PaymentMethodsCreditCardFormCardCVVField"
                    name="cvv"
                    validate={validateCVV}
                  >
                    {({ field }) => (
                      <TextField
                        id="PaymentMethodsCreditCardFormCardCVVTextField"
                        size="small"
                        error={Boolean(errors.cvv) && touched.cvv}
                        label="CCV"
                        variant="outlined"
                        name="cvv"
                        type="password"
                        value={field.value}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue('cvv', e.target.value.slice(0, 4))}
                      />
                    )}
                  </Field>
                </div>
                <div
                  className="form__installmentsNumber"
                  style={{
                    paddingLeft: '30px',
                    paddingRight: '15px',
                  }}
                >
                  <InstallmentsSelect setFieldValue={setFieldValue} />
                </div>
              </div>
              <div className="form__SaveCard text-left">
                <Field name="saveCard">
                  {({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(field.value)}
                          onChange={(e) => setFieldValue('saveCard', e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Guardar esta tarjeta para compras futuras"
                    />
                  )}
                </Field>
              </div>
              <Button
                id="PaymentMethodsCreditCardFormCardContinueButton"
                onClick={() => window.scroll(0, 0)}
                type="submit"
                className={
                  dirty && !errors.cardNumber && !errors.cardName && !errors.date && !errors.cvv
                    ? 'form__button--tc'
                    : 'form__button--disabled'
                }
                disabled={
                  !dirty || errors.cardNumber || errors.cardName || errors.date || errors.cvv
                }
              >
                Agregar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreditCardForm.propTypes = {
  toggleForm: PropTypes.func.isRequired,
  paymentMethod: PropTypes.shape({
    bank: PropTypes.string,
    documentNumber: PropTypes.string,
    documentType: PropTypes.string,
    email: PropTypes.string,
    cardName: PropTypes.string,
    cardNumber: PropTypes.string,
    cvv: PropTypes.string,
    date: PropTypes.string,
    method: PropTypes.string,
  }).isRequired,
  cleanPaymentMethod: PropTypes.func.isRequired,
  handlePaymentMethod: PropTypes.func.isRequired,
};
