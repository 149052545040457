/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { DEPARTMENT_ID } from '@constants/config.constants';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  isOpenLocationModal: false,
  isOpenGoogleMapsModal: false,
  isOpenSuperFastCobertureModal: false,
  showAddressConfirmUI: false,
  changeForceLocation: false,
  data: null,
  isEnabledBack: true,
  departmentId: '',
  userLocations: [],
  anonLocations: [],
  willPickUpInWarehouse: false,
  isLocationSaved: null,
  isLocationLoading: false,
  filterCity: null,
  enableEditMode: {
    enable: false,
    addressId: null,
  },
});

/* ------------- Reducers ------------- */
const openLocationModal = (state = INITIAL_STATE) => {
  return { ...state, isOpenLocationModal: true, isLocationSaved: null };
};

const closeLocationModal = (state = INITIAL_STATE) => {
  return { ...state, isOpenLocationModal: false };
};
/* New Reducers to handle geolocation */

export const toggleGoogleMapsModal = (state = INITIAL_STATE, { payload }) => {
  const isEnabledBack = payload ? payload.isEnabledBack : false;
  const isOpenGoogleMapsModal = !state.isOpenGoogleMapsModal;
  return { ...state, isOpenGoogleMapsModal, isEnabledBack };
};

export const enableUserAddressEditMode = (state = INITIAL_STATE, { location }) => {
  return { ...state, enableEditMode: { enable: true, addressId: location } };
};

export const disableUserAddressEditMode = (state = INITIAL_STATE) => {
  return { ...state, enableEditMode: { enable: false, addressId: null } };
};

export const resetIsLocationSaved = (state = INITIAL_STATE) => {
  return { ...state, isLocationSaved: null };
};

export const resetAnonLocationsRequest = (state = INITIAL_STATE) => {
  localStorage.removeItem('anonLocations');
  return { ...state, anonLocations: [] };
};

export const updatedAnonLocationsSuccess = (state = INITIAL_STATE, { locations }) => {
  return {
    ...state,
    anonLocations: locations.newAnonLocations,
    isLocationSaved: locations.isLocationSaved,
  };
};

/* End */

const setDepartmentId = (state = INITIAL_STATE, action) => {
  return { ...state, departmentId: action.departmentId };
};

const addUserLocationRequest = (state = INITIAL_STATE, action) => {
  return { ...state, isLocationSaved: null, isLocationLoading: true };
};

const updateUserLocation = (state = INITIAL_STATE, action) => {
  return { ...state, isLocationSaved: null, isLocationLoading: true };
};

const userLocationFetchSucceeded = (state, { payload }) => {
  const isSelectedLocation = payload.userLocations.find((location) => location.isSelected);
  if (isSelectedLocation) {
    localStorage.setItem(DEPARTMENT_ID, isSelectedLocation.department.id);
  }
  return {
    ...state,
    userLocations: payload.userLocations,
    isLocationSaved: payload?.islocationSaved ?? null,
    isLocationLoading: false,
  };
};

const anonLocationFetchSucceeded = (state, { anonLocations }) => {
  return { ...state, anonLocations, isLocationSaved: true, isLocationLoading: false };
};

const setWillPickUpInWarehouse = (state, { willPickUpInWarehouse }) => {
  return { ...state, willPickUpInWarehouse };
};

export const addFilterCity = (state, { city }) => {
  return { ...state, filterCity: city };
};

export const clearFilterCity = (state) => {
  return { ...state, filterCity: null };
};

export const startLoading = (state) => {
  return { ...state, isLocationLoading: true };
};

export const stopLoading = (state) => {
  return { ...state, isLocationLoading: false };
};

export const openSuperFastModalRequest = (state, { payload }) => {
  return {
    ...state,
    hasAliade: payload.hasAliade,
    lossOfItemsIfChange: payload.lossOfItemsIfChange,
    isOpenSuperFastCobertureModal: true,
    changeForceLocation: payload.changeForceLocation ?? null,
    showAddressConfirmUI: payload.confirmAddress ?? null,
    data: payload.data,
    loadingLocations: false,
  };
};

export const closeSuperFastModalRequest = (state) => {
  return {
    ...state,
    isOpenSuperFastCobertureModal: false,
    changeForceLocation: false,
    showAddressConfirmUI: false,
    data: null,
  };
};

const reset = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DEPARTMENT_ID]: setDepartmentId,
  [Types.ADD_USER_LOCATION]: addUserLocationRequest,
  [Types.UPDATE_USER_LOCATION]: updateUserLocation,
  [Types.USER_LOCATION_FETCH_SUCCEEDED]: userLocationFetchSucceeded,
  [Types.ANON_LOCATION_FETCH_SUCCEEDED]: anonLocationFetchSucceeded,
  [Types.OPEN_LOCATION_MODAL]: openLocationModal,
  [Types.CLOSE_LOCATION_MODAL]: closeLocationModal,
  [Types.SET_WILL_PICK_UP_IN_WAREHOUSE]: setWillPickUpInWarehouse,
  [Types.ADD_FILTER_CITY]: addFilterCity,
  [Types.CLEAR_FILTER_CITY]: clearFilterCity,
  [Types.START_LOADING]: startLoading,
  [Types.STOP_LOADING]: stopLoading,
  [Types.TOGGLE_GOOGLE_MAPS_MODAL]: toggleGoogleMapsModal,
  [Types.ENABLE_USER_ADDRESS_EDIT_MODE]: enableUserAddressEditMode,
  [Types.DISABLE_USER_ADDRESS_EDIT_MODE]: disableUserAddressEditMode,
  [Types.RESET_IS_LOCATION_SAVED]: resetIsLocationSaved,
  [Types.RESET_ANON_LOCATIONS_REQUEST]: resetAnonLocationsRequest,
  [Types.UPDATED_ANON_LOCATIONS_SUCCESS]: updatedAnonLocationsSuccess,
  [Types.OPEN_SUPER_FAST_MODAL_REQUEST]: openSuperFastModalRequest,
  [Types.CLOSE_SUPER_FAST_MODAL_REQUEST]: closeSuperFastModalRequest,
  [Types.RESET]: reset,
});
