import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like productNewRequest -> PRODUCT_NEW_REQUEST
 */
export const { Types, Creators } = createActions({
  productNewRequest: ['clean'],
  productNewSuccess: ['items'],
  reset: null,
});
