// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      let swUrl = `../service-worker.js`;
      if (window.location.href.indexOf('-dev') > -1) {
        const urlParams = new URLSearchParams(window.location.search);
        const uiVersion = urlParams.get('ui_version');
        swUrl = `/service-worker.js?ui_version=${uiVersion}`;
      }
      registerValidSW(swUrl);
    });
  }
}

async function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log('New content is available; please refresh.');
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });

  if (!('Notification' in window) || !('serviceWorker' in navigator)) {
    console.log('Tu browser no soporta notificaciones');
  }
  if (Notification.permission === 'default') {
    Notification.requestPermission();
  }

  if (Notification.permission === 'blocked') {
    console.log('Bloqueaste las notificaciones :(');
  }

  if (Notification.permission !== 'granted') {
    console.log('Bloqueaste las notificaciones :( granted');
  }
  Notification.requestPermission((result) => {
    if (result === 'granted') {
      /*  navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification('Hola Saludo', {
          body: 'Buzz! Buzz!',
        });
      }); */
    }
  });
  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      console.log('This page was restored from the bfcache.');
    } else {
      console.log('This page was loaded normally.');
    }
  });
}
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
