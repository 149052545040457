import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like productSuggestedRequest -> PRODUCT_SUGGESTED_REQUEST
 */
export const { Types, Creators } = createActions({
  getProductNew: null,
  getProductSuggested: null,
  getProductsByCategoryRequest: ['categoryId', 'clean'],
  getProductsByBrandRequest: ['brandId', 'clean'],
  getProductsByHintRequest: ['hint', 'clean'],
  getProductsByDietsRequest: ['dietsId', 'clean'],
  getProductsSuccess: ['items'],
  getProductsByCategory: ['categoryId', 'expressRoutes'],
});
