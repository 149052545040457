import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like productDetails -> PROUCT_DETAILS
 */
export const { Types, Creators } = createActions({
  openProductDetailsModal: ['item'],
  closeProductDetailsModal: null,
});
