import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@material-ui/core';
import { Field, ErrorMessage } from 'formik';
import { getInnerValue } from '@utils';

import './style.scss';

const limitNumber = (setValues, _values, maxLength, categ, n) => {
  const values = { ..._values };
  const val = values[categ][n];
  const v = String(val);
  const max = Number(maxLength);
  if (Number.isNaN(val)) {
    setValues(values);
    values[categ][n] = '0';
  }
  if (v.length > max) {
    values[categ][n] = Number(v.substring(0, max));
    setValues(values);
  }
};

export const TextForm = (props) => {
  const { xs, md, label, name, formikProps, ...rest } = props;
  const error = getInnerValue(name, formikProps.errors);
  useEffect(() => {
    const arr = name.split('.');
    const [categ, n] = arr;
    if (
      arr.length === 2 &&
      rest.type === 'number' &&
      rest.maxLength &&
      rest.formikProps &&
      rest.formikProps.values[categ] &&
      rest.formikProps.values[categ][n]
    ) {
      if (rest.formikProps.values[categ][n]) {
        limitNumber(rest.formikProps.setValues, rest.formikProps.values, rest.maxLength, categ, n);
      }
    }
  }, [rest, name]);
  const propsRest = { ...rest };
  delete propsRest.values;
  delete propsRest.className;
  return (
    <Grid item xs={xs} md={md} className=" text-form-container">
      <InputLabel className="label">{label}</InputLabel>
      <Field className="field" margin="normal" component="input" name={name} {...propsRest} />
      {error && <div>{error}</div>}
      {name === 'bill.extraData' ? <span>Ej: Apto 402 bloque 2</span> : null}
      <ErrorMessage name={name} render={(msg) => <div>{msg}</div>} />
    </Grid>
  );
};

TextForm.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any,
  placeholder: PropTypes.string,
  xs: PropTypes.number,
  md: PropTypes.number,
  formikProps: PropTypes.shape({
    errors: PropTypes.shape({}),
  }),
  type: PropTypes.string,
  className: PropTypes.string,
};
TextForm.defaultProps = {
  disabled: false,
  values: false,
  xs: 6,
  md: 6,
  type: '',
  formikProps: { error: undefined, touched: undefined },
  placeholder: '',
  className: '',
};
