import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { history } from '@redux/store';
import { Autocomplete } from '@components/index';
import '@algolia/autocomplete-theme-classic/dist/theme.css';

import './style.scss';

const SearchBar = ({ placeholder }) => {
  const onSubmit = useCallback(({ state }) => {
    if (state.query) history.push(`/buscar/${encodeURIComponent(state.query)}`);
  }, []);
  return (
    <div id="SearchBarContainer" className="search-bar-component">
      <Autocomplete
        placeholder={placeholder}
        openOnFocus={false}
        debug={false}
        onSubmit={onSubmit}
      />
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: '',
};

export { SearchBar };
