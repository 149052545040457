import { put, select } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import {
  Creators as ShoppingCartCreators,
  getShoppingCartItems,
  getShoppingCart as getCurrentShoppingCart,
  getAllShoppingCartItemsList,
  getPositiveBallanceState,
} from '@reducers/Sales';
import { Creators as LocationCreators, getFilterCity, userHasLocation } from '@reducers/Locations';
import { ShoppingCartService } from '@services/shopping-cart.service';
import { userIsLoggedIn } from '@reducers/Auth';
import { Creators as UserCreators } from '@reducers/User';
import { transformShoppingCart } from '@transforms/ApiToCart.transform';
import { ORDER__STATUS, MessagesError, MessagesInventory } from '@constants/index';

export function* getShoppingCart() {
  try {
    const userHasActiveLocation = yield select(userHasLocation);
    if (userHasActiveLocation) {
      const shoppingCart = yield ShoppingCartService.getShoppingCart();
      const shoppingCartModified = transformShoppingCart(shoppingCart);
      yield put(ShoppingCartCreators.shoppingCartFetchSucceeded(shoppingCartModified));
    }
  } catch (error) {
    yield put(ShoppingCartCreators.shoppingCartFetchFailed(error?.parsedBody?.errors));
  }
}

export function* upsertShoppingCart({ items }) {
  try {
    const { state } = yield select(getCurrentShoppingCart);
    if (state === ORDER__STATUS.PENDING) {
      yield put(ShoppingCartCreators.openSnackbar(MessagesInventory));
      return;
    }
    const userHasActiveLocation = yield select(userHasLocation);
    const currentShoppingCartItemsList = yield select(getAllShoppingCartItemsList);
    const currentItem = currentShoppingCartItemsList.standart?.items.find(
      (item) => item.itemId === items[0].itemId,
    );
    if (userHasActiveLocation) {
      const updateshoppingCart = yield ShoppingCartService.updateShoppingCart(items);
      if (updateshoppingCart) {
        const shoppingCart = yield ShoppingCartService.getShoppingCart();
        mixpanel.track('Add_To_Cart', { items: { ...items }, action: 'upsertShoppingCart' });
        const shoppingCartModified = transformShoppingCart(shoppingCart);

        yield put(ShoppingCartCreators.shoppingCartFetchSucceeded(shoppingCartModified));

        if (items[0].quantityInPackages === items[0].totalStock) {
          yield put(ShoppingCartCreators.openSnackbar(MessagesInventory));
        }

        const { standart } = shoppingCartModified.items;

        const fullItems = [...standart.items];

        const storedItem = fullItems.find((itemData) => itemData.itemId === items[0].itemId);
        if (
          items[0].quantityInPackages !== items[0].totalStock &&
          storedItem?.quantityInPackages > currentItem?.quantityInPackages
        ) {
          yield put(ShoppingCartCreators.openAddProductSnackbar());
        }
      }
    } else {
      yield put(LocationCreators.openLocationModal());
    }
  } catch (error) {
    yield put(ShoppingCartCreators.shoppingCartFetchFailed(error?.parsedBody?.errors));
  }
}

export function* addToShoppingCart({ item }) {
  try {
    const shoppingCart = yield select(getCurrentShoppingCart);
    if (shoppingCart?.state === ORDER__STATUS.PENDING) {
      yield put(UserCreators.openSnackbar(MessagesError));
      return;
    }
    let userHasActiveLocation = yield select(userHasLocation);
    if (!userHasActiveLocation) {
      userHasActiveLocation = yield select(getFilterCity);
    }
    if (userHasActiveLocation) {
      const { standart } = yield select(getShoppingCartItems);
      let existingItem;

      const itemsListByType = [standart].filter((cart) => cart.items.length > 0);

      if (itemsListByType.length) {
        const matchProductByCartType = itemsListByType.find((typecart) => {
          const matched =
            typecart.items.length &&
            typecart.items.find((p) => p.itemId === item.itemId && p.routeId === item.routeId);
          return matched;
        });
        if (matchProductByCartType !== undefined) {
          existingItem = matchProductByCartType.items.find(
            (currentItem) =>
              currentItem.itemId === item.itemId && currentItem.routeId === item.routeId,
          );
        } else {
          existingItem = false;
        }
      }

      const quantity = item.quantityInPackages === 0 ? 1 : item.quantityInPackages;
      const newQuantity = existingItem
        ? existingItem.quantityInPackages + item.quantityInPackages
        : quantity;

      if (!item.isClothes) {
        if (newQuantity > item.total_inventory) {
          yield put(ShoppingCartCreators.openSnackbar(MessagesInventory));
          return;
        }
      }
      const dataUpdateCart = [
        {
          itemId: item.selectVariant ? item.selectVariant : item.id,
          quantityInPackages: item.selectVariant ? item.quantity : newQuantity || 1,
          routeId: item.routeId ? item.routeId : null,
        },
      ];

      yield ShoppingCartService.updateShoppingCart(dataUpdateCart);
      mixpanel.track('Add_To_Cart', {
        items: { ...dataUpdateCart },
        action: 'addToShoppingCart',
      });
      const shoppingCart = yield ShoppingCartService.getShoppingCart();
      const shoppingCartModified = transformShoppingCart(shoppingCart);
      yield put(ShoppingCartCreators.closeRepeatProductModalRequest());
      yield put(ShoppingCartCreators.shoppingCartFetchSucceeded(shoppingCartModified));
      if (!item.isClothes) {
        if (newQuantity === item.total_inventory) {
          yield put(ShoppingCartCreators.openSnackbar(MessagesInventory));
          return;
        }
      }

      if (newQuantity !== item.total_inventory) {
        yield put(ShoppingCartCreators.openAddProductSnackbar());
      }
    } else {
      yield put(LocationCreators.openLocationModal());
    }
  } catch (error) {
    yield put(ShoppingCartCreators.shoppingCartFetchFailed(error?.parsedBody?.errors));
  }
}
export function* resetShoppingCart() {
  const shoppingCart = yield select(getShoppingCart);
  /*   const shoppingCart = yield ShoppingCartService.resetShoppingCart(); */
  console.log(shoppingCart);
  /*   yield put(ShoppingCartCreators.shoppingCartFetchSucceeded(transformShoppingCart(shoppingCart))); */
}

export function* updatePickUpMethod({ willPickUpInWarehouse }) {
  const isLoggedIn = yield select(userIsLoggedIn);
  if (isLoggedIn) {
    const shoppingCart = yield ShoppingCartService.updateShoppingCartPickUp(willPickUpInWarehouse);
    yield put(ShoppingCartCreators.shoppingCartFetchSucceeded(transformShoppingCart(shoppingCart)));
  }
}

export function* usePositiveBallance() {
  const { enabled } = yield select(getPositiveBallanceState);
  const shoppingCart = yield ShoppingCartService.handlePositiveBalallance(enabled);
  const shoppingCartModified = transformShoppingCart(shoppingCart);
  yield put(ShoppingCartCreators.shoppingCartFetchSucceeded(shoppingCartModified));
}
