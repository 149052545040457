import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { stringNormalize } from '../../../utils/format/stringNormalize.utils';
import styles from './styles.module.scss';

export const CategoryButton = ({ className, onClick, isActive, category: { label, imageUrl } }) => (
  <div
    id={`CategoryButtonCategoryContainer-${stringNormalize(label)}`}
    className={cx(styles['category-button'], styles[`category-button--circular`], {
      [`${className}`]: className !== null,
    })}
    onClick={onClick}
  >
    <img
      id={`CategoryButtonCategoryImg-${stringNormalize(label)}`}
      className={cx(
        styles['category-button__image'],
        styles[`category-button__image--circular`],
        isActive ? styles[`category-button__image--active`] : '',
      )}
      src={imageUrl}
      alt={label}
    />
    <p
      id={`CategoryButtonCategoryTitle-${stringNormalize(label)}`}
      className={cx(
        'sf-text--normal',
        styles['category-button__label'],
        styles[`category-button__label--circular`],
        isActive ? styles[`category-button__label--active`] : '',
      )}
    >
      {label}
    </p>
  </div>
);

CategoryButton.defaultProps = {
  className: null,
  onClick: null,
  isActive: false,
  category: {},
};

CategoryButton.propTypes = {
  category: PropTypes.shape({
    imageUrl: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
