import { call } from 'redux-saga/effects';
import { odooApi } from './api';

export const LocationService = {
  *getUserLocations() {
    return yield call({ context: odooApi, fn: odooApi.get }, '/general/locations');
  },

  getAnonLocations() {
    const root = JSON.parse(localStorage.getItem('persist:root'));
    const delivery = JSON.parse(root.delivery);
    const { anonLocations } = delivery;
    const parserCordinatesAnonLocations = anonLocations.reduce((accumulator, currentValue) => {
      const mappedLocation = {
        ...currentValue,
        latitude: parseFloat(currentValue.latitude),
        longitude: parseFloat(currentValue.longitude),
      };
      return [...accumulator, mappedLocation];
    }, []);
    return parserCordinatesAnonLocations || [];
  },

  *addAnonLocation({ location }) {
    const currentLocations = yield call(this.getAnonLocations);
    const disabledIsSelectedAnonlist = currentLocations.map((loc) => ({
      ...loc,
      isSelected: false,
    }));
    const anonLocations = [
      ...disabledIsSelectedAnonlist,
      { ...location, id: Math.round(Math.random() * 100) },
    ];
    localStorage.setItem('anonLocations', JSON.stringify(anonLocations));
    const parserCordinatesAnonLocations = anonLocations.reduce((accumulator, currentValue) => {
      const mappedLocation = {
        ...currentValue,
        latitude: parseFloat(currentValue.latitude),
        longitude: parseFloat(currentValue.longitude),
      };
      return [...accumulator, mappedLocation];
    }, []);
    return yield parserCordinatesAnonLocations;
  },

  *updateAnonLocations({ location, currentAnonLocations }) {
    const anonLocations = currentAnonLocations.map((loc) => {
      if (loc.id === location.id) {
        return loc;
      }
      return { ...loc, isSelected: false };
    });
    localStorage.setItem('anonLocations', JSON.stringify(anonLocations));
    const parserCordinatesAnonLocations = anonLocations.reduce((accumulator, currentValue) => {
      const mappedLocation = {
        ...currentValue,
        latitude: parseFloat(currentValue.latitude),
        longitude: parseFloat(currentValue.longitude),
      };
      return [...accumulator, mappedLocation];
    }, []);
    return yield parserCordinatesAnonLocations;
  },

  *changeSelectedLocation({ locationId, forceSelection }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      `/general/locations/default`,
      { force_selection: forceSelection },
      { locationId },
    );
  },

  *addUserLocation({ location, forceSelection }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/general/locations',
      { force_selection: forceSelection },
      {
        name: location.name,
        address: location.address,
        city: location.city,
        department: location.department,
        latitude: parseFloat(location.latitude),
        longitude: parseFloat(location.longitude),
        aditionalInfo: location.aditionalInfo,
      },
    );
  },

  *updateUserLocation({ location: { id, ...rest } }) {
    return yield call(
      { context: odooApi, fn: odooApi.put },
      `/general/locations`,
      { location_id: id },
      {
        name: rest.name,
        address: rest.address,
        city: rest.city,
        department: rest.department,
        latitude: parseFloat(rest.latitude),
        longitude: parseFloat(rest.longitude),
        aditionalInfo: rest.aditionalInfo,
      },
    );
  },

  *deleteUserLocation({ locationId }) {
    return yield call({ context: odooApi, fn: odooApi.delete }, `/user/locations/${locationId}`);
  },
};
