// @ts-nocheck
import { call } from 'redux-saga/effects';
import { transformDiets } from '@transforms/ApiToDiets.transform';
import { odooApi } from './api';

export const DietsService = {
  *getDiets() {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/diets', {});
    return transformDiets(response.data.diets);
  },
};
