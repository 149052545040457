import { useMemo } from 'react';
import QueryString from 'query-string';
import { history } from '@utils';

export function useQueryParam(queryParam, defaultValue) {
  const { location } = history;
  const params = useMemo(() => QueryString.parse(location.search), [location.search]);

  const value = params[queryParam];

  if (value === undefined && defaultValue !== undefined) {
    return defaultValue;
  }

  return value;
}
