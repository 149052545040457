import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '@superfuds/saori';
import { capitalizeText } from '@utils/format/format.utils';
import { currencyFormatter } from '@utils/render';
import { getPaymentMethod } from '@utils/index';
import { MINIMUM_TC_PAYMENT, PAYMENT__METHODS } from '@constants/index';

import { ReactComponent as EnabledIcon } from '@assets/svg/statusIconEnabled.svg';
import { ReactComponent as DisabledIcon } from '@assets/svg/statusIconDisabled.svg';
import { ReactComponent as TCIcon } from '@assets/svg/TCIcon.svg';
import { ReactComponent as TCIconDisabled } from '@assets/svg/TCIconDisabled.svg';

import './styles.scss';

const TCOption = ({ open, paymentMethod, isDisabled }) => {
  return (
    <Card
      className={`paymentOption ${
        paymentMethod?.method === PAYMENT__METHODS.TC
          ? 'paymentOption-active'
          : 'paymentOption-whiteBg'
      } ${
        (paymentMethod?.method === PAYMENT__METHODS.RAPPI_PAY ||
          paymentMethod?.method === PAYMENT__METHODS.CASH) &&
        'paymentOption-whiteBg'
      }`}
    >
      <Grid container classes={{ root: 'paymentOption__dataContainer' }}>
        <Grid
          container
          justifyContent="flex-start"
          classes={{ root: 'paymentOption__paymentDetail' }}
        >
          {isDisabled ? (
            <TCIconDisabled
              id="PaymentMethodsPaymentOptionTCOptionMethodOptionTCIconDisabled"
              style={{ marginRight: 20 }}
            />
          ) : (
            <TCIcon
              id="PaymentMethodsPaymentOptionTCOptionMethodOptionTCIcon"
              style={{ marginRight: 20 }}
            />
          )}
          <Grid container direction="column" style={{ maxWidth: '80%' }}>
            {paymentMethod?.method === PAYMENT__METHODS.TC && (
              <Typography
                classes={{
                  root: isDisabled
                    ? 'paymentOption__paymentDetail--disabled'
                    : 'paymentOption__paymentDetail',
                }}
              >
                {capitalizeText(getPaymentMethod(paymentMethod.cardNumber.replace(/\s/g, '')))}
                <span className="paymentOption__TCNumber">
                  {paymentMethod.cardNumber
                    .slice(-5)
                    .split('')
                    .map((s, i) => (i < 1 ? '*' : s))
                    .join('')}
                </span>
              </Typography>
            )}
            {!paymentMethod?.method || paymentMethod?.method !== PAYMENT__METHODS.TC ? (
              <Typography
                classes={{
                  root: isDisabled ? 'paymentOption__label--disabled' : 'paymentOption__label',
                }}
              >
                Tarjeta de crédito
              </Typography>
            ) : null}
            {isDisabled && (
              <Typography component="span" classes={{ root: 'paymentOption__info' }}>
                Pago para compra mínima de {currencyFormatter(MINIMUM_TC_PAYMENT)}
              </Typography>
            )}
          </Grid>
        </Grid>
        {(open || paymentMethod?.method === PAYMENT__METHODS.TC) && !isDisabled && (
          <EnabledIcon
            id="PaymentMethodsPaymentOptionTCOptionMethodOptionEnabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
        {!open && paymentMethod?.method !== PAYMENT__METHODS.TC && !isDisabled && (
          <DisabledIcon
            id="PaymentMethodsPaymentOptionTCOptionMethodOptionDisabledIcon"
            style={{ justifySelf: 'flex-end' }}
          />
        )}
      </Grid>
    </Card>
  );
};

TCOption.propTypes = {
  open: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.shape({
    bank: PropTypes.string,
    documentNumber: PropTypes.string,
    documentType: PropTypes.string,
    email: PropTypes.string,
    cardName: PropTypes.string,
    cardNumber: PropTypes.string,
    cvv: PropTypes.string,
    date: PropTypes.string,
    method: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export { TCOption };
