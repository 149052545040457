import { put, call } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import { Creators as CategoriesCreators } from '@reducers/Categories/actionsTypes';
import { odooApi } from '@services/api';
import { transformCategories } from '@transforms/ApiToCategorytransform';

export function* getCategories() {
  try {
    const response = yield call({ context: odooApi, fn: odooApi.get }, '/categories');
    const categories = transformCategories(response.data);

    const data = {
      categories,
      expressRoutes: response.data.expressRoutes,
      sections: response.data.sections,
      exploreCategories: response.data.categories.filter(
        (item) => item.image.length && item.image.find((image) => image.type === 'General'),
      ),
    };
    yield put(CategoriesCreators.categoriesSuccess(data));
  } catch (error) {
    captureException(error);
  }
}
