import { select, put } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import { getProductsPage } from '@reducers/Products';
import { Creators as ProductsCreators } from '@reducers/Products/actionsTypes';
import { Creators as ProductActions } from '@reducers/Product/actionsTypes';
import { getUserData } from '@reducers/User';
import { getProductsPageNew } from '@reducers/ProductNew';
import { Creators as ProductNewCreators } from '@reducers/ProductNew/actionsTypes';
import { ProductsService } from '@services/products.service';
import { SUGGESTED_CATEGORY_ID, UserType } from '@constants/index';

import { history } from '@redux/store';

export function* getProductNew() {
  try {
    const page = yield select(getProductsPageNew);
    const response = yield ProductsService.getProductNew({
      page: page + 1,
    });
    yield put(ProductNewCreators.productNewSuccess(response));
  } catch (err) {
    captureException(err);
  }
}

export function* getProductSuggested() {
  try {
    const user = yield select(getUserData);
    const page = 0;
    if (user.clientType === parseInt(UserType.B2C, 10)) {
      const response = yield ProductsService.getByCategoryId({
        categoryId: SUGGESTED_CATEGORY_ID,
        page: page + 1,
      });
      console.log(response);
    }
  } catch (error) {
    captureException(error);
  }
}

export function* getProductsByCategory({ categoryId, expressRoutes, subCategoryId }) {
  try {
    const page = yield select(getProductsPage);
    const response = yield ProductsService.getByCategoryId({
      categoryId,
      page: page + 1,
      subCategoryId,
    });
    yield put(ProductsCreators.getProductsSuccess(response));
  } catch (error) {
    captureException(error);
  }
}

export function* getProductsByBrand({ brandId }) {
  try {
    const page = yield select(getProductsPage);
    const response = yield ProductsService.getByBrand({
      brandId,
      page: page + 1,
    });
    yield put(ProductsCreators.getProductsSuccess(response));
  } catch (error) {
    captureException(error);
  }
}

export function* getProductsByHint({ hint }) {
  try {
    const page = yield select(getProductsPage);
    const response = yield ProductsService.getByQuery({
      hint,
      page: page + 1,
    });
    yield put(ProductsCreators.getProductsSuccess(response));
  } catch (error) {
    captureException(error);
  }
}

export function* getProductById({ payload }) {
  const { productId, templateId } = payload;
  try {
    const product = yield ProductsService.getProductDetails({ productId, templateId });
    if (product.data.length > 0) {
      const responseToMap = product.data[0];
      yield put(ProductActions.getProductSuccess(responseToMap));
    } else {
      history.push(`/404/`);
      yield put(ProductActions.getProductError());
    }
  } catch (error) {
    yield put(ProductActions.getProductError());
    captureException(error);
  }
}

export function* getProductsByDiets({ dietsId }) {
  try {
    const page = yield select(getProductsPage);
    const response = yield ProductsService.getByDietID({
      dietsId,
      page: page + 1,
    });
    yield put(ProductsCreators.getProductsSuccess(response));
  } catch (error) {
    captureException(error);
  }
}
