export const isEmptyValue = (variable) => {
  return variable === undefined || variable === null || variable === '';
};

export const getObjectFunction = (obj, nameFunction) => {
  if (typeof obj[nameFunction] === 'function') {
    return obj[nameFunction]();
  }
  return null;
};
