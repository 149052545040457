import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';

import './style.scss';

const TitleSections = ({ title, onClick, show, id }) => {
  return (
    <Grid container className="title-sections">
      <Grid item lg={10} md={9} xs={12} className="mt-2 mb-2">
        <Typography variant="h5" className="text-left">
          {title}
        </Typography>
      </Grid>
      <Grid item lg={2} md={3} className="mt-2 mb-2">
        {show && (
          <Typography
            variant="caption"
            id={`${id}SeeMore`}
            className="primary-colored-font  show-all"
            style={{ cursor: 'pointer', fontSize: '1rem' }}
            onClick={() => {
              onClick();
            }}
          >
            Ver más
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
TitleSections.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

TitleSections.defaultProps = {
  onClick: () => {},
  show: true,
};

export { TitleSections };
