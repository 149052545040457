/* eslint-disable no-useless-catch */
import { call } from 'redux-saga/effects';
import { odooApi } from './api';

export const OrdersService = {
  *getOrders({ page = 1, month }) {
    try {
      const response = yield call({ context: odooApi, fn: odooApi.get }, '/sales', {
        page,
        per_page: 20,
        ...(month ? { month } : {}),
      });
      return response;
    } catch (err) {
      throw new Error(err);
    }
  },

  *getOrdersByMonth({ page = 1, month }) {
    try {
      const response = yield call({ context: odooApi, fn: odooApi.get }, '/orders', {
        page,
        per_page: 20,
        month,
      });
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  *getSeason() {
    try {
      return yield call({ context: odooApi, fn: odooApi.get }, '/sales/experience-rating/reasons');
    } catch (error) {
      throw error;
    }
  },

  *experienceUser({ orderId, body }) {
    try {
      return yield call(
        { context: odooApi, fn: odooApi.post },
        `/sales/experience-rating/${orderId}`,
        {},
        {
          ...body,
        },
      );
    } catch (error) {
      throw error;
    }
  },
};
