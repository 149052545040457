import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { connect, useDispatch } from 'react-redux';
import { Creators as LocationsCreators } from '@reducers/Locations';
import { Creators as AuthCreators } from '@reducers/Auth';
import locationIcon from '@assets/svg/locationIcon.svg';
import { UserType, TOKEN_KEY } from '@constants/index';

import { capitalizeText } from '@utils/format/format.utils';
import './style.scss';

const LocationIconSvg = () => (
  <SVG id="UbicationLocationIconSvg" className="svg svg-primary-color" src={locationIcon} />
);

const UbicationButtonBase = ({ id, onclick, userLocations, clientType, justifyContent, name }) => {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery('(max-width: 899px)');

  const address = () => {
    if (localStorage.getItem(TOKEN_KEY) && userLocations.length) {
      const isSelectedLocation = userLocations.find((location) => location.isSelected);
      return isSelectedLocation;
    }
    return null;
  };

  return (
    <Grid
      id={id}
      container
      style={{
        justifyContent,
      }}
      className="ubication-button"
      aria-hidden="true"
      onClick={() => {
        if (localStorage.getItem(TOKEN_KEY)) {
          if (Array.isArray(userLocations) || userLocations?.isSelected) {
            onclick();
          } else {
            dispatch(LocationsCreators.openLocationModal());
          }
        } else if (clientType === parseInt(UserType.B2B, 10)) {
          dispatch(AuthCreators.openBToBModal());
        } else {
          dispatch(LocationsCreators.toggleGoogleMapsModal());
        }
      }}
    >
      <Grid item className="iconContainer">
        <LocationIconSvg />
      </Grid>
      <Grid item className="ml-1 infoContainer">
        {isTablet && name?.split(' ')?.[0] && (
          <Typography variant="h6" className="addressLabel pr-1" noWrap>
            Enviar a {name?.split(' ')?.[0] ?? ''} -
          </Typography>
        )}

        <Typography variant="h6" className="addressLabel">
          {address() &&
            address()?.name &&
            `${capitalizeText(address()?.city?.name)}, ${capitalizeText(
              address() && address()?.name,
            )}`}
          {address() &&
            !address()?.name &&
            address()?.city &&
            `Envío a ${capitalizeText(address()?.city?.name)}`}
          {!address() && 'Seleccionar ciudad'}
        </Typography>
      </Grid>
    </Grid>
  );
};
UbicationButtonBase.defaultProps = {
  justifyContent: '',
};

UbicationButtonBase.propTypes = {
  justifyContent: PropTypes.string,
  id: PropTypes.string.isRequired,
  onclick: PropTypes.func.isRequired,
  clientType: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({
  delivery: { userLocations, filterCity },
  user: { clientType, name },
}) => ({
  userLocations,
  filterCity,
  clientType,
  name,
});

export const UbicationButton = connect(mapStateToProps)(UbicationButtonBase);
