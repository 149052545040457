import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like salesRequest -> SALES_REQUEST
 */
export const { Types, Creators } = createActions({
  toggleShoppingCart: null,
  getShoppingCart: ['checkStatus'],
  getStatusPayWithPse: ['token'],
  shoppingCartFetchSucceeded: ['shoppingCart'],
  shoppingCartFetchFailed: ['error'],
  upsertShoppingCart: ['items'],
  addToShoppingCart: ['item'],
  resetShoppingCart: null,
  addCoupon: ['coupon'],
  addCouponSucceded: ['couponInfo'],
  addCouponFailed: ['couponInfo'],
  getCoupons: null,
  getCouponsSucceeded: ['couponsList'],
  getCouponsFailed: ['error'],
  selectCoupon: ['coupon'],
  deleteCoupon: ['coupon'],
  payWithPse: ['paymentData'],
  payWithCreditCard: ['paymentData'],
  shoppingCartSaleSucceeded: ['payload'],
  shoppingCartSaleFailed: ['error'],
  startShoppingCartSale: null,
  stopShoppingCartSale: null,
  resetShoppingCartSalePayment: null,
  updateBillingData: ['billingData'],
  reset: null,
  cleanError: null,
  cleanCouponError: null,
  showExpressWarning: null,
  hideExpressWarning: null,
  showExpressUnavailable: null,
  hideExpressUnavailable: null,
  updateExpressCart: ['categoryId', 'item'],
  updateExpressCartSelected: ['categoryId'],
  removeExpressCart: null,
  setWillPickUpInWarehouse: ['willPickUpInWarehouse'],
  confirmFreeOrder: null,
  openRepeatProductModalRequest: ['payload'],
  closeRepeatProductModalRequest: null,
  handlePositiveBallanceRequest: null,
  payWithCashRequest: null,
  toggleCouponerRequest: null,
  openAddProductSnackbar: null,
  closeAddProductSnackbar: null,
  openSnackbar: ['message'],
  closeSnackbar: null,
  payWithCreditRequest: ['id', 'sfId'],
  shoppingCartSalePayCreditSucceeded: ['payload'],
  getListBank: null,
  listBanckSucceeded: ['payload'],
  getListCard: null,
  getListCardSucceeded: ['payload'],
  getListCardFailed: ['error'],
  deleteCard: ['id'],
  getTokenZiro: null,
  validateCreditZiro: ['payload'],
  validateCreditZiroSucceeded: ['data'],
  validateCreditZiroFailed: ['error'],
  validateCodeCreditZiro: ['payload'],
  resendCodeZiro: ['payload'],
  applyCreditZiro: ['payload'],
  resetCreditZiro: null,
});
