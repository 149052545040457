import React, { useState, useMemo, useRef, useEffect } from 'react';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import algoliasearch from 'algoliasearch/lite';
import { Modal, Backdrop, Grid, Button } from '@material-ui/core';
import { history } from '@redux/store';
import { Highlight } from '@hooks/use-Highlight';
import { useWindowSize } from '@hooks/use-windows-size.hook';

import { ReactComponent as SearchIcon } from '@assets/svg/searchIcon.svg';
import { ReactComponent as ClearIcon } from '@assets/svg/clearIcon.svg';

import './style.scss';

/* const appId = 'latency';
const apiKey = '6be0576ff61c053d5f9a3225e2a90f76'; */

const appId = 'SKLHPQ79AO';
const apiKey = '5c2dc88c112386e2dcd6beaf71f654c7';
const searchClient = algoliasearch(appId, apiKey);

export function Autocomplete({ placeholder, openOnFocus, debug, onSubmit }) {
  const { width } = useWindowSize();
  const [autocompleteState, setAutocompleteState] = useState({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });
  const [showModal, setModata] = useState(false);

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          const collections = [];
          state.collections.map((collection) => {
            const { items, source } = collection;
            // const filterItems = items.filter((item) => item.is_available_to_sale);
            collections.push({
              source,
              items: [...items],
            });
            return items;
          });

          const newState = {
            ...state,
            collections,
          };
          setAutocompleteState(newState);
        },
        onSubmit(state) {
          onSubmit(state);
        },
        getSources() {
          return [
            {
              sourceId: 'products',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'production_products',
                      query,
                      params: {
                        hitsPerPage: 5,
                        highlightPreTag: '__aa-highlight__',
                        highlightPostTag: '__/aa-highlight__',
                      },
                    },
                  ],
                  transformResponse({ hits }) {
                    return hits[0]?.filter(
                      (item) => item.is_available_to_sale && item?.product_brand_id !== 772,
                    );
                  },
                });
              },
            },
          ];
        },
      }),
    [onSubmit],
  );
  const inputRef = useRef(null);
  const formRef = useRef(null);
  const panelRef = useRef(null);

  const { getEnvironmentProps } = autocomplete;

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }
    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, formRef, inputRef, panelRef, autocomplete]);
  useEffect(() => {
    if (autocompleteState.query === '') {
      autocomplete.setContext({ root: 'scope' });
    } else {
      autocomplete.setContext({ root: '' });
    }
    autocomplete.refresh();
  }, [autocompleteState.query, autocomplete, showModal]);

  const formsAutocomplete = (children) => {
    return (
      <form
        ref={formRef}
        className="aa-Form"
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
      >
        <div className="aa-InputWrapperPrefix">
          <label className="aa-Label" {...autocomplete.getLabelProps({})}>
            <button className="aa-SubmitButton" type="submit" title="Submit">
              <SearchIcon />
            </button>
          </label>
        </div>
        <div className="aa-InputWrapper">{children}</div>
        {autocompleteState.isOpen && (
          <div className="aa-InputWrapperSuffix">
            <Button className="aa-ClearButton" type="reset">
              <ClearIcon />
            </Button>
          </div>
        )}
      </form>
    );
  };

  const responseResult = () =>
    autocompleteState.isOpen &&
    autocompleteState.collections[0]?.items.length > 0 && (
      <div
        ref={panelRef}
        className={[
          'aa-Panel',
          'aa-Panel--desktop',
          autocompleteState.status === 'stalled' && 'aa-Panel--stalled',
        ]
          .filter(Boolean)
          .join(' ')}
        {...autocomplete.getPanelProps({})}
      >
        {autocompleteState.collections.length > 0 && (
          <div className="aa-PanelLayout aa-Panel--scrollable">
            {autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;

              return (
                <section key={`source-${index}`} className="aa-Source">
                  <span>
                    <span className="aa-SourceHeaderTitle">Resultados:</span>
                    <div className="aa-SourceHeaderLine" />
                  </span>

                  {items.length > 0 && (
                    <ul className="aa-List" {...autocomplete.getListProps()}>
                      {items
                        .filter((item) => item.is_available_to_sale)
                        .map((item) => {
                          return (
                            <li
                              key={item.objectID}
                              className="aa-Item"
                              {...autocomplete.getItemProps({ item, source })}
                            >
                              <div
                                className="aa-ItemWrapper"
                                onClick={() => {
                                  const pathname = `/p/${item.id}`;
                                  if (item.id) {
                                    history.push({
                                      pathname,
                                      state: {
                                        totalInventory: item?.total_inventory
                                          ? item?.total_inventory
                                          : 0,
                                        routeId: item?.routeId ? item?.routeId : null,
                                        routeType: item?.routeType ? item?.routeType : null,
                                      },
                                    });
                                  }
                                }}
                              >
                                <div className="aa-ItemContent">
                                  {item.image && (
                                    <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                                      <img
                                        src={item.image}
                                        alt={item.name}
                                        width="40"
                                        height="40"
                                      />
                                    </div>
                                  )}
                                  <div className="aa-ItemContentBody">
                                    <div className="aa-ItemContentTitle">
                                      <Highlight hit={item} attribute="sf_ecom_name" />
                                    </div>
                                    <div className="aa-ItemContentDescription">
                                      de la marca <strong>{item.product_brand_name}</strong>
                                    </div>
                                  </div>
                                </div>
                                <div className="aa-ItemActions">
                                  <button
                                    className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                    type="button"
                                    title="Select"
                                    style={{ pointerEvents: 'none' }}
                                  >
                                    <svg fill="currentColor" viewBox="0 0 24 24">
                                      <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </section>
              );
            })}
          </div>
        )}
      </div>
    );
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalAutocomplete"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        hideBackdrop
        keepMounted
        open={showModal}
      >
        <Grid container spacing={2} className="container-search">
          <Grid item xs={10}>
            {formsAutocomplete(
              width <= 600 && (
                <input
                  className="aa-Input"
                  ref={inputRef}
                  {...autocomplete.getInputProps({ inputElement: inputRef.current })}
                  placeholder={placeholder}
                />
              ),
            )}
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => {
                autocomplete.setQuery('');
                setModata(!showModal);
              }}
            >
              <ClearIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            {responseResult()}
          </Grid>
        </Grid>
      </Modal>
      <div
        onClick={() => {
          if (width <= 600) setModata(!showModal);
        }}
        className="aa-Autocomplete"
        {...autocomplete.getRootProps({})}
      >
        {!showModal &&
          formsAutocomplete(
            width >= 600 && (
              <input
                className="aa-Input"
                ref={inputRef}
                {...autocomplete.getInputProps({ inputElement: inputRef.current })}
                placeholder={placeholder}
              />
            ),
          )}
        {!showModal && responseResult()}
      </div>
    </div>
  );
}
