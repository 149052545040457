/* eslint-disable camelcase */
import { put, select } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';

import { PaymentService } from '@services/payment.service';
import { Creators as ShoppingCartCreators, getShoppingCart } from '@reducers/Sales';
import { userIsLoggedIn } from '@reducers/Auth';
import { getUserData } from '@reducers/User';
import { history } from '@redux/store';

import {
  PLATFORM,
  SCREENTYPE,
  PAYMENT__METHODS,
  REJECTED_TRANSACTION_CODE,
  responseNetworkMessages,
  ResponseMessagesError,
  MessagesError,
  MessagesErrorUnknown,
  TOKEN_ZIRO,
  statusCode,
  // TRANSACTION__STATUS,
} from '@constants/index';

import { pseProcessorStateMapper } from '@utils/payu/payu.parser';
import { getObjectFunction } from '@utils/helpers/helper.utils';

function isResponseSuccessful(response) {
  return (
    response?.code === statusCode.SUCCESS
    /*  &&
    response?.status?.uppercase() === TRANSACTION__STATUS.SUCCESS */
  );
}

export function* requestListBank() {
  try {
    const response = yield PaymentService.listBack();
    const formatData = response?.data?.map((item) => ({
      ...item,
      label: item.description,
    }));
    yield put(ShoppingCartCreators.listBanckSucceeded(formatData));
  } catch (error) {
    console.error(error);
  }
}
export function* requestListCard() {
  try {
    const response = yield PaymentService.getCreditCard();
    yield put(ShoppingCartCreators.getListCardSucceeded(response.data?.card_list));
  } catch (error) {
    yield put(ShoppingCartCreators.getListCardFailed(error));
  }
}
export function* requestCardDelete({ id }) {
  try {
    const response = yield PaymentService.subscriptionCreditCardDelete(id);
    if (response?.data) {
      mixpanel.track('DeleteTC', { id });
      yield put(ShoppingCartCreators.getListCard());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* payWithPse({ paymentData }) {
  try {
    yield put(ShoppingCartCreators.startShoppingCartSale());
    const { data } = yield PaymentService.initTransfer({
      data: paymentData,
    });
    if (data.transactionResponse.state === SCREENTYPE.PENDING) {
      const {
        transactionResponse: {
          extraParameters: { BANK_URL },
        },
      } = data;
      mixpanel.track('Payment_redirect_pse', { ...paymentData, data });
      window.location = BANK_URL;
    } else {
      yield put(ShoppingCartCreators.openSnackbar(MessagesError));
      mixpanel.track('Payment_redirect_pse_error', { ...data });
      yield put(ShoppingCartCreators.stopShoppingCartSale());
      yield put(
        ShoppingCartCreators.shoppingCartSaleFailed({
          error: data.transactionResponse,
          status: SCREENTYPE.FAILURESF,
          message: data.transactionResponse,
        }),
      );
    }
  } catch (error) {
    yield put(
      ShoppingCartCreators.shoppingCartSaleFailed({
        ...error,
        status: SCREENTYPE.FAILURESF,
      }),
    );
  }
}

export function* getStatuspayWithPse({ token }) {
  try {
    const isLoggedIn = yield select(userIsLoggedIn);
    yield put(ShoppingCartCreators.startShoppingCartSale());
    if (isLoggedIn) {
      const { data } = yield PaymentService.getpayStatus({ token });
      if (data?.result?.payload) {
        const {
          result: { payload },
        } = data;
        const status = pseProcessorStateMapper(payload.state);
        yield put(ShoppingCartCreators.getShoppingCart());
        const shoppingCart = yield select(getShoppingCart);
        switch (status) {
          case 'APPROVED':
            mixpanel.track('Payment_Successful', {
              ...payload,
              ...shoppingCart,
              type: PAYMENT__METHODS.PSE,
              platform: PLATFORM,
              status: SCREENTYPE.SUCCESS,
            });
            ReactGA.event('purchase', {
              currency: 'COP',
              transaction_id: shoppingCart?.sfId,
              value: shoppingCart?.grandTotal,
              shipping: shoppingCart?.coldServiceCost,
              tax: shoppingCart?.taxes,
              items: [
                ...(shoppingCart.items?.cold?.items ?? []),
                ...(shoppingCart.items?.standart?.items ?? []),
              ],
            });
            yield put(
              ShoppingCartCreators.shoppingCartSaleSucceeded({
                ...shoppingCart,
                status: SCREENTYPE.SUCCESS,
                data: payload,
              }),
            );
            break;
          case 'PENDING':
            yield put(
              ShoppingCartCreators.shoppingCartSaleFailed({
                message: responseNetworkMessages.get('PENDING'),
                status: SCREENTYPE.PENDING,
                type: PAYMENT__METHODS.PSE,
              }),
            );
            mixpanel.track('Payment_Successful', {
              ...payload,
              type: PAYMENT__METHODS.PSE,
              platform: PLATFORM,
              status: SCREENTYPE.PENDING,
              message: responseNetworkMessages.get('PENDING'),
            });
            break;
          case 'DECLINED':
            yield put(
              ShoppingCartCreators.shoppingCartSaleFailed({
                message: responseNetworkMessages.get('DECLINED'),
                status: SCREENTYPE.FAILURE,
                type: PAYMENT__METHODS.PSE,
              }),
            );
            mixpanel.track('Payment_Failed', {
              message: responseNetworkMessages.get('DECLINED'),
              status: SCREENTYPE.PENDING,
              type: PAYMENT__METHODS.PSE,
              platform: PLATFORM,
            });
            break;
          case 'EXPIRED':
            yield put(
              ShoppingCartCreators.shoppingCartSaleFailed({
                message: responseNetworkMessages.get('EXPIRED'),
                status: SCREENTYPE.FAILURE,
                type: PAYMENT__METHODS.PSE,
              }),
            );
            mixpanel.track('Payment_Failed', {
              message: responseNetworkMessages.get('EXPIRED'),
              status: SCREENTYPE.FAILURE,
              type: PAYMENT__METHODS.PSE,
              platform: PLATFORM,
            });
            break;
          default:
            yield put(
              ShoppingCartCreators.shoppingCartSaleFailed({
                message: MessagesErrorUnknown,
                status: SCREENTYPE.FAILURE,
                data: { code: REJECTED_TRANSACTION_CODE },
                type: PAYMENT__METHODS.PSE,
              }),
            );
            mixpanel.track('Payment_Failed', {
              message: MessagesErrorUnknown,
              status: SCREENTYPE.FAILURE,
              type: PAYMENT__METHODS.PSE,
              platform: PLATFORM,
            });
            yield put(ShoppingCartCreators.getShoppingCart());
        }
      } else {
        history.push('/cart');
      }
    }
  } catch (error) {
    const parsedBodyError = getObjectFunction(error, 'getParsedBody');
    mixpanel.track('Payment_Failed', {
      ...parsedBodyError,
      type: PAYMENT__METHODS.PSE,
      platform: PLATFORM,
    });
    yield put(ShoppingCartCreators.getShoppingCart());
    yield put(
      ShoppingCartCreators.shoppingCartSaleFailed({
        ...parsedBodyError,
        status: SCREENTYPE.FAILURESF,
        type: PAYMENT__METHODS.PSE,
      }),
    );
  }
}
export function* payWithCreditCard({ paymentData }) {
  try {
    yield put(ShoppingCartCreators.startShoppingCartSale());
    const shoppingCart = yield select(getShoppingCart);

    const { data } = yield PaymentService.payWithCreditCard({ data: paymentData });
    if (data?.transactionResponse?.state === 'APPROVED') {
      yield put(
        ShoppingCartCreators.shoppingCartSaleSucceeded({
          ...shoppingCart,
          status: SCREENTYPE.SUCCESS,
          data,
        }),
      );
      if (paymentData.saveCard) {
        const user = yield select(getUserData);
        const {
          paymentMethod,
          creditCard: { expirationDate, name, number, securityCode },
        } = paymentData;
        const { data } = yield PaymentService.subscriptionCreditCard({
          data: {
            creditCard: {
              identificationNumber: user.documentNumber,
              paymentMethod,
              expirationDate,
              number,
              securityCode,
              name,
            },
          },
        });
        yield put(ShoppingCartCreators.getListCard());
        mixpanel.track('SaveTC', data);
      }
      mixpanel.track('Payment_Successful', {
        ...shoppingCart,
        type: PAYMENT__METHODS.TC,
        platform: PLATFORM,
      });
      ReactGA.event('purchase', {
        currency: 'COP',
        transaction_id: shoppingCart?.sfId,
        value: shoppingCart?.grandTotal,
        shipping: shoppingCart?.coldServiceCost,
        tax: shoppingCart?.taxes,
        items: [
          ...(shoppingCart.items?.cold?.items ?? []),
          ...(shoppingCart.items?.standart?.items ?? []),
        ],
      });
    } else {
      yield put(
        ShoppingCartCreators.shoppingCartSaleFailed({
          ...shoppingCart,
          status: SCREENTYPE.FAILURE,
          data,
          message:
            responseNetworkMessages.get(data?.transactionResponse?.state) || ResponseMessagesError,
        }),
      );
      mixpanel.track('Payment_Failed', {
        ...shoppingCart,
        status: SCREENTYPE.FAILURE,
        data,
        message:
          responseNetworkMessages.get(data?.transactionResponse?.state) || ResponseMessagesError,
        type: PAYMENT__METHODS.TC,
        platform: PLATFORM,
      });
    }

    history.push('/confirmacion-pago');
    yield put(ShoppingCartCreators.getShoppingCart());
  } catch (error) {
    if (
      error.parsedBody?.data?.message === 'Transacción declinada.' ||
      error.parsedBody?.data?.message === 'Internal server error'
    ) {
      error.parsedBody.data.message = ResponseMessagesError;
    }

    mixpanel.track('Payment_Failed', {
      status: SCREENTYPE.FAILURE,
      ...error?.parsedBody,
      platform: PLATFORM,
      type: PAYMENT__METHODS.TC,
    });
    yield put(
      ShoppingCartCreators.shoppingCartSaleFailed({
        ...error?.parsedBody,
        ...error?.parsedBody?.data,
        status: SCREENTYPE.FAILURE,
      }),
    );
    history.push('/confirmacion-pago');
  }
}

export function* confirmFreeOrder() {
  const isLoggedIn = yield select(userIsLoggedIn);
  const shoppingCart = yield select(getShoppingCart);

  if (isLoggedIn) {
    try {
      const { data } = yield PaymentService.confirmFreeOrder({
        id: shoppingCart?.id,
      });
      if (data && data?.message) {
        yield put(
          ShoppingCartCreators.shoppingCartSaleSucceeded({
            ...shoppingCart,
            status: SCREENTYPE.SUCCESS,
            data: {
              state: 'APPROVED',
            },
          }),
        );
        mixpanel.track('Payment_Successful', {
          ...shoppingCart,
          data: {
            state: 'APPROVED',
          },
          type: PAYMENT__METHODS.ZIRO,
          platform: PLATFORM,
        });
        ReactGA.event('purchase', {
          currency: 'COP',
          transaction_id: shoppingCart?.sfId,
          value: shoppingCart?.grandTotal,
          shipping: shoppingCart?.coldServiceCost,
          tax: shoppingCart?.taxes,
          items: [
            ...(shoppingCart.items?.cold?.items ?? []),
            ...(shoppingCart.items?.standart?.items ?? []),
          ],
        });
        yield put(ShoppingCartCreators.stopShoppingCartSale());
        history.push('/confirmacion-pago');
      }
    } catch (error) {
      yield put(ShoppingCartCreators.resetCreditZiro());
      yield put(
        ShoppingCartCreators.shoppingCartSaleFailed({
          message: ResponseMessagesError,
          status: SCREENTYPE.FAILURE,
        }),
      );
    }
  }
}

export function* payWithCash() {
  try {
    const { data } = yield PaymentService.payWithCashIn();

    const shoppingCart = yield select(getShoppingCart);
    yield put(
      ShoppingCartCreators.shoppingCartSaleSucceeded({
        sfId: shoppingCart?.sfId,
        message: 'Se genero el documento de forma exitosa!',
        status: SCREENTYPE.SUCCESS,
        type: PAYMENT__METHODS.CASH,
        data,
      }),
    );
    yield put(ShoppingCartCreators.getShoppingCart());
    yield put(ShoppingCartCreators.stopShoppingCartSale());
    mixpanel.track('Payment_Successful', {
      ...shoppingCart,
      type: PAYMENT__METHODS.CASH,
      platform: PLATFORM,
    });
    ReactGA.event('purchase', {
      currency: 'COP',
      transaction_id: shoppingCart?.sfId,
      value: shoppingCart?.grandTotal,
      shipping: shoppingCart?.coldServiceCost,
      tax: shoppingCart?.taxes,
      items: [
        ...(shoppingCart.items?.cold?.items ?? []),
        ...(shoppingCart.items?.standart?.items ?? []),
      ],
    });

    history.push('/confirmacion-pago');
  } catch (error) {
    mixpanel.track('Payment_Failed', {
      status: SCREENTYPE.FAILURE,
      ...error?.parsedBody,
      platform: PLATFORM,
      type: PAYMENT__METHODS.CASH,
    });
    yield put(
      ShoppingCartCreators.shoppingCartSaleFailed({
        status: SCREENTYPE.FAILURE,
        message: error.message,
        type: PAYMENT__METHODS.CASH,
      }),
    );
  }
}

export function* payWithCredit({ id, sfId }) {
  yield put(ShoppingCartCreators.startShoppingCartSale());
  try {
    const { data } = yield PaymentService.payWithCreditSF({
      id,
    });
    yield put(
      ShoppingCartCreators.shoppingCartSalePayCreditSucceeded({
        sfId,
        type: PAYMENT__METHODS.CREDIT_SF,
        message: sfId,
      }),
    );
    yield put(ShoppingCartCreators.stopShoppingCartSale());
    mixpanel.track('Payment_Successful', {
      ...data,
      type: PAYMENT__METHODS.CREDIT_SF,
      platform: PLATFORM,
    });
    history.push('/confirmacion-pago');
    yield put(ShoppingCartCreators.getShoppingCart());
  } catch (error) {
    const errorData = {
      status: SCREENTYPE.FAILURE,
      message: error.message,
      platform: PLATFORM,
      type: PAYMENT__METHODS.CREDIT_SF,
    };
    mixpanel.track('Payment_Failed', errorData);
    yield put(ShoppingCartCreators.shoppingCartSaleFailed(errorData));
  }
}

export function* authZiro() {
  try {
    const { token } = yield PaymentService.authZiro();
    localStorage.setItem(TOKEN_ZIRO, `Bearer ${token}`);
  } catch (error) {
    console.log(error);
  }
}

export function* validateCreditZiro({ payload }) {
  try {
    const response = yield PaymentService.validateCreditZiro({ payload });
    yield put(ShoppingCartCreators.validateCreditZiroSucceeded(response));
  } catch (error) {
    yield put(ShoppingCartCreators.validateCreditZiroFailed(error?.parsedBody));
  }
}

export function* applyCreditZiro({ payload: { numero_obligacion, numero_identificacion } }) {
  const shoppingCart = yield select(getShoppingCart);
  try {
    yield put(ShoppingCartCreators.startShoppingCartSale());
    const updatedSfId = shoppingCart?.sfId.replace('S', '');
    const response = yield PaymentService.applyCreditZiro({
      numero_obligacion,
      numero_identificacion,
      numero_orden: parseInt(updatedSfId, 10),
    });
    if (isResponseSuccessful(response)) {
      yield put(ShoppingCartCreators.confirmFreeOrder());
    }
  } catch (error) {
    mixpanel.track('Payment_Failed', {
      status: SCREENTYPE.FAILURE,
      ...error?.parsedBody,
      platform: PLATFORM,
      type: PAYMENT__METHODS.ZIRO,
    });
    yield put(
      ShoppingCartCreators.validateCreditZiroFailed({
        ...error?.parsedBody,
        validateCode: false,
      }),
    );
  }
}

export function* validateCodeZiro({ payload }) {
  try {
    const response = yield PaymentService.validateCodeCreditZiro({ payload });
    yield put(
      ShoppingCartCreators.validateCreditZiroSucceeded({
        ...response,
        validateCode: true,
      }),
    );
    if (response?.code === statusCode.SUCCESS) {
      yield put(ShoppingCartCreators.applyCreditZiro(payload));
    }
  } catch (error) {
    yield put(
      ShoppingCartCreators.validateCreditZiroFailed({
        ...error?.parsedBody,
        validateCode: !!payload?.codigo,
      }),
    );
  }
}
export function* resendCodeZiro({ payload }) {
  try {
    yield PaymentService.resendCodeZiro({ payload });
  } catch (error) {
    yield put(
      ShoppingCartCreators.validateCreditZiroFailed({
        ...error?.parsedBody,
        validateCode: !!payload?.codigo,
      }),
    );
  }
}
