import { all, select, call, put } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import { Creators as LocationCreators } from '@reducers/Locations';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { Creators as AuthCreators } from '@reducers/Auth';
import { Creators as UserCreators, getUserData as getUser } from '@reducers/User';
import { history, getStatus } from '@utils';
import { logoutSentry } from '@utils/sentry/sentry.utils';
import { TOKEN_KEY, LOGIN_IN } from '@constants/index';
import { Creators as SalesCreators } from '@reducers/Sales';
import { Creators as StartupTypes } from '@reducers/Startup';
import { getUserData } from '@sagas/user/UserSagas';

export function* otpLogin({ phone, shippingTypeOtp }) {
  try {
    const { message, status_code: status } = yield AuthService.loginOtp(phone, shippingTypeOtp);
    if (status !== 200) {
      yield put(AuthCreators.authFailure({ status, message }));
      return;
    }
    yield put(AuthCreators.authSuccess(true));
  } catch (error) {
    mixpanel.track('Acquisition_Signup_Not_Allowed', { phone });
    yield put(
      AuthCreators.authFailure({
        status: 401,
        message: 'Contacto no permitido, Escribe al siguiente correo experiencia@superfuds.com.co',
        allowedUser: true,
      }),
    );
  }
}

export function* validateOtp({ otp }) {
  try {
    const { data } = yield AuthService.validateOtp(otp);
    const { phone } = otp;

    if (data.is_new && data.register_token) {
      localStorage.setItem(TOKEN_KEY, data.register_token);
      const responseAnonymousRegister = yield UserService.anonymousRegister(phone);
      localStorage.setItem(TOKEN_KEY, responseAnonymousRegister.data.access_token);
      yield put(UserCreators.openB2bRegister());

      yield call(getUserData);
      if (responseAnonymousRegister.data.access_token) {
        yield UserService.getUserData();
      }
      yield put(
        AuthCreators.validateOtpSuccess(
          responseAnonymousRegister.data.access_token,
          responseAnonymousRegister.status_code,
        ),
      );
    }

    if (!data.is_new && data.access_token) {
      localStorage.setItem(LOGIN_IN, 'true');
      localStorage.setItem(TOKEN_KEY, data.access_token);
      yield call(getUserData);
      const { email } = yield select(getUser);
      yield put(AuthCreators.validateOtpSuccess(data.access_token));
      mixpanel.track('Login_Successful', { type: 'Phone', email });
    }
  } catch (error) {
    const errorStatus = getStatus(error);
    if (errorStatus === 401 || !error?.parsedBody)
      yield put(AuthCreators.validateOtpFailure({ otpError: true, statusCode: errorStatus }));
  }
}

export function* validateEmail({ email }) {
  try {
    const { data } = yield AuthService.validateEmail(email);

    if (data.is_new && data.register_token) {
      yield put(UserCreators.changeStatus({ loading: false, status: 401 }));
      yield put(AuthCreators.emailLoginSuccess(data.register_token));
      localStorage.setItem(TOKEN_KEY, data.register_token);
      return;
    }

    const hasPassword = data.has_password;
    if (hasPassword) {
      yield put(UserCreators.changeStatus({ status: 411 }));
      yield put(AuthCreators.validateEmailSuccess({ statusCode: 411 }));
    } else {
      const { data } = yield AuthService.sendForgotPasswordEmail(email);
      if (data?.message === 'Email has been sent successfully') {
        yield put(UserCreators.changeStatus({ loading: false, status: 410 }));
        yield put(AuthCreators.forgotPasswordSuccess());
      }
    }
  } catch (error) {
    yield put(AuthCreators.validateEmailSuccess({ statusCode: 401 }));
  }
}

export function* emailLogin({ email }) {
  try {
    const { data } = yield AuthService.loginEmail(email);

    if (data.access_token) {
      localStorage.setItem(TOKEN_KEY, data.access_token);
      yield put(UserCreators.getUserData());
      yield put(AuthCreators.validateOtpSuccess(data.access_token));
      const user = yield select(getUser);
      mixpanel.track('Login_Successful', { type: 'Email', ...user });
    }
  } catch (error) {
    yield put(AuthCreators.emailLoginFailure());
  }
}

export function* logout() {
  localStorage.clear();
  logoutSentry();
  yield all([
    put(UserCreators.reset()),
    put(AuthCreators.reset()),
    put(LocationCreators.reset()),
    put(SalesCreators.reset()),
    put(StartupTypes.startup()),
  ]);
  window.location.replace(`${window.location.origin}/${window.location.search}`);
}

export function* signup({ userInfo }) {
  try {
    const user = {
      ...userInfo,
      document_type: 4,
      document: userInfo.documentNumber || userInfo.document,
      lastName: userInfo.name,
    };

    const { success: status, data } =
      userInfo.type === 'email'
        ? yield AuthService.signup({ userInfo: user })
        : yield UserService.updateUserData({ ...user });
    if (data.access_token && status) {
      localStorage.setItem(TOKEN_KEY, data.access_token);
      yield put(AuthCreators.validateOtpSuccess(data.access_token));
    }

    const userData = yield UserService.getUserData();
    if (userInfo.type === 'email') {
      mixpanel.track('Acquisition_Signup_Successful', { type: 'Email', ...user });
    } else {
      mixpanel.track('Acquisition_Signup_Successful', { type: 'Phone', ...user });
    }
    yield put(UserCreators.userDataUpdateSucceeded(userData));
    yield put(AuthCreators.signUpRequestSuccess());
  } catch (error) {
    if (userInfo.type === 'email') {
      mixpanel.track('Acquisition_Signup_Failed', { type: 'Email', ...userInfo, error });
    } else {
      mixpanel.track('Acquisition_Signup_Failed', { type: 'Phone', ...userInfo, error });
    }
    yield put(AuthCreators.authFailure({ status: 409, message: error?.data?.message }));
    yield put(UserCreators.changeStatus({ loading: false, status: 409, error: false }));
  }
}

export function* forgotPassword({ email }) {
  try {
    const { data } = yield AuthService.sendForgotPasswordEmail(email);
    if (data?.message === 'Email has been sent successfully') {
      yield put(UserCreators.changeStatus({ loading: false, status: 410 }));
      yield put(AuthCreators.forgotPasswordSuccess());
    }
  } catch (error) {
    yield put(AuthCreators.validateEmailSuccess({ statusCode: 401 }));
  }
}

export function* updatePassword({ password }) {
  try {
    const { data } = yield AuthService.resetPassword(password.password);
    if (data.access_token) {
      history.replace({ pathname: '/', search: '', state: {} });
      localStorage.setItem(TOKEN_KEY, data.access_token);
      yield all([
        yield put(AuthCreators.authSuccess(true)),
        put(AuthCreators.validateOtpSuccess(data.access_token)),
        put(UserCreators.getUserData()),
      ]);
    }
  } catch (error) {
    if (error?.parsedBody?.data) {
      const { message } = error?.parsedBody?.data;
      switch (message) {
        case 'Token is invalid or has expired"':
          yield put(AuthCreators.updatePasswordFailure('El token no es válido o ha caducado'));
          break;
        case "Param 'password' or 'password_confirmation' cannot be empty":
          yield put(
            AuthCreators.updatePasswordFailure(
              "El parámetro 'contraseña' o 'contraseña_confirmación' no puede estar vacío",
            ),
          );
          break;
        default:
          yield put(
            AuthCreators.updatePasswordFailure(
              'La nueva contraseña debe tener al menos 8 caracteres con 1 número y 1 letra mayúscula',
            ),
          );
          break;
      }
    } else {
      yield put(
        AuthCreators.updatePasswordFailure(
          'Actualmente, el sistema no se encuentra disponible intente nuevamente más tarde',
        ),
      );
    }
  }
}

export function* signUpBrans({ payload }) {
  try {
    const user = {
      ...payload,
      client_type: 1,
      lastName: payload.name,
      document: payload.documentNumber.toString(),
      phone: payload.phone.toString(),
      name_contact: payload.name,
      document_type: 4,
      company_name: payload.businessName,
      password: payload.documentNumber.toString(),
      source: 'Contacto Proveedor',
    };
    const { data } = yield AuthService.signup({
      userInfo: user,
      option: 'Brands',
    });
    if (data) {
      mixpanel.track('Acquisition_Brans_Signup_Successful', { type: 'Form', ...user });
      yield put(AuthCreators.signUpBransSuccess(payload));
    }
  } catch (error) {
    if (error?.parsedBody?.data) {
      const { message } = error?.parsedBody?.data;
      switch (message) {
        case 'The email already exists':
          yield put(AuthCreators.signUpBransFailure('El correo ya existe'));
          break;
        case 'The document already exists':
          yield put(AuthCreators.signUpBransFailure('El documento ya existe'));
          break;
        case 'Verification digit is invalid':
          yield put(AuthCreators.signUpBransFailure('El dígito de verificación no es válido'));
          break;
        case 'Phone must have minimum 10 digits':
          yield put(
            AuthCreators.signUpBransFailure('El teléfono debe tener un mínimo de 10 dígitos'),
          );
          break;
        case 'The document and phone already exist':
          yield put(AuthCreators.signUpBransFailure('El documento y el teléfono ya existen'));
          break;
        case 'The document already exists with another phone':
          yield put(AuthCreators.signUpBransFailure('El documento ya existe con otro teléfono'));
          break;
        default:
          yield put(AuthCreators.signUpBransFailure('El documento ya existe con otro teléfono'));
          break;
      }
      mixpanel.track('Acquisition_Signup_Failed', { type: 'Form', message });
    } else {
      yield put(
        AuthCreators.signUpBransFailure(
          'Actualmente, el sistema no se encuentra disponible intente nuevamente más tarde',
        ),
      );
    }
  }
}
