/**
 * here put your initial state, reducers and combine reducers
 */
import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { Types } from './actionsTypes';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  data: {},
  productDetailsTemp: {},
  isLoading: true,
  error: '',
  isOpenClothesModal: false,
  productInfo: {
    product: null,
    callback: null,
    isSelectedSize: false,
    source: '',
  },
});

/* ------------- Reducers ------------- */
export const getProductRequest = (state, { clean }) => ({
  ...state,
  isLoading: true,
});

export const getProductSuccess = (state = INITIAL_STATE, { data }) => {
  const {
    productInfo: { product },
  } = state;
  const fieldToUpdate = state.productInfo.source === 'Cart' ? 'productDetailsTemp' : 'data';
  let variantsMapped;
  if (state.productInfo.source === 'Cart') {
    variantsMapped = data.variants.map((variant) => {
      if (variant.id === product.itemId) {
        return { ...variant, isSelected: true };
      }
      return { ...variant, isSelected: false };
    });
  }
  return {
    ...state,
    [fieldToUpdate]:
      state.productInfo.source === 'Cart' ? { ...data, variants: variantsMapped } : { ...data },
    isLoading: false,
  };
};

export const reset = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const openClothesModal = (state, { payload }) => {
  const { product, callback, source } = payload;
  return {
    ...state,
    isOpenClothesModal: !state.isOpenClothesModal,
    isLoading: source === 'Cart',
    productInfo: {
      ...state.productInfo,
      product,
      callback,
      source,
      isSelectedSize: source === 'Cart',
    },
  };
};

export const closeClothesModal = (state) => {
  return {
    ...state,
    isOpenClothesModal: !state.isOpenClothesModal,
    productInfo: { product: null, callback: null, isSelectedSize: false, source: '' },
    productDetailsTemp: {},
  };
};

export const updateProductInfoVariant = (state, { payload }) => {
  const { variant } = payload;
  const product = variant.source === 'Cart' ? state.productDetailsTemp : state.productInfo.product;
  const variantsUpdated = product.variants.map((variantMap) => {
    if (variantMap.id === variant.id) return { ...variantMap, isSelected: true };
    return { ...variantMap, isSelected: false };
  });
  if (variant.source === 'Cart') {
    return {
      ...state,
      productDetailsTemp: { ...state.productDetailsTemp, variants: variantsUpdated },
    };
  }
  return {
    ...state,
    productInfo: {
      product: {
        ...state.productInfo.product,
        variants: variantsUpdated,
        imagesGuides: product.imagesGuides,
      },
      callback: state.productInfo.callback,
      isSelectedSize: variant.hex === null,
      source: variant.source,
    },
  };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_BY_ID_REQUEST]: getProductRequest,
  [Types.GET_PRODUCT_SUCCESS]: getProductSuccess,
  [Types.OPEN_CLOTHES_MODAL]: openClothesModal,
  [Types.CLOSE_CLOTHES_MODAL]: closeClothesModal,
  [Types.UPDATE_PRODUCT_INFO_VARIANT]: updateProductInfoVariant,
  [Types.UPDATE_PRODUCT_INFO_VARIANT]: updateProductInfoVariant,
});
