const transformLocations = (locations) => {
  const newLocationsMapped = locations
    .map((location) => {
      if (location.address) {
        const locationsSplit = location.address.split(',');
        if (locationsSplit.length > 1) {
          return { ...location, address: locationsSplit[0] };
        }
        return location;
      }
      return location;
    })
    .filter((location) => location.address !== false);
  return newLocationsMapped;
};

export { transformLocations };
