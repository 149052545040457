import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like loginRequest -> LOGIN_REQUEST
 */
export const { Types, Creators } = createActions({
  getUserLocations: null,
  getAnonLocations: null,
  setDepartmentId: ['departmentId'],
  changeSelectedLocation: ['payload'],
  addUserLocation: ['payload'],
  deleteUserLocation: ['locationId'],
  updateUserLocation: ['location'],
  openLocationModal: null,
  closeLocationModal: null,
  userLocationFetchSucceeded: ['payload'],
  anonLocationFetchSucceeded: ['anonLocations'],
  reset: null,
  setWillPickUpInWarehouse: ['willPickUpInWarehouse'],
  addFilterCity: ['city'],
  startLoading: null,
  stopLoading: null,
  clearFilterCity: null,
  toggleGoogleMapsModal: ['payload'],
  enableUserAddressEditMode: ['location'],
  disableUserAddressEditMode: null,
  resetIsLocationSaved: null,
  resetAnonLocationsRequest: null,
  updatedAnonLocationsSuccess: ['locations'],
  openSuperFastModalRequest: ['payload'],
  closeSuperFastModalRequest: null,
});
