/* eslint-disable no-nested-ternary */
// @ts-nocheck
import { call } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { mapPseTransferBody } from '@utils/payu/payu.parser';
import {
  WEB_URL,
  PAYMENT__METHODS,
  DEVICESESSIONID,
  ZIRO_API_SECRET,
  ZIRO_API_USER,
  CODE_COMERCIO,
  CODE_COMERCIO_SANDBOX,
  ZIRO_HOST,
  QueryParams,
  ZIRO_API_SECRET_SANDBOX,
  ZIRO_API_USER_SANDBOX,
  DEMO_USER,
  ZIRO_HOST_DEMO,
} from '@constants/index';

import { odooApi, ziroApi, fetchData } from './api';

export const PaymentService = {
  reqTransferToken(paymentData, shoppingCart) {
    return mapPseTransferBody(
      { ...paymentData },
      `${WEB_URL}/confirmacion-pago${window.location.search ? `${window.location.search}&` : '?'}${QueryParams.REDIRECTED_FROM
      }=PSE&id=${shoppingCart.id}`,
    );
  },
  *initTransfer({ data }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/payu/payment/pse',
      {},
      {
        ...data,
      },
    );
  },
  *listBack() {
    return yield call({ context: odooApi, fn: odooApi.get }, `/payu/payment/banks`);
  },
  *payWithCreditCard({ data }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/payu/payment/card',
      {},
      { ...data },
    );
  },
  *subscriptionCreditCard({ data }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/payu/subscription/card',
      {},
      { ...data },
    );
  },
  *subscriptionCreditCardDelete({ id }) {
    return yield call({ context: odooApi, fn: odooApi.delete }, `/payu/subscription/card/${id}`);
  },
  *getCreditCard() {
    return yield call({ context: odooApi, fn: odooApi.get }, `/payu/subscription/getCard`);
  },
  *getpayStatus({ token }) {
    return yield call({ context: odooApi, fn: odooApi.post }, `/payu/payment/status/${token}`);
  },
  *confirmFreeOrder({ id }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      `/sales/confirm-free-order`,
      {
        is_ziro: 'true',
      },
      {
        order_id: id,
      },
    );
  },
  *getRappiPayStatus({ token }) {
    return yield call({ context: odooApi, fn: odooApi.get }, `/rappi/status/?checkout_id=${token}`);
  },
  *reqRappiPayTransferToken({ email }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/payment/rappi',
      {},
      {
        email,
      },
    );
  },
  *payWithCashIn() {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/payu/payment/cash',
      {},
      {
        paymentMethod: PAYMENT__METHODS.CASH,
        cookie: uuidv4(),
        userAgent: navigator.userAgent,
        deviceSessionId: localStorage.getItem(DEVICESESSIONID),
      },
    );
  },
  *payWithCreditSF({ id }) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/sales/confirm-order',
      {},
      { order_id: id },
    );
  },
  *authZiro() {
    const formData = new FormData();
    const isDemoUser = localStorage.getItem('DEMO_USER') === DEMO_USER;

    const apiUser = isDemoUser ? ZIRO_API_USER_SANDBOX : ZIRO_API_USER;
    const apiSecret = isDemoUser ? ZIRO_API_SECRET_SANDBOX : ZIRO_API_SECRET;
    const urlZiro = isDemoUser ? (isDemoUser ? ZIRO_HOST_DEMO : ZIRO_HOST) : ZIRO_HOST;

    formData.append('api_user', apiUser);
    formData.append('api_secret', apiSecret);

    const url = `${urlZiro}/api/auth`;
    return yield call(fetchData, url, formData);
  },
  *validateCreditZiro({ payload }) {
    const isDemoUser = localStorage.getItem('DEMO_USER') === DEMO_USER;
    return yield call(
      { context: ziroApi, fn: ziroApi.post },
      '/validar-datos-credito',
      {},
      {
        ...payload,
        codigo_comercio: isDemoUser ? CODE_COMERCIO_SANDBOX : CODE_COMERCIO,
        cuotas_credito: 1,
      },
    );
  },
  *validateCodeCreditZiro({ payload }) {
    return yield call(
      { context: ziroApi, fn: ziroApi.post },
      '/validar-codigo-credito',
      {},
      {
        ...payload,
      },
    );
  },
  *resendCodeZiro({ payload }) {
    return yield call({ context: ziroApi, fn: ziroApi.get }, '/reenviar-codigo-credito', {
      ...payload,
    });
  },
  *applyCreditZiro(payload) {
    return yield call(
      { context: ziroApi, fn: ziroApi.post },
      '/aplicar-credito',
      {},
      {
        ...payload,
      },
    );
  },
};
