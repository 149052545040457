import { put } from 'redux-saga/effects';
import { Creators as BannersCreators } from '@reducers/Banners/actionsTypes';
import { BannersService } from '@services/banners.service';

export function* getBanners() {
  try {
    const banners = yield BannersService.getBanners();
    yield put(BannersCreators.bannersSuccess(banners));
  } catch (error) {
    yield put(BannersCreators.bannersFailed({ error }));
  }
}
