import { assign, isArray } from 'lodash';
import { capitalizeText } from '@utils/format/format.utils';
import { cleanProperties } from './cleanData.transform';

export const transformBrands = (brandsResponse) => {
  const apiToBrand = (brandItem) => {
    const brandMap = {
      id: brandItem?.id,
      name: capitalizeText(brandItem?.name),
      image: brandItem?.image,
      banner: brandItem?.banner,
      discount: brandItem?.discount_percentage,
      position: brandItem?.position,
    };
    return assign({}, cleanProperties(brandMap));
  };
  const filteredBrands = isArray(brandsResponse)
    ? brandsResponse.filter((brandItem) => !!brandItem?.image)
    : [];
  const sortedBrands = filteredBrands.sort((a, b) => {
    if (a.discount > 0 && b.discount > 0) {
      return b.discount - a.discount;
    }
    if (a.discount === 0 && b.discount > 0) {
      return 1;
    }
    if (a.discount > 0 && b.discount === 0) {
      return -1;
    }
    return a.position - b.position;
  });
  return sortedBrands.map(apiToBrand);
};
