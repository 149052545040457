import { createActions } from 'reduxsauce';

/**
 * the keys of object will be the Types converted in SCREAMING_SNAKE_CASE.
 * like getUserRequest -> USER_REQUEST
 */
export const { Types, Creators } = createActions({
  getUserData: null,
  updateUserProfile: ['userProfile'],
  updateProfileRequest: null,
  changePassword: ['password'],
  userDataFetchSucceeded: ['userData'],
  requestNewPassword: ['payload'],
  userDataUpdateSucceeded: ['userData'],
  changeProfileUser: ['userType'],
  setAuthModal: ['isAuthModal'],
  toggleWarningProducts: null,
  reset: null,
  openB2bRegister: null,
  openB2cRegister: null,
  userChangeTypeRequest: ['userInfo'],
  userChangeTypeSuccess: ['userData'],
  userChangeTypeFailure: ['payload'],
  setB2c: null,
  setSourceModal: ['source'],
  changeStatus: ['payload'],
  openAddProductSnackbar: null,
  closeAddProductSnackbar: null,
  toggleCompleteDocumentModal: ['completeDocument'],
});
