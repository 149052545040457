import { call } from 'redux-saga/effects';
import { capitalizeText } from '@utils/format/format.utils';
import { odooApi } from './api';

export const UserService = {
  *getUserData() {
    const {
      data: {
        email,
        document: documentNumber,
        documentType,
        document: nit,
        clientType,
        name,
        first_name: firstName,
        last_name: lastName,
        avatar,
        phone,
        id,
        data_age,
        date_birth,
        isEmployee,
        membership,
        credit_enable: creditEnable,
      },
    } = yield call({ context: odooApi, fn: odooApi.get }, '/contacts/user');
    return {
      eid: id && id.toString(),
      email: email || '',
      nit,
      documentNumber,
      documentType,
      clientType,
      creditEnable,
      name: name.includes('DEFAULT')
        ? ''
        : firstName ||
          name
            .split(' ')
            .map((word) => capitalizeText(word))
            .join(' '),
      lastName: lastName || '',
      isEmployee,
      avatar,
      phone,
      dataAge: data_age,
      dateBirth: date_birth,
      membership: membership || [],
    };
  },
  *requestNewPassword(email) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/forgot-password',
      {},
      {
        email,
      },
    );
  },

  *updateUserData(data) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/update-data',
      {},
      {
        ...data,
      },
    );
  },

  *updateUserProfile(userProfile) {
    return yield call(
      { context: odooApi, fn: odooApi.put },
      '/contacts/user',
      {},
      { ...userProfile },
    );
  },

  *changePassword(password) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/change-password',
      {},
      {
        params: {
          password,
          password_confirmation: password,
        },
      },
    );
  },

  *getPositiveBallance() {
    const data = yield call({ context: odooApi, fn: odooApi.get }, '/balance');
    return { data };
  },

  *anonymousRegister(phone) {
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/register/default',
      {},
      {
        phone,
      },
    );
  },

  *changeUserType(user) {
    const body = { ...user, ...(user.source && { source: user.source }) };
    return yield call({ context: odooApi, fn: odooApi.post }, `/auth/change-user`, {}, body);
  },
};
