import { assign, isArray } from 'lodash';
import { capitalizeText } from '@utils/format/format.utils';
import { cleanProperties } from './cleanData.transform';

export const transformDiets = (dietsResponse) => {
  const getBanner = (images, type = 'Icon') => {
    if (images.length > 0) {
      const filterImage = images.find((item) => item.type === type);
      return filterImage ? filterImage.url : '';
    }
    return '';
  };
  const apiToDiets = (dietsItem) => {
    const dietsdMap = {
      id: dietsItem.id,
      name: capitalizeText(dietsItem.name),
      icon: getBanner(dietsItem.images),
      stamp: getBanner(dietsItem.images, 'Stamp'),
      images: dietsItem.images,
    };
    return assign({}, cleanProperties(dietsdMap));
  };

  return isArray(dietsResponse) ? dietsResponse.map(apiToDiets) : [];
};
