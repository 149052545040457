import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { ProductBrandBanner } from '@superfuds/saori';
import { Grid } from '@material-ui/core';

import { history } from '@redux/store';
import { Carousel } from '@components/index';
import { useWindowSize } from '@hooks/use-windows-size.hook';

import './style.scss';

export const BannerCarousel = ({ banners, isLoading, location, isEmployee, user }) => {
  const { width } = useWindowSize();
  const data = location === 'HOME' ? banners?.data : banners;
  const hasDescription = data.length > 0 && data[0]?.description;

  const handleClick = useCallback((banner) => {
    switch (banner.BannersType) {
      case 'PRODUCT':
        history.push(`/p/${banner.categoryID}`);
        break;
      case 'CATEGORY': {
        history.push(`/categoria/${banner.categoryID}`);
        break;
      }
      case 'BRAND':
        history.push(`/marca/${banner.categoryID}`);
        break;
      case 'BRANDS':
        history.push(`/todas-brands`);
        break;
      case 'ZIRO':
        window.open('https://creditos.somosziro.com/tu-credito/SuperfudsS.a.s./65579747', '_blank');
        break;
      case 'PROMO':
        break;
      default:
        break;
    }
  }, []);

  const validateBanner = (banner) => {
    if (banner.changeUserType && isEmployee) return false;
    if (banner.url) return true;
    return false;
  };
  if (isLoading) {
    return (
      <Grid item xs={12} id="Banners-promo" className="banner-promo-loading">
        <ContentLoader height="100%" width="100%">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </Grid>
    );
  }
  return (
    <Grid
      item
      xs={12}
      id="Banners-promo"
      className={`Banners-promo text-left ${hasDescription ? 'banner-full-image' : ''}`}
      style={{
        marginTop: user?.isLoggedIn ? '95px' : '',
      }}
    >
      {hasDescription ? (
        <ProductBrandBanner
          title={data[0]?.name}
          description={data[0]?.description}
          image={data[0]?.image}
        />
      ) : (
        <Carousel
          sources="Banner-home"
          dots
          infinite
          centerMode
          speed={750}
          slidesToShow={1}
          slidesToScroll={1}
          autoplaySpeed={10000}
          adaptiveHeight
          className="banner-promo-component"
        >
          {data?.length > 0 &&
            data
              .filter((item) => {
                if (width < 1024) {
                  return item.PlatformBanner === 'Movil';
                }
                return item.PlatformBanner === 'Web';
              })
              .map((banner, index) => {
                const isMobile = banner?.PlatformBanner === 'Movil';
                return (
                  validateBanner(banner) && (
                    <div
                      className={`${location !== 'HOME' ? 'container-banner-image' : ''}`}
                      key={index + banner.name}
                    >
                      <img
                        fetchpriority="high"
                        width={isMobile ? '380px' : '2000px'}
                        height={isMobile ? 'auto' : '400px'}
                        role="presentation"
                        onClick={() => handleClick(banner)}
                        alt={`${banner?.name}`}
                        className={`lazy ${isMobile ? 'bannersImg' : ''}`}
                        src={banner?.url}
                        srcSet={isMobile ? `${banner?.url} 380w` : `${banner?.url} 2000w`}
                        sizes={isMobile ? '(max-width: 600px) 380px' : '(min-width: 601px) 2000px'}
                        loading="lazy"
                      />
                    </div>
                  )
                );
              })}
        </Carousel>
      )}
    </Grid>
  );
};

BannerCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      imageAlt: PropTypes.string,
    }),
  ).isRequired,
  location: PropTypes.string,
  isLoading: PropTypes.bool,
  isEmployee: PropTypes.bool,
};

BannerCarousel.defaultProps = {
  location: '',
  isLoading: false,
  isEmployee: false,
};
