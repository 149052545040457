import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { NextArrow } from './NextArrow';
import { PrevArrow } from './PrevArrow';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './style.scss';

const Carousel = ({
  dots,
  speed,
  sources,
  children,
  infinite,
  className,
  centerMode,
  slidesToShow,
  slidesToScroll,
  autoplaySpeed,
  adaptiveHeight,
  responsiveSettings,
}) => {
  const settings = {
    sliderSettings: {
      dots,
      speed,
      infinite,
      centerMode,
      draggable: true,
      mobileFirst: true,
      initialSlide: 0,
      adaptiveHeight,
      autoplaySpeed,
      slidesToScroll,
      slidesToShow,
      className,
      centerPadding: '0px',
      nextArrow: <NextArrow idSources={sources ? `${sources}-Next Next` : 'Next'} />,
      prevArrow: <PrevArrow idSources={sources ? `${sources}-Prev Next` : 'Prev'} />,
      responsive: responsiveSettings,
    },
  };
  return (
    <div className="finite-items-carousel-default">
      <Slider {...settings.sliderSettings}>{children}</Slider>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  sources: PropTypes.string.isRequired,
  responsiveSettings: PropTypes.arrayOf(PropTypes.object),
  adaptiveHeight: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  autoplaySpeed: PropTypes.number,
  className: PropTypes.string,
  centerMode: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  dots: PropTypes.bool,
};
Carousel.defaultProps = {
  responsiveSettings: [],
  adaptiveHeight: false,
  slidesToScroll: 3,
  slidesToShow: 5,
  autoplaySpeed: 0,
  infinite: false,
  centerMode: false,
  className: '',
  speed: 500,
  dots: false,
};

export { Carousel };
